import React, { useState, useEffect } from "react";
import { ButtonDom } from "../../components/ButtonDates/ButtonDom";
import { User } from "../../components/User";
import { useHistory } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import "./Wallet.styles.css";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";
import { api } from "../../services/api";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Swal from "sweetalert2";
import { alpha, styled } from "@mui/material/styles";

export const Wallet = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "auto",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filme, setFilme] = useState({});
  const [cupom, setCupom] = useState();
  const [limiteCupon, setLimiteCupon] = useState(null);
  const [cupomInfo, setCupomInfo] = useState(undefined);
  const [ingressoInfo, setIngressoInfo] = useState({});
  const [datas, setDatas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [qtdInteiras, setQtdInteiras] = useState(0);
  const [qtdMeias, setQtdMeias] = useState(0);
  const history = useHistory();
  const paramsG = new URLSearchParams(window.location.search);
  const [isEditing, setIsEditing] = useState(paramsG.get("edit"));

  async function getFilme() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/v1/filme/pesquisar-info/" + params.get("id"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setFilme(response.data.data);
  }

  async function getIngressoInfo() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/v1/ingresso/pesquisar-info/" + params.get("edit"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setIngressoInfo(response.data.data);
  }

  async function getDatas(cb) {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/v1/ingresso/pesquisar-datas/" + params.get("id"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setDatas(response.data.data);

    if (typeof cb != "undefined") {
      cb(response.data.data);
    }
  }

  async function getHorarios(horario, cb) {
    setHorarios([]);

    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/v1/ingresso/pesquisar-horarios/" +
      params.get("id") +
      "?data=" +
      horario,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setHorarios(response.data.data);

    if (typeof cb != "undefined") {
      cb(response.data.data);
    }
  }

  async function getDataSelectedLocal(datas) {
    if (localStorage.ingresso) {
      let ingresso = JSON.parse(localStorage.ingresso);

      datas.map((item) => {
        if (item.id == ingresso.horario_id) {
          handleSelectedDate(item, getHorarioSelectedLocal);
        }
      });
    }
  }

  async function getHorarioSelectedLocal(horarios) {
    let ingresso = JSON.parse(localStorage.ingresso);

    horarios.map((item) => {
      if (item.id == ingresso.horario_id) {
        handleSelectedHorario(item);
      }
    });

    let ingressoL = JSON.parse(localStorage.ingresso);
    setQtdInteiras(ingressoL.qtd_inteiras);
    setQtdMeias(ingressoL.qtd_meias);
  }

  useEffect(() => {
    getFilme();
    getDatas(function (datas) {
      getDataSelectedLocal(datas);
    });

    if (isEditing) {
      getIngressoInfo();
    }
  }, []);

  useEffect(() => { }, [filme]);

  //funcao Navigation
  const handleNavigation = () => {
    if (isEditing) {
      let qMeias = ingressoInfo.tipo_ingresso == "meia" ? 1 : 0;
      let qInteiras = ingressoInfo.tipo_ingresso == "inteira" ? 1 : 0;


      let valorTotal = 0;
      if (limiteCupon == null) {
        valorTotal = (selectedHorario.valor / 2) * qMeias + selectedHorario.valor * qInteiras;
        if (cupomInfo.tipo == "porcentagem") {
          valorTotal = valorTotal - (cupomInfo.porcentagem / 100) * valorTotal;
        } else if (cupomInfo.tipo == "valor") {
          valorTotal = valorTotal - cupomInfo.valor >= 0 ? valorTotal - cupomInfo.valor : 0;
        }
      }
      localStorage.ingresso = JSON.stringify({
        horario: selectedHorario,
        horario_id: selectedHorario.id,
        cupom: cupomInfo,
        qtd_meias: qMeias,
        qtd_inteiras: qInteiras,
        qtd_total: parseInt(qMeias) + parseInt(qInteiras),
        valor_meias: ((selectedHorario.valor / 2) * qMeias).toFixed(2),
        valor_inteiras: (selectedHorario.valor * qInteiras).toFixed(2),
        valor_total: valorTotal.toFixed(2),
      });

      let params = new URLSearchParams(window.location.search);
      history.push(
        "/butacas?id=" + params.get("id") + "&edit=" + params.get("edit")
      );
    } else {
      if (qtdInteiras == 0 && qtdMeias == 0) {
        Swal.fire(
          "Falta información!",
          "Favor digite la cantidad de entradas.",
          "error"
        )
      } else {
        let valorTotal = 0;
        let valorDesconto = null;
  
        if (limiteCupon == null) {
          if (cupomInfo) {
            valorTotal = cupomInfo.valor_total;
            valorDesconto = cupomInfo.valor_desconto;
          } else {
            valorTotal +=
              (selectedHorario.valor / 2) * qtdMeias +
              selectedHorario.valor * qtdInteiras;
          }
        }
  
        localStorage.ingresso = JSON.stringify({
          horario: selectedHorario,
          horario_id: selectedHorario.id,
          cupom: cupomInfo,
          qtd_meias: qtdMeias,
          qtd_inteiras: qtdInteiras,
          qtd_total: parseInt(qtdMeias) + parseInt(qtdInteiras),
          valor_meias: ((selectedHorario.valor / 2) * qtdMeias).toFixed(2),
          valor_inteiras: (selectedHorario.valor * qtdInteiras).toFixed(2),
          valor_total: valorTotal.toFixed(2),
          valor_desconto: valorDesconto ? valorDesconto.toFixed(2) : null,
        });
  
        let params = new URLSearchParams(window.location.search);
        history.push("/butacas?id=" + params.get("id"));
      }
    }
  };

  const handleCupomInfos = (e) => {
    setCupom(e.target.value);
  };

  const inserirCupom = async () => {
    let token = localStorage.token;

    const response = await api
      .get(
        `/verificar-cupom/${cupom}/${filme.id}/${selectedDate.dia_da_semana}/2`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .catch(() =>
        Swal.fire(
          "Código inválido!",
          "El cupón que dígitó no fue encontrado.",
          "error"
        )
      );

    if (response.data.data) {
      if (response.data.data.saldo !== undefined) {
        if (response.data.data.saldo < (qtdInteiras + qtdMeias)) {
          setQtdMeias(0)
          setQtdInteiras(0)
        }
        setLimiteCupon(response.data.data.saldo);
        Swal.fire("Código aceptado!", "Tienes hasta " + response.data.data.saldo + " entradas con este cupón", "success");
      } else {
        Swal.fire("Código aceptado!", "Descuento aplicado", "success");
      }
    } else {
      Swal.fire(
        "Código inválido!",
        "El cupón que dígitó no fue encontrado.",
        "error"
      );
    }

    handleValuesTotal(response);
  };

  const handleValuesTotal = (response) => {
    if (response.data.data.saldo === undefined) {
      if (response.data.data.tipo === "quantidade") cupomQuantidade(response);
      else if (response.data.data.tipo === "porcentagem")
        cupomPorcentagem(response.data.data);
      else if (response.data.data.tipo === "valor")
        cupomValorFixo(response.data.data);
    } else {
      setCupomInfo(response.data.data);
    }

  }

  const cupomValorFixo = (cupom) => {
    cupom.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    cupom.valor_desconto = cupom.valor;

    if (cupom.valor_total - cupom.valor_desconto > 0)
      cupom.valor_total = cupom.valor_total - cupom.valor_desconto;
    else cupom.valor_total = 0;

    setCupomInfo(cupom);
  };

  const cupomPorcentagem = (cupom) => {
    cupom.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    cupom.valor_desconto = cupom.valor_total * (cupom.porcentagem / 100);

    cupom.valor_total = cupom.valor_total - cupom.valor_desconto;

    setCupomInfo(cupom);
  };

  const cupomQuantidade = (response) => {
    response.data.data.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    if (qtdInteiras > 0) {
      if (response.data.data.tickets_afetados > 0) {
        if (response.data.data.tickets_afetados < qtdInteiras) {
          response.data.data.valor_total -= parseInt(
            selectedHorario.valor * response.data.data.tickets_afetados
          );
          response.data.data.valor_desconto = parseInt(
            selectedHorario.valor * response.data.data.tickets_afetados
          );
        } else {
          response.data.data.valor_total -= parseInt(
            selectedHorario.valor * qtdInteiras
          );
          response.data.data.valor_desconto = parseInt(
            selectedHorario.valor * qtdInteiras
          );
        }
      }
    } else {
      if (response.data.data.tickets_afetados > 0) {
        if (response.data.data.tickets_afetados <= qtdMeias) {
          response.data.data.valor_total -= parseInt(
            (selectedHorario.valor / 2) * response.data.data.tickets_afetados
          );
          response.data.data.valor_desconto = parseInt(
            (selectedHorario.valor / 2) * response.data.data.tickets_afetados
          );
        } else {
          response.data.data.valor_total -= parseInt(
            (selectedHorario.valor * qtdMeias) / 2
          );
          response.data.data.valor_desconto = parseInt(
            (selectedHorario.valor * qtdMeias) / 2
          );
        }
      }
    }
    setCupomInfo(response.data.data);
  };

  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedHorario, setSelectedHorario] = useState(false);

  const handleSelectedDate = (selected, cb) => {
    setSelectedDate(selected);
    setSelectedHorario(false);
    setQtdMeias(0);
    setQtdInteiras(0);
    getHorarios(selected.hora, cb);
  };

  const handleSelectedHorario = (selected) => {
    setSelectedHorario(selected);
    setQtdMeias(0);
    setQtdInteiras(0);
  };
  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      <div className="container-category-filme">
        <div className="container-pai">
          <div className="container-pai-filme">
            <div className="detalhes-filme">
              <img src={filme.link_img_capa} alt="" className="img-capa" />
              <div className="infos-filme-carteira">
                <h2 className="title-sinopse">{filme.titulo}</h2>
                <p>2019 - Ação | Fantasia</p>
                <p>Diretor: {filme.diretor}</p>
                <p>
                  {" "}
                  <AiFillStar className="iconStar" />
                  7.5 (85,741)
                </p>
                <h6>
                  We all have a superhero inside us, it just takes a bit of
                  magic to bring it out. In Billy Batson's case, by shouting out
                  one word - SHAZAM. - this streetwise fourteen-year-old foster
                  kid can turn into the grown-up superhero Shazam
                </h6>
              </div>
            </div>
            <div className="informacoes-detalhes">
              <div className="detalhes-filme-direita">
                <div className="container-main-sesion">
                  <div className="container-sesion">
                    <div className="main-bolinha1">
                      <span>01</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>02</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>03</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>04</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>05</span>
                    </div>
                  </div>
                </div>
                <div className="description-bolinha">
                  <p>Selección</p>
                  <p>Butacas</p>
                  <p>Pago</p>
                  <p>Ticket</p>
                  <p>Finalizado</p>
                </div>
              </div>
              <div className="calendario-direitaaa">
                <div className="calendario-direita-cabecalho">
                  {/* <h3 className="title-calendary">Seleccione la sección</h3> */}
                </div>

                <div className="conainer-selecione-date">
                  <h6>SELECCIONE FECHA</h6>
                  <div className="container-DATAS">
                    {datas.map((item) => {
                      return (
                        <button
                          className={
                            selectedDate.data == item.data
                              ? "content-selecione-date-date-Dom-active"
                              : "content-selecione-date-date-Dom"
                          }
                          onClick={() => handleSelectedDate(item)}
                        >
                          <div className="main-content-date-date-Dom">
                            <p
                              className={
                                selectedDate.data == item.data
                                  ? "first-p-active"
                                  : "first-p"
                              }
                            >
                              {item.dia_da_semana}
                            </p>
                            <p
                              className={
                                selectedDate.data == item.data
                                  ? "last-p-active"
                                  : "last-p"
                              }
                            >
                              {item.data[0] + "" + item.data[1]}
                            </p>
                            <p
                              className={
                                selectedDate.data == item.data
                                  ? "tri-p-active"
                                  : "tri-p"
                              }
                            >
                              {item.nome_mes}
                            </p>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>

                {selectedDate && (
                  <>
                    <div className="container-seccion-carteira">
                      <p>HORA / TIPO</p>

                      <div className="container-main-buuton">
                        {horarios.map((item) => {
                          return (
                            <button
                              className={
                                selectedHorario.id == item.id
                                  ? "container-secction-button-selected"
                                  : "container-secction-button"
                              }
                              onClick={() => handleSelectedHorario(item)}
                            >
                              <p
                                className={
                                  selectedHorario.id == item.id
                                    ? "button-first-txt-selected"
                                    : "button-first-txt"
                                }
                              >
                                {item.audio == "leg"
                                  ? "Subtitulada"
                                  : "Doblada"}
                              </p>
                              <p
                                className={
                                  selectedHorario.id == item.id
                                    ? "button-last-txt-selected"
                                    : "button-last-txt"
                                }
                              >
                                {item.horario}
                              </p>
                              <p
                                className={
                                  selectedHorario.id == item.id
                                    ? "button-tri-txt-selected"
                                    : "button-tri-txt"
                                }
                              >
                                {item.qualidade}
                              </p>
                              <p
                                className={
                                  selectedHorario.id == item.id
                                    ? "button-tri-txt-selected"
                                    : "button-tri-txt"
                                }
                              >
                                {item.sala.nome}
                              </p>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

                {selectedHorario.id && !isEditing ? (
                  <>
                    <h6 className="title-cantidad">CANTIDAD</h6>
                    <div className="container-main-igresso-column01">
                      <ButtonGroup sx={{ backgroundColor: "white" }}>
                        <Button
                          onClick={() => {
                            if (qtdInteiras - 1 >= 0)
                              setQtdInteiras(qtdInteiras - 1);
                          }}
                        >
                          -
                        </Button>
                        <Button disable>{qtdInteiras}</Button>
                        <Button onClick={() => {
                          if (limiteCupon == null || (qtdInteiras + qtdMeias + 1) <= limiteCupon) {
                            setQtdInteiras(qtdInteiras + 1)
                          }
                        }}>
                          +
                        </Button>
                      </ButtonGroup>
                      <p className="description-igresso">BUTACA TRADICIONAL</p>
                    </div>

                    <div
                      className="container-main-igresso-column01"
                      style={{ marginTop: 20 }}
                    >
                      <ButtonGroup sx={{ backgroundColor: "white" }}>
                        <Button
                          onClick={() => {
                            if (qtdMeias - 1 >= 0) setQtdMeias(qtdMeias - 1);
                          }}
                        >
                          -
                        </Button>
                        <Button disable>{qtdMeias}</Button>
                        <Button onClick={() => {
                          if (limiteCupon == null || (qtdInteiras + qtdMeias + 1) <= limiteCupon) {
                            setQtdMeias(qtdMeias + 1)
                          }
                        }}>
                          +
                        </Button>
                      </ButtonGroup>
                      <p className="description-igresso">BUTACA MEDIA</p>
                    </div>

                    <h4
                      style={{ marginTop: 20 }}
                      className="description-igresso"
                    >
                      {qtdInteiras}x Butaca Tradicional: ${" "}
                      {parseInt(
                        selectedHorario.valor * qtdInteiras
                      ).toLocaleString("pt-br", { currency: "CLP" })}
                    </h4>

                    <h4
                      style={{ marginTop: 20 }}
                      className="description-igresso"
                    >
                      {qtdMeias}x Butaca Media: ${" "}
                      {parseInt(
                        (selectedHorario.valor / 2) * qtdMeias
                      ).toLocaleString("pt-br", { currency: "CLP" })}
                    </h4>

                    <h2
                      style={{ marginTop: 20 }}
                      className="description-igresso"
                    >
                      {qtdMeias + qtdInteiras}x Butaca Total: ${" "}
                      {(limiteCupon !== null) ?
                        ('0 (Tu cupón cubre el 100% de las entradas)')
                        : (
                          parseInt((selectedHorario.valor / 2) * qtdMeias) +
                          parseInt(selectedHorario.valor * qtdInteiras)
                        ).toLocaleString("pt-br", { currency: "CLP" })
                      }
                    </h2>

                    {cupomInfo && limiteCupon == null && (
                      <h2
                        style={{ marginTop: 20, fontWeight: "bold" }}
                        className="description-igresso"
                      >
                        Descuento: ${" "}
                        {cupomInfo.valor_desconto.toLocaleString("pt-br", {
                          currency: "CLP",
                        })}
                      </h2>
                    )}

                    {cupomInfo && limiteCupon == null && (
                      <h2
                        style={{ marginTop: 20, fontWeight: "bold" }}
                        className="description-igresso"
                      >
                        Total: ${" "}
                        {cupomInfo.valor_total.toLocaleString("pt-br", {
                          currency: "CLP",
                        })}
                      </h2>
                    )}
                    <br />

                    {filme.estreia == 0 && (
                      <>
                        <hr style={{ width: "75%", marginBottom: "2%" }} />

                        <h4> INGRESA AQUÍ TU CÓDIGO PROMOCIONAL </h4>
                        <p style={{ fontSize: "0.9rem" }}>
                          {" "}
                          Aprovecha tu descuento y ven a disfrutar de CINESOL !{" "}
                        </p>

                        <br />
                        <BootstrapInput
                          id="bootstrap-input-1"
                          autoFocus={true}
                          value={cupom}
                          onChange={handleCupomInfos}
                          disabled={cupomInfo !== null && cupomInfo !== undefined}
                        />

                        <BootstrapButton
                          variant="contained"
                          onClick={() => inserirCupom()}
                          disabled={cupomInfo !== null && cupomInfo !== undefined}
                        >
                          Agregar
                        </BootstrapButton>
                      </>
                    )}

                    {cupomInfo ? (
                      <>
                        <br />
                        <div
                          style={{
                            padding: 20,
                            marginTop: 15,
                            background: "var(--orange)",
                            display: "inline-block",
                            color: "#ffffff",
                          }}
                        >
                          {limiteCupon == null ? cupomInfo.promo.nome : cupomInfo.nome}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <hr style={{ width: "75%", marginTop: "2%" }} />
                    <div style={{ marginLeft: "25%", marginBottom: 50 }}>
                      <button
                        onClick={handleNavigation}
                        className="button-continue"
                      >
                        <span>Continuar</span>
                      </button>
                    </div>
                  </>
                ) : selectedHorario.id && isEditing ? (
                  <div className="footer-button">
                    <button
                      onClick={handleNavigation}
                      className="button-continue"
                    >
                      <span>Continuar</span>
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

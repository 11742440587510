import React, { useState, useEffect } from "react";
import { Navbar } from '../../components/Navbar';
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from 'react-icons/ai';
import { ImCheckmark } from 'react-icons/im';
import { User } from "../../components/User";
import './Finally.styles.css';
import { api } from '../../services/api';
export const Finally = () => {
    
    const [ ingressos, setIngressos] = useState([]);
    const [ filme, setFilme] = useState({});

    async  function getFilme(){

        let token = localStorage.token;

        let params = new URLSearchParams(window.location.search);
        
        const response = await api.get('/v1/filme/pesquisar-info/' + params.get('id'), {
           headers:{
                Authorization: "Bearer " + token
           }
       })

       setFilme(response.data.data)
    }

    useEffect( ()=> {
        getFilme();

        let ingressos = JSON.parse(localStorage.ingressosF);
        setIngressos(ingressos)
    },  []);

    return (
        <div className="container-home">
            <div className="container-capa">
                <img src={Photocapa} alt="photo-capa" className="photo-capa" />
                <User />
            </div>
            <Navbar />
            <div className="container-category-filme">


                <div className="container-pai">
                    <div className="container-pai-filme">
                        <div className="detalhes-filme">
                            <img src={filme.link_img_capa} alt="" className="img-capa" />
                            <div className="infos-filme-carteira">
                                <h2 className="title-sinopse">{filme.titulo}</h2>
                                <p>2019 - Ação | Fantasia</p>
                                <p>Diretor: {filme.diretor}</p>
                                <p> <AiFillStar className="iconStar" />7.5 (85,741)</p>
                                <h6>
                                    We all have a superhero inside us, it just takes a bit of magic to bring it out.
                                    In Billy Batson's case, by shouting out one word - SHAZAM. - this streetwise fourteen-year-old
                                    foster kid can turn into the grown-up superhero Shazam
                                </h6>
                            </div>
                        </div>
                        <div className="informacoes-detalhes">
                            <div className="detalhes-filme-direita">

                         <div className="container-main-sesion">  
                              <div className="container-sesion">
                                        <div className="bolinha1">
                                            <span>01</span>
                                        </div>
                                        < hr  className="container-line"/>
                                    </div>
                                    <div className="container-sesion">
                                        <div className="bolinha1">
                                            <span>02</span>
                                        </div>
                                        < hr  className="container-line"/>
                                    </div>
                                    <div className="container-sesion">
                                        <div className="bolinha1">
                                            <span>03</span>
                                        </div>
                                        < hr  className="container-line"/>
                                    </div>
                                    <div className="container-sesion">
                                        <div className="bolinha1">
                                            <span>04</span>
                                        </div>
                                        < hr  className="container-line"/>
                                    </div>
                                    <div className="container-sesion">
                                        <div className="main-bolinha1">
                                            <span>05</span>
                                        </div>
                                    </div>
                              </div>
                             <div className="description-bolinha">
                                <p>Selección</p>
                                    <p>Butacas</p>
                                    <p>Pago</p>
                                    <p>Ticket</p>
                                    <p>Finalizado</p>
                             </div>
                        </div>
                            
                        <div className="container-finaly-main">
                             <div className="container-finaly">
                                <ImCheckmark  size={28} color="#FFFF"/>
                            </div>
                       </div>
                       <div className="container-title-finnaly">
                            <h1 className="title-finnaly">Compra completada con éxito</h1>
                            <div className="tickets-list">
                                {ingressos && ingressos.length ? 
                                    <a href={"https://api-cinesol.busticket.cl/ingresso/" + ingressos[0].venda.vid} target="_blank">
                                        Imprimir tickets
                                    </a>
                                : <></>}
                            </div>
                            <div className="tickets-button">
                                <a href="/cartelera">
                                    Nueva venta
                                </a>
                            </div>
                       </div>
                     

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
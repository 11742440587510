import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
//components
import { Navbar } from "../../components/Navbar";
import { User } from "../../components/User";
import Photocapa from "../../assets/photo-capa.png";
import { api } from "../../services/api";

import "./Cartelera.styles.css";
import Item from "./item";
import { ButtonIngressar } from "../../components/Forms/ButtonIngressar";

import Carousel from "react-elastic-carousel";

import { ModalBanner } from "../../components/ModalIngressarSala/modalbanner";
import { ModalVisualizarTicket } from "../../components/ModalIngressarSala/modalVisualizarTicket";
import { Box, Layer } from "grommet";
import { ListarPedidosOnline } from "../Informations/ListarPedidosOnline";

const breakPoints = [{ width: 1, itemsToShow: 4 }];

export const Cartelera = () => {
  const history = useHistory();

  const usuario = JSON.parse(localStorage.usuario);
  const [ingressoId, setIngressoId] = useState();
  const [valorCaixa, setValorCaixa] = useState();
  const [filmeSelectedId, setFilmeSelectedId] = useState();
  const [consultandoIngresso, setConsultandoIngresso] = useState(false);
  const [filmes, setFilmes] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [caixa, setCaixa] = useState();


  const handleIngresoId = (e) => {
    e.preventDefault();
    setIngressoId(e.target.value);
  };

  const handleValorCaixa = (e) => {
    e.preventDefault();
    setValorCaixa(e.target.value);
  };

  const handleFilmeSelectedId = (e) => {
    e.preventDefault();
    setFilmeSelectedId(e.target.value);
  };

  async function getFilmes() {
    let token = localStorage.token;
    const response = await api.get("/v1/filme/pesquisar/1", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setFilmes(response.data.data.filme);
    setEventos(response.data.data.eventos);
  }

  async function getCaixa() {
    let token = localStorage.token;
    const response = await api.get("/v1/ingresso/verificar-caixa", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setCaixa(response.data.data);
  }

  useEffect(() => {
    getFilmes();
    getCaixa();
    if (JSON.parse(localStorage.usuario).papel_id == 3) {
      history.push("/autenticacion");
    }
  }, []);

  useEffect(() => {
  }, [filmes]);

  const handleAbrirCaixa = async () => {
    if (typeof valorCaixa == "undefined") {
      alert("¡Ingrese un valor para abrir el caja!");
      return;
    }

    let token = localStorage.token;
    const response = await api.post(
      "/v1/ingresso/abrir-caixa",
      {
        valor: valorCaixa,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    window.location.href = window.location.href;
  };


  const [isModalVisibleBanner, setIsModalVisibleBanner] = useState(false);
  const [isTicketVisible, setIsTicketVisible] = useState(false);
  const [ticketInfo, setTicketInfo] = useState([]);

  const clearVendaAtual = () => {
    localStorage.removeItem("ingressosF");
    localStorage.removeItem("ingressoF");
    localStorage.removeItem("ingressos");
    localStorage.removeItem("ingresso");
  };


  const handleOpenTicket = () => {
    let token = localStorage.token;

    api
      .get("/v1/ingresso/pesquisar-info/" + ingressoId, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (!response.data.data) {
          setConsultandoIngresso(false);
          alert("Ingreso no encontrado en la base");
        } else {
          setTicketInfo({
            ticket: response.data.data,
            filme: response.data.extrainfo,
          });
          setIsTicketVisible(true);
        }
      })
      .catch();
  };

  const RedirectToEdit = () => {
    if (filmeSelectedId) {
      let urlRedirect =
        "/pelicula?id=" + filmeSelectedId + "&edit=" + ingressoId;
      window.location.href = urlRedirect;
    } else {
      alert("Debe seleccionar la película deseada para continuar");
    }
  };

  const [modalTicketsOnline, setMTO] = React.useState(false);
  const [aba, setAba] = useState(0);
  const [peliculaFilter, setPelifulcaFilter] = useState("");
  return (
    <div className="container-home">
      {modalTicketsOnline && (
        <Layer
          onEsc={() => setMTO(false)}
        >
          <ListarPedidosOnline onEsc={() => setMTO(false)} />
        </Layer>
      )}

      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />

      {isModalVisibleBanner && <ModalBanner />}

      {isTicketVisible && (
        <ModalVisualizarTicket
          redirect={() => RedirectToEdit()}
          filmeInfo={ticketInfo.filme}
          ticketInfo={ticketInfo.ticket}
          closemodal={() => {
            setIsTicketVisible(false);
          }}
        />
      )}

      {(caixa && caixa.id) || 1 ? (
        <>
          <div className="container-main-pai">
            <div className="abas">
              <button
                className={"aba " + (aba == 0 ? "active" : "")}
                onClick={() => setAba(0)}
              >
                Venta
              </button>
              <button
                className={"aba " + (aba == 1 ? "active" : "")}
                onClick={() => setAba(1)}
              >
                Modificar ticket
              </button>
            </div>
          </div>
          {aba === 0 ? (
            <div
              className="container-category-filme"
              style={{ padding: "0% 3% 0 5%" }}
            >
              <div className="container-header-ingessar-filme">
                <h1>Selecione una película para generar entradas</h1>
              </div>

              <input
                type="text"
                name="search-filme"
                placeholder="Buscar película o evento"
                className="search-filme"
                style={{ maxWidth: "50vw" }}
                onChange={(e) => setPelifulcaFilter(e.target.value)}
              />

              <div id="carrosel">
                <h1> Películas </h1>
                <div className="App">
                  <Carousel breakPoints={breakPoints}>
                    {filmes
                      .filter((filme) =>
                        filme.titulo.includes(peliculaFilter.toUpperCase())
                      )
                      .map((item) => (
                        <Link
                          onClick={() => clearVendaAtual()}
                          to={"/pelicula?id=" + item.id}
                        >
                          <Item>
                            <div className="container-main-image-listagem">
                              <img
                                src={item.link_img_capa}
                                alt="percy=jackson"
                                className="image-listagem-filmes"
                              />
                              {item.estreia === 1 && (
                                <div
                                  style={{
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    backgroundColor: "#f2cf22",
                                    borderRadius: "15px 15px 0 0",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1.2rem",
                                      textAlign: "center",
                                      margin: 0,
                                      color: "black",
                                    }}
                                  >
                                    Estreno
                                  </p>
                                </div>
                              )}

                              <div
                                className={
                                  item.lancamento === 1
                                    ? "container-fotter-filmes lancamento"
                                    : "container-fotter-filmes"
                                }
                              >
                                <h3 className="container-title-filme">
                                  {item.titulo}
                                </h3>

                                <div className="horarios">
                                  {item.proximos_horarios ? (
                                    item.proximos_horarios.map(
                                      (horario, key) => (
                                        <>
                                          <p>{horario.horario}</p>
                                          {key === 0 &&
                                          item.proximos_horarios.length > 1 ? (
                                            <div className="pipe"></div>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Item>
                        </Link>
                      ))}
                  </Carousel>
                </div>
              </div>

              <div id="carrosel">
                <h1> Eventos </h1>
                <div className="App">
                  <Carousel breakPoints={breakPoints}>
                    {eventos
                      .filter((evento) =>
                        evento.titulo.includes(peliculaFilter.toUpperCase())
                      )
                      .map((item) => (
                        <Link
                          onClick={() => clearVendaAtual()}
                          to={"/eventos/" + item.id}
                        >
                          <Item>
                            <div className="container-main-image-listagem">
                              <img
                                src={
                                  api.defaults.baseURL.slice(0, -4) +
                                  "/assets/eventos/" +
                                  item.capa
                                }
                                alt="percy=jackson"
                                className="image-listagem-filmes"
                              />
                              <div className="container-fotter-filmes">
                                <h3 className="container-title-filme">
                                  {item.titulo}
                                </h3>
                              </div>
                            </div>
                          </Item>
                        </Link>
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-search-filmes">
              <div className="container-border-header">
                <div className="editar-ingressos">
                  <h1>Modificar Entradas</h1>
                  <span
                    style={{
                      postion: "absolute",
                      display: "flex",
                      marginBottom: "-2.5vh",
                    }}
                  >
                    {" "}
                    Ingrese el número de entrada:{" "}
                  </span>

                  <span
                    style={{
                      postion: "absolute",
                      display: "flex",
                      marginLeft: "47%",
                      marginBottom: "-2vh",
                    }}
                  >
                    {" "}
                    Reprogramar para:{" "}
                  </span>

                  <div className="input-group-ingressos">
                    <input
                      type="text"
                      required
                      name="nome"
                      placeholder="Código entrada"
                      onChange={handleIngresoId}
                      style={{ fontFamilly: "Poppins" }}
                    />
                    <select onChange={handleFilmeSelectedId}>
                      <option value=""></option>
                      {filmes &&
                        filmes.map((filme) => (
                          <option value={filme.id}>{filme.titulo}</option>
                        ))}
                    </select>

                    <button
                      onClick={handleOpenTicket}
                      className="btn-editar"
                    >
                      {consultandoIngresso ? (
                        <span>Buscando ingreso</span>
                      ) : (
                        <span>Modificar Entradas</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="container-border-header">
                <div className="editar-ingressos">
                  <h1>Entradas online</h1>

                  <div className="input-group-ingressos">
                    <button
                      onClick={() => setMTO(true)}
                      style={{ cursor: "pointer" }}
                      className="btn-editar"
                    >
                      Visualizar tickets online
                    </button>
                  </div>
                </div>
              </div>

              <div className="container-header-ingessar-filme">
                <h1>Selecione una película para generar entradas</h1>
                {(usuario.papel_id === 1 || usuario.papel_id === 4) && (
                  <Box direction="row" gap="small">
                    <ButtonIngressar title="INGRESAR EVENTO" type={"event"} />

                    <ButtonIngressar title="INGRESAR PELÍCULA" type="film" />
                  </Box>
                )}
              </div>

              <input
                type="text"
                name="search-filme"
                placeholder="Buscar película o evento"
                className="search-filme"
              />
            </div>
          )}
        </>
      ) : (
        <div className="container-search-filmes">
          <div className="container-border-header">
            <div className="editar-ingressos">
              <h1>Abrir caja</h1>
              <span
                style={{
                  postion: "absolute",
                  display: "flex",
                  marginBottom: "-2.5vh",
                }}
              >
                Ingrese el monto de entrada de efectivo (cantidad de cambio):
              </span>

              <div className="input-group-ingressos">
                <input
                  type="number"
                  min={0}
                  required
                  name="nome"
                  placeholder="Monto de entrada"
                  onChange={handleValorCaixa}
                  style={{ fontFamilly: "Poppins" }}
                />

                <button
                  onClick={handleAbrirCaixa}
                  className="btn-editar"
                >
                  {consultandoIngresso ? (
                    <span>Cargando</span>
                  ) : (
                    <span>Abrir caja</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

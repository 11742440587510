import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import "./GenerarCierre.styles.css";
import Button from "@mui/material/Button";
import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function GenerarCierre({ vendedores, handleVolver }) {


  const denominacionesDineroDefecto = [{
    nombre: '$20.000',
    valor: 20000,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$10.000',
    valor: 10000,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$5.000',
    valor: 5000,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$2.000',
    valor: 2000,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$1.000',
    valor: 1000,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$500',
    valor: 500,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$100',
    valor: 100,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$50',
    valor: 50,
    cantidad: 0,
    total: 0
  }, {
    nombre: '$10',
    valor: 10,
    cantidad: 0,
    total: 0
  }];

  const [denominacionesDinero, setDenominacionesDinero] = useState(denominacionesDineroDefecto);
  const [generado, setGenerado] = useState(false);
  const [guardado, setGuardado] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [vendedor, setVendedor] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [cantidadEfectivo, setCantidadEfectivo] = useState(0);
  const [totalEfectivo, setTotalEfectivo] = useState(0);
  const [recaudacion, setRecaudacion] = useState(0);
  const [debito, setDebito] = useState(0);
  const [credito, setCredito] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [fechaHoraCierre, setFechaHoraCierre] = useState(null);
  const [admin, setAdmin] = useState({});
  const [adminName, setAdminName] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function generarCierreDiario() {
    limpiarVariables();
    if (vendedor == null || fecha == null) {
      alert("Complete los campos");
    } else {
      let fechaSeteada = formatData(fecha);
      const resp = await api.get('/v1/relatorios/vendas-vendedor/' + vendedor + '?data=' + fechaSeteada, {
        headers: {
          Authorization: "Bearer " + token
        }
      });
      if (resp.data.data.guardado) {
        setGuardado(true);
        setDenominacionesDinero(resp.data.data.json_denominacion_dinero)
        setData(resp.data.data)
      } else {
        await setData(resp.data.data)
        if (generado) {
          for (let index = 0; index < denominacionesDineroDefecto.length; index++) {
            document.querySelector("#total-" + index).value = '$ 0';
            document.querySelector("#cantidad-" + index).value = 0;
          }
        }
        await calcularRecaudacion(0, 0, 0, resp.data.data.tipo.total);
        setFechaHoraCierre(new Date().toLocaleString('es', { timeZone: 'America/Santiago' }).replace(',', ''));
        setGenerado(true);
      }
    }
  }

  const formatData = (fecha, separador = '-', separadorOrigen = '-') => {
    let fecha1 = {
      year: parseInt(fecha.split(separadorOrigen)[0], 10),
      month: parseInt(fecha.split(separadorOrigen)[1], 10),
      day: parseInt(fecha.split(separadorOrigen)[2], 10)
    };

    return fecha1.day + separador + fecha1.month + separador + fecha1.year;
  }

  async function setearCredito(value) {
    value = Number(value);
    setCredito(value);
    document.querySelector("#credito").value = value;
    calcularRecaudacion(value, debito, totalEfectivo, data.tipo.total);
  }
  async function setearDebito(value) {
    value = Number(value);
    setDebito(value);
    document.querySelector("#debito").value = value;
    calcularRecaudacion(credito, value, totalEfectivo, data.tipo.total);
  }

  async function calcularRecaudacion(creditoVal, debitoVal, efectivoVal, total) {
    let suma = creditoVal + debitoVal + efectivoVal;
    setRecaudacion(suma);
    document.querySelector("#recaudo").textContent = suma.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0];
    calcularSaldo(total, suma);
  }

  async function calcularSaldo(total, recaudo) {
    let resta = recaudo - total;
    setSaldo(resta);
    document.querySelector("#saldo").textContent = resta.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0];
  }

  const obtenerNombreVendedor = () => {
    let vendedorName = vendedores.find(e => e.id == vendedor);
    return vendedorName.nome;
  }

  async function calcularDinero(index, cantidad) {
    cantidad = Number(cantidad) < 0 ? 0 : Number(cantidad)

    let tempArray = denominacionesDinero;
    let totalAnterior = tempArray[index].total;
    tempArray[index].total = cantidad * tempArray[index].valor;

    let cantidadEfectivoTemp = cantidadEfectivo - tempArray[index].cantidad + cantidad;
    setCantidadEfectivo(cantidadEfectivoTemp);
    tempArray[index].cantidad = cantidad;
    document.querySelector("#total-" + index).textContent = tempArray[index].total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0];
    document.querySelector("#cantidad-" + index).value = cantidad;

    let totalEfectivoTemp = totalEfectivo - totalAnterior + tempArray[index].total;
    setTotalEfectivo(totalEfectivoTemp);

    document.querySelector("#total-efectivo").textContent = totalEfectivoTemp.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0];
    document.querySelector("#total-efectivo2").textContent = totalEfectivoTemp.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0];
    document.querySelector("#cantidad-efectivo").textContent = cantidadEfectivoTemp;

    setDenominacionesDinero(tempArray);
    calcularRecaudacion(credito, debito, totalEfectivoTemp, data.tipo.total);
  }

  async function guardarCierre() {
    handleClose();
    let fechaHoraCierreTemp = fechaHoraCierre.split(' ');
    let dataGuardar = {
      usuario_vendedor_id: vendedor,
      usuario_admin_cierre_id: admin.id,
      total_ventas: data.qtd_vendas,
      total_ingresos: data.qtd_ingressos,
      valor_rendir: Number(data.tipo.total),
      efectivo_real: totalEfectivo,
      credito,
      debito,
      recaudo_total: recaudacion,
      saldo,
      fecha_cierre: fecha,
      fecha_ejecucion_cierre: formatData(fechaHoraCierreTemp[0], '-', '/') + ' ' + fechaHoraCierreTemp[1],
      observaciones,
      json_funciones: JSON.stringify(data.filmes),
      json_denominacion_dinero: JSON.stringify(denominacionesDinero)
    }
    const resp = await api.post('/v1/cierre_caja_diario', dataGuardar, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    if (resp.data.sucesso) {
      bloquearForm();
    }

  }

  async function bloquearForm(bloqueo = true) {
    if (generado) {
      denominacionesDinero.map((element, index) => {
        if (bloqueo) {
          document.querySelector("#cantidad-" + index).setAttribute('disabled', '');
        } else {
          document.querySelector("#cantidad-" + index).removeAttribute('disabled');
        }
      })
      if (bloqueo) {
        document.querySelector("#credito").setAttribute('disabled', '');
        document.querySelector("#debito").setAttribute('disabled', '');
        document.querySelector("#observaciones").setAttribute('disabled', '');
      } else {
        document.querySelector("#credito").removeAttribute('disabled');
        document.querySelector("#debito").removeAttribute('disabled');
        document.querySelector("#observaciones").removeAttribute('disabled');
      }
    }
    setDisableButton(bloqueo)

  }

  async function volver() {
    setGenerado(false);
    setVendedor(null);
    setFecha(null);
    await limpiarVariables()
    handleVolver();
  }

  async function limpiarVariables() {
    setData(null);
    bloquearForm(false);
    setGuardado(false);
    setDenominacionesDinero(denominacionesDineroDefecto);
    setCantidadEfectivo(0);
    setTotalEfectivo(0);
    setRecaudacion(0);
    setDebito(0);
    setCredito(0);
    setSaldo(0);
    setFechaHoraCierre(null);
    setObservaciones('')
  }

  useEffect(() => {
    setToken(localStorage.token)
    const adminTemp = JSON.parse(localStorage.usuario);
    setAdmin(adminTemp);
    setAdminName(adminTemp.nome);
  }, []);

  return (
    <div className="container-main-pai">
      <h4>Cierre de caja diario</h4>
      <div className="filtros">
        <div className="filtro-field">
          <label style={{ marginRight: '5px' }}>Vendedor</label>
          <select name="usuario_vendedor_id" onChange={(e) => {
            setVendedor(e.target.value)
            setDisableButton(false);
          }}>
            <option value="">Seleccione</option>
            {vendedores.map((item) => (
              <option value={item.id}>{item.nome}</option>
            ))}
          </select>
        </div>
        <div className="filtro-field">
          <label style={{ marginRight: '5px' }}>Fecha</label>
          <input className="inputCierre"
            type="date"
            name="fecha"
            onChange={(e) => {
              setFecha(e.target.value);
              setDisableButton(false);
            }}
          />
        </div>
      </div>
      {data && (
        <div style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
          <table style={{ borderWidth: '0px', width: '100%' }}>
            <tr style={{ borderWidth: '0px' }}>
              <td className="tdTablasCierre" style={{ width: '170px', borderWidth: '0px' }}>Día cierre</td>
              <td className="tdTablasCierre" style={{ borderWidth: '0px' }}>{formatData(fecha, '/')}</td>
            </tr>
            <tr>
              <td className="tdTablasCierre" style={{ width: '170px', borderWidth: '0px' }}>Operador</td>
              <td className="tdTablasCierre" style={{ borderWidth: '0px' }}>{obtenerNombreVendedor()}</td>
            </tr>
            <tr>
              <td className="tdTablasCierre" style={{ width: '170px', borderWidth: '0px' }}>Admin</td>
              <td className="tdTablasCierre" style={{ borderWidth: '0px' }}>{adminName}</td>
            </tr>
          </table>
          <br />
          <table id="funciones" style={{ width: '100%' }} >
            <tr>
              <th className="tdTablasCierre">Función</th>
              <th className="tdTablasCierre">Audio</th>
              <th className="tdTablasCierre">Cant tickets</th>
              <th className="tdTablasCierre">Total</th>
            </tr>
            {data.filmes.map(element => (
              <tr>
                <td className="tdTablasCierre">{element.titulo}</td>
                <td className="tdTablasCierre">{element.audio}</td>
                <td className="tdTablasCierre">{element.total}</td>
                <td className="tdTablasCierre">{element.total_valor}</td>
              </tr>
            ))}
            <tr>
              <th className="tdTablasCierre" colSpan={2}>Valor a rendir</th>
              <th className="tdTablasCierre">{data.qtd_ingressos}</th>
              <th className="tdTablasCierre">{data.valor_bruto}</th>
            </tr>
          </table>
          <br />
          <table id="dinero" style={{ width: '100%' }}>
            <tr>
              <th colSpan={3}>Efectivo</th>
            </tr>
            <tr>
              <th className="tdTablasCierre">Denominación</th>
              <th className="tdTablasCierre">Cant</th>
              <th className="tdTablasCierre">Total</th>
            </tr>
            {denominacionesDinero.map((dinero, index) => (
              <tr>
                <td className="tdTablasCierre">{dinero.nombre}</td>
                {guardado ? (
                  <>
                    <td className="tdTablasCierre">
                      <input className="inputCierre" style={{ width: '80px' }} value={dinero.cantidad} type="number" disabled />
                    </td>
                    <td className="tdTablasCierre">{dinero.total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                  </>
                ) : (
                  <>
                    <td className="tdTablasCierre">
                      <input className="inputCierre" style={{ width: '80px' }} min={0} id={"cantidad-" + index} defaultValue={0} type="number" onChange={(e) => calcularDinero(index, e.target.value)} />
                    </td>
                    <td className="tdTablasCierre" id={"total-" + index}>$ 0</td>
                  </>
                )}
              </tr>
            ))}
            <tr>
              <th className="tdTablasCierre">Total efectivo</th>
              {guardado ? (
                <>
                  <th>{data.cantidad_efectivo}</th>
                  <th>{data.efectivo_real.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</th>
                </>
              ) : (
                <>
                  <th id="cantidad-efectivo">0</th>
                  <th id="total-efectivo">$ 0</th>
                </>
              )}
            </tr>
          </table>
          <br />
          <table style={{ width: '100%' }}>
            <tr>
              <td className="tdTablasCierre">Efectivo real</td>
              <th className="tdTablasCierre" id="total-efectivo2" >{guardado ? data.efectivo_real.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0] : '$ 0'}</th>
            </tr>
            <tr>
              <td className="tdTablasCierre">Transbank - Crédito</td>
              <th className="tdTablasCierre">
                {guardado ? (
                  <>
                    $ <input className="inputCierre" style={{ width: '120px' }} type="number" value={data.credito} disabled />
                  </>
                ) : (
                  <>
                    $ <input className="inputCierre" style={{ width: '120px' }} min={0} id="credito" defaultValue={0} type="number" onChange={(e) => setearCredito(e.target.value)} />
                  </>
                )}
              </th>
            </tr>
            <tr>
              <td className="tdTablasCierre">Transbank - Débito</td>
              <th className="tdTablasCierre">
                {guardado ? (
                  <>
                    $ <input className="inputCierre" style={{ width: '120px' }} type="number" value={data.debito} disabled />
                  </>
                ) : (
                  <>
                    $ <input className="inputCierre" style={{ width: '120px' }} min={0} id="debito" defaultValue={0} type="number" onChange={(e) => setearDebito(e.target.value)} />
                  </>
                )}
              </th>
            </tr>
            <tr>
              <td className="tdTablasCierre">Recaudación</td>
              <th id="recaudo">{guardado ? data.recaudo_total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0] : '$ 0'}</th>
            </tr>
            <tr>
              <td className="tdTablasCierre">Saldo</td>
              <th id="saldo">{guardado ? data.saldo.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0] : '$ 0'}</th>
            </tr>
          </table>
          <br />
          <p>Fecha y hora de cierre: {fechaHoraCierre}</p>
          {guardado ? (
            <textarea placeholder="Observaciones" rows="4" className="inputCierre" style={{ width: '100%', textAlign: 'left' }} disabled>{data.observaciones}</textarea>
          ) : (
            <textarea placeholder="Observaciones" rows="4" id="observaciones" className="inputCierre" style={{ width: '100%', textAlign: 'left' }} onChange={(e) => { setObservaciones(e.target.value) }}></textarea>
          )}
        </div>
      )}
      <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        <Button variant="contained" style={{
          width: '150px',
          height: '40px',
          backgroundColor: '#CCCCCC',
          color: '#333333',
          border: '1px solid #CCCCCC',
          cursor: 'pointer',
          fontFamily: 'Poppins',
          textTransform: 'capitalize',
          marginRight: '5px'
        }} onClick={volver}>Volver</Button>

        <Button variant="contained" disabled={disableButton} style={{
          width: '150px',
          height: '40px',
          backgroundColor: '#00C455',
          border: '1px solid #00C455',
          cursor: 'pointer',
          fontFamily: 'Poppins',
          textTransform: 'capitalize',
          marginRight: '5px'
        }} onClick={generarCierreDiario}>Generar cierre</Button>

        {generado && (
          <Button variant="contained" disabled={disableButton} style={{
            width: '150px',
            height: '40px',
            backgroundColor: '#F26522',
            border: '1px solid #F26522',
            cursor: 'pointer',
            fontFamily: 'Poppins',
            textTransform: 'capitalize'
          }} onClick={handleOpen}>Guardar</Button>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p>
            ¿Está seguro de guardar el cierre de caja?
            <br />Al guardar no podrá volver a modificarlo
          </p>
          <Button variant="contained" style={{
            width: '150px',
            height: '40px',
            backgroundColor: '#CCCCCC',
            color: '#333333',
            border: '1px solid #CCCCCC',
            cursor: 'pointer',
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
            marginRight: '5px'
          }} onClick={handleClose}>Cancelar</Button>

          <Button variant="contained" style={{
            width: '150px',
            height: '40px',
            backgroundColor: '#F26522',
            border: '1px solid #F26522',
            cursor: 'pointer',
            fontFamily: 'Poppins',
            textTransform: 'capitalize'
          }} onClick={guardarCierre}>Guardar</Button>

        </Box>
      </Modal>
    </div>

  );
}



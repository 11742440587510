import React, { useState } from "react";
import "./ButtonIngressar.styles.css";
import { ModalIngressarPelicula } from "../../ModalIngressarPelicula";
import { ModalIngressarEvento } from "../../ModalIngressarPelicula/indexEvento";

export const ButtonIngressar = ({ title, type }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  function handleModalVisible() {
    setIsModalVisible(true);
  }

  return (
    <div className="container-input-button-ingressar">
      <button
        className="button-ingressar-pelicula"
        style={{ fontWeight: "normal" }}
        onClick={handleModalVisible}
      >
        {title}
      </button>

      {isModalVisible && (
        <div>
          {type === "event" ? (
            <ModalIngressarEvento />
          ) : (
            <ModalIngressarPelicula />
          )}
        </div>
      )}
    </div>
  );
};

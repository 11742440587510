import React, { useEffect, useRef, useState } from "react";
import { api } from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Input, Select, AutoComplete } from "antd";
import { Box, Text } from "grommet";
import moment from "moment";

registerLocale("es", es);
const { Option } = Select;

export const ModalAgregarHrEvento = ({ eventoId }) => {
  const [isModalVisible, setisModalVisible] = useState(true);
  const _refDatePicker = useRef(null);

  function handleModalVisible() {
    window.location.reload();
  }

  const [salas, setSalas] = useState([]);
  const [horarioData, setHorarioData] = useState({});
  const [salvando, setSalvando] = useState(false);

  async function getSalas() {
    let token = localStorage.token;
    const response = await api.get("/v1/sala/pesquisar", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setSalas(response.data.data);
  }

  useEffect(() => {
    setisModalVisible(isModalVisible);
    setHorarioData({ ...horarioData, filme_id: eventoId });
    getSalas();
  }, []);


  const moneyMask = (val) => {
    if (val) {
      val = val.replace(".", "").replace(",", "").replace(/\D/g, "");
      setPreco({
        ...preco,
        valor: new Intl.NumberFormat("es-CL", {
          currency: "CLP",
          style: "currency",
        }).format(val),
      });

    } else setPreco({ ...preco, valor: "$ 0" });
  };


  const [evento, setEvento] = React.useState({
    datas: [],
    horas: [],
    precos: [],
  });

  const handleSubmit = () => {
    setSalvando(true);
    let token = localStorage.token;

    api
      .post("/v2/evento/adicionar-horario", evento, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(({ data }) => {
        window.location.reload();
      })
      .catch((error) => alert("erro"));
  };
  React.useEffect(() => {
    setEvento({
      datas: [],
      horas: [],
      precos: [],
      evento_id: eventoId,
    });
  }, []);

  const options = [{ value: "Niños" }, { value: "Adultos" }];

  const selecionarDatePicker = () => {
    if (_refDatePicker !== null) {
      _refDatePicker.current.handleFocus();
    }
  };

  const updateHandlerData = (data) => {
    setEvento({
      ...evento,
      datas: [...evento.datas, moment(data).format("Y-MM-DD hh:mm:ss")],
    });
  };

  const updateHandleHora = (event) => {
    setEvento({ ...evento, horas: [...evento.horas, event.target.value] });
  };

  const removeData = (index) => {
    setEvento({
      ...evento,
      datas: evento.datas.filter((item, i) => i !== index),
    });
  };

  const removeHora = (index) => {
    setEvento({
      ...evento,
      horas: evento.horas.filter((item, i) => i !== index),
    });
  };

  const handleRemovePreco = (index) => {
    setEvento({
      ...evento,
      precos: evento.precos.filter((item, i) => i !== index),
    });
  };
  const [preco, setPreco] = React.useState({
    valor: null,
    tipo: null,
  });

  const handleVerify = () => {
    if (preco.tipo && preco.valor) {
      let newEvento = evento;
      newEvento.precos.push(preco);

      setPreco({ valor: "$ 0", tipo: null });
    }
  };

  const handlePublico = (val) => {
    setPreco({
      ...preco,
      tipo: val,
    });

  };
  return (
    <>
      {isModalVisible && (
        <div
          className="container-area-modal-agregrar-hr-copy"
        >
          <button
            className="button-closed-agregrar-hr"
            onClick={handleModalVisible}
          >
            X
          </button>

          <div className="title-modal-agregrar-hr" style={{ margin: 0 }}>
            <h1>Agregar Horario</h1>
          </div>
          <div className="cont-agr-hr">
            <div className="container-main-body">
              <div className="container-main-body-column-n">
                {/* FINAL PRIMEIRO INPUT*/}
                <Box width={"50%"}>
                  <Text color={"white"}>Sala</Text>
                  <Select
                    style={{ width: "100%" }}
                    title="sala"
                    onChange={(e, opt) =>
                      setEvento({ ...evento, sala_id: e, sala: opt.children })
                    }
                    placeholder="seleccionar"
                  >
                    {salas.map((item) => (
                      <Option value={item.id}>{item.nome}</Option>
                    ))}
                  </Select>
                </Box>

              </div>

              <Box
                direction="row"
                justify="between"
                gap="small"
                margin={{ top: "10px", bottom: "-7px" }}
              >
                <Box width={"50%"}>
                  <Text color={"white"}>Tipo</Text>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => setEvento({ ...evento, tipo: e })}
                    placeholder="seleccionar"
                  >
                    <Option value={"capacidad"}>Cantidad</Option>
                    <Option value={"asiento"}>Asiento</Option>
                  </Select>
                </Box>

                <Box width={"50%"}>
                  <Text color={"white"}>Número</Text>
                  <Input
                    type="number"
                    onChange={(e) =>
                      setEvento({ ...evento, quantidade: e.target.value })
                    }
                  />
                </Box>
              </Box>

              {/* FINAL SEGUNDO INPUT*/}
              <div
                className="container-main-body-column-n"
                style={{ height: "100px" }}
              >
                <div className="container-main-input-n">
                  <p className="label-texto">Fecha</p>
                  <input
                    type="text"
                    name="datas"
                    onClick={selecionarDatePicker}
                    value={horarioData.datas}
                    autoComplete="off"
                  />
                  <DatePicker
                    locale="es"
                    className="input-invisivel"
                    ref={_refDatePicker}
                    selected={null}
                    onChange={updateHandlerData}
                  />
                </div>
                <div className="container-main-input-n">
                  <p className="label-texto">Hora</p>
                  <input type="time" name="hora" onChange={updateHandleHora} />
                </div>
              </div>
              {evento.datas || evento.horas ? (
                <div className="hora-flex">
                  <div>
                    {evento.datas.length ? (
                      <span class="title-item">Fechas: </span>
                    ) : (
                      false
                    )}
                    {evento.datas.length
                      ? evento.datas.map((data, index) => (
                          <div key={index}>
                            <span class="item-text">
                              {moment(data).format("DD/MM/Y")}
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              className="button-remove"
                              onClick={() => removeData(index)}
                            >
                              X
                            </span>
                            <br />
                          </div>
                        ))
                      : false}
                  </div>
                  <div>
                    {evento.horas.length ? (
                      <span class="title-item">Horas: </span>
                    ) : (
                      false
                    )}
                    {evento.horas.length
                      ? evento.horas.map((data, index) => (
                          <div key={index}>
                            <span class="item-text">{data}</span>
                            <span
                              className="button-remove"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeHora(index)}
                            >
                              X
                            </span>
                            <br />
                          </div>
                        ))
                      : false}
                  </div>
                </div>
              ) : (
                false
              )}

              {/* FINAL TERCEIRO  INPUT*/}
              <div className="container-main-body-column-n">
                <div className="container-main-input-n">
                  <p>Público</p>
                  <AutoComplete
                    style={{
                      width: "100%",
                    }}
                    options={options}
                    onChange={(e, opt) => handlePublico(e)}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    value={preco.tipo}
                  />
                </div>
                <div className="container-main-input-n">
                  <p>Precio</p>
                  <Input
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      moneyMask(e.target.value);
                    }}
                    value={preco.valor}
                    placeholder="$ 0"
                  />
                </div>
                <Box align="center" justify="center" alignSelf="center">
                  <button
                    className="footer-button-add-horario"
                    onClick={handleVerify}
                  >
                    <span>Agregar precio</span>
                  </button>
                </Box>
              </div>

              {evento.precos.map((item, index) => {
                return (
                  <Box direction="row" justify="between">
                    <Text>{item.tipo}:</Text>
                    <Text>{item.valor}</Text>
                    <Text
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemovePreco(index)}
                    >
                      X
                    </Text>
                  </Box>
                );
              })}
            </div>
          </div>

          <div className="footer">
            <button
              className="footer-button-add-horario"
              onClick={handleSubmit}
            >
              <span>{salvando ? "Ingresando" : "Ingresar"}</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

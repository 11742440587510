import React, { useState } from "react";
import "./Navbar.styles.css";
import { useLocation, Link } from "react-router-dom";

export const Navbar = () => {

    const location = useLocation();
    const [pathName, setPathName] = useState(location.pathname);

    const usuario = JSON.parse(localStorage.getItem('usuario'));

    const [papelId, setPapelId] = useState(usuario.papel_id);
    return (
        <header className="container-header">
            <nav className="container-navigation">
                <ul className={papelId ? "contianer-ul" : "container-ul-client"}>
                    {
                        (papelId == 1 || papelId == 4) ? <>
                            <li className={pathName === '/cartelera' || pathName === '/pelicula' || pathName === '/butacas' || pathName === '/pago' || pathName === '/ticket' || pathName === '/finalizado' ? 'contianer-li active' : 'contianer-li'}> <Link to="/cartelera">Cartelera</Link></li>
                            <li className={pathName === '/calendario' ? 'contianer-li active' : 'contianer-li'}> <Link to="/calendario">Calendario</Link></li>
                            <li className={pathName === '/salas' ? 'contianer-li active' : 'contianer-li'}> <Link to="/salas">Salas</Link></li>
                            <li className={pathName === '/informes' ? 'contianer-li active' : 'contianer-li'}> <Link to="/informes">Informes</Link></li>
                            {
                                papelId == 1 &&
                                <>
                                    <li className={pathName === '/usuarios' ? 'contianer-li active' : 'contianer-li'}> <Link to="/usuarios">Usuarios</Link></li>
                                    <li className={pathName === '/cupon' ? 'contianer-li active' : 'contianer-li'}> <Link to="/cupon">Cupones</Link></li>
                                </>
                            }
                        </>
                            : papelId == 2 ? <>
                                <li className={pathName === '/cartelera' || pathName === '/pelicula' || pathName === '/butacas' || pathName === '/pago' || pathName === '/ticket' || pathName === '/finalizado' ? 'contianer-li active' : 'contianer-li'}> <Link to="/cartelera">Cartelera</Link></li>
                                <li className={pathName === '/informes' ? 'contianer-li active' : 'contianer-li'}> <Link to="/informes">Informes</Link></li>
                            </>
                                : <>
                                    <li className={pathName === '/autenticacion' ? 'contianer-li active' : 'contianer-li'}> <Link to="/autenticacion">Autenticación</Link></li>
                                </>
                    }

                </ul>
            </nav>
        </header>
    );
};
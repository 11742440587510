import axio from "axios";

export const api = axio.create(({
    baseURL: 'https://api-cinesol.busticket.cl/api',
    timeout: 60000,
}))

/* export const api = axio.create({
  baseURL: "http://127.0.0.1:8000/api",
  timeout: 60000,
}); */

// export const api = axio.create(({
//   baseURL: 'http://192.168.3.7:8000/api',
//   timeout: 5000,
// }))
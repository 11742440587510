import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";
import "./Billete.styles.css";
import { User } from "../../components/User";
import { api } from "../../services/api";
export const Billete = () => {
  const history = useHistory();
  const [filme, setFilme] = useState({});
  const [ingresso, setIngresso] = useState({});
  const paramsG = new URLSearchParams(window.location.search);

  const finalizarCompra = function () {
    let ingresso = JSON.parse(localStorage.ingresso);

    let token = localStorage.token;
    if (!ingresso || !ingresso.horario_id) {
      history.push("/cartelera");
    }

    ingresso.formas_pagamento = JSON.parse(localStorage.formasPagamento);

    if (JSON.parse(localStorage.formasPagamento).length === 0)
      ingresso.formas_pagamento = [
        { forma_pagamento: "Cupon", valor: "0", troco: 0 },
      ];

    api
      .post("/v1/ingresso/finalizar-compra", ingresso, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let params = new URLSearchParams(window.location.search);

        localStorage.ingressosF = localStorage.ingressos;
        localStorage.ingressoF = localStorage.ingresso;
        localStorage.removeItem("ingresso");
        localStorage.removeItem("ingressos");
        localStorage.removeItem("formasPagamento");
        history.push("/finalizado?id=" + params.get("id"));
      })
      .catch(function (response) {
        let params = new URLSearchParams(window.location.search);
        history.push("/finalizado?id=" + params.get("id"));
      });
  };

  async function getFilme() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/v1/filme/pesquisar-info/" + params.get("id"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setFilme(response.data.data);
  }

  useEffect(() => {
    getFilme();

    let ingresso = JSON.parse(localStorage.ingresso);
    setIngresso(ingresso);
  }, []);

  useEffect(() => {
  }, [ingresso]);

  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      <div className="container-category-filme">
        <div className="container-pai">
          <div className="container-pai-filme">
            <div className="detalhes-filme">
              <img
                src={filme.link_img_capa}
                alt="foto=capa"
                className="img-capa"
              />
              <div className="infos-filme-carteira">
                <h2 className="title-sinopse">{filme.titulo}</h2>
                <p>2019 - Ação | Fantasia</p>
                <p>Diretor: {filme.diretor}</p>
                <p>
                  {" "}
                  <AiFillStar className="iconStar" />
                  7.5 (85,741)
                </p>
                <h6>
                  We all have a superhero inside us, it just takes a bit of
                  magic to bring it out. In Billy Batson's case, by shouting out
                  one word - SHAZAM. - this streetwise fourteen-year-old foster
                  kid can turn into the grown-up superhero Shazam
                </h6>
              </div>
            </div>
            <div className="informacoes-detalhes">
              <div className="detalhes-filme-direita">
                <div className="container-main-sesion">
                  <div className="container-sesion">
                    <a
                      href={"/pelicula?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      <div className="bolinha1">
                        <span>01</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <a
                      href={"/butacas?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      <div className="bolinha1">
                        <span>02</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <a
                      href={"/pago?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      <div className="bolinha1">
                        <span>03</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="main-bolinha1">
                      <span>04</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>05</span>
                    </div>
                  </div>
                </div>
                <div className="description-bolinha">
                  <p>
                    <a
                      href={"/pelicula?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      Selección
                    </a>
                  </p>
                  <p>
                    <a
                      href={"/butacas?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      Butacas
                    </a>
                  </p>
                  <p>
                    <a
                      href={"/pago?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      Pago
                    </a>
                  </p>
                  <p>Ticket</p>
                  <p>Finalizado</p>
                </div>
              </div>

              <div className="content-header-payment">
                <h1 className="title-billete">
                  DETALLES DE LA ENTRADA DE CINE
                </h1>

                <div className="description-first">
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    PELÍCULA
                  </h3>
                  <p>{filme.titulo}</p>
                </div>
                <div className="description-first">
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    HORA
                  </h3>
                  <p>{ingresso.horario ? ingresso.horario.horario : ""}</p>
                </div>
                <div className="description-first">
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    SESIÓN
                  </h3>
                  <p>{ingresso.horario ? ingresso.horario.qualidade : ""}</p>
                </div>
                <div className="description-first">
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    ASIENTOS
                  </h3>
                  <div className="assentos-items">
                    {ingresso.assentos ? (
                      ingresso.assentos.map((assento) => (
                        <div className="assento-item">
                          {assento.assento_nome}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div
                  className="footer-button-billete"
                  style={{ marginBottom: 50 }}
                >
                  <a
                    href={"/pago?id=" + paramsG.get("id")}
                    className="button-cancel"
                  >
                    <span>Volver</span>
                  </a>
                  <button onClick={finalizarCompra} className="button-continue">
                    <span>Finalizar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

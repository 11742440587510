import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { api } from "../../services/api";
export const EventFinally = ({ data, evento, selectedInfo }) => {
  const history = useHistory();
  const paramsG = new URLSearchParams(window.location.search);


  const finalizarCompra = function () {
    let token = localStorage.token;

    api
      .post("/v2/evento/comprar", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data.message === "success")
          history.push("/evento/finalziado/" + data.vid);
      });

    return 1;
    
  };


  useEffect(() => {}, []);

  return (
    <div className="informacoes-detalhes">
      <div className="detalhes-filme-direita">
        <div className="container-main-sesion">
          <div className="container-sesion">
            <a
              href={"/pelicula?id=" + paramsG.get("id")}
              style={{ color: "#777777" }}
            >
              <div className="bolinha1">
                <span>01</span>
              </div>
            </a>
            <hr className="container-line" />
          </div>
          <div className="container-sesion">
            <a href={"/cartelera"} style={{ color: "#777777" }}>
              <div className="bolinha1">
                <span>02</span>
              </div>
            </a>
            <hr className="container-line" />
          </div>
          <div className="container-sesion">
            <a href={"/cartelera"} style={{ color: "#777777" }}>
              <div className="bolinha1">
                <span>03</span>
              </div>
            </a>
            <hr className="container-line" />
          </div>
          <div className="container-sesion">
            <div className="main-bolinha1">
              <span>04</span>
            </div>
            <hr className="container-line" />
          </div>
          <div className="container-sesion">
            <div className="bolinha1">
              <span>05</span>
            </div>
          </div>
        </div>
        <div className="description-bolinha">
          <p>
            <a
              href={"/pelicula?id=" + paramsG.get("id")}
              style={{ color: "#777777" }}
            >
              Selección
            </a>
          </p>
          <p>
            <a href={"/cartelera"} style={{ color: "#777777" }}>
              Entradas
            </a>
          </p>
          <p>
            <a
              href={"/pago?id=" + paramsG.get("id")}
              style={{ color: "#777777" }}
            >
              Pago
            </a>
          </p>
          <p>Ticket</p>
          <p>Finalizado</p>
        </div>
      </div>

      <div className="content-header-payment">
        <h1 className="title-billete">DETALLES DE LA ENTRADA DE CINE</h1>

        <div className="description-first">
          <h3
            style={{
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}
          >
            EVENTO
          </h3>
          <p>
            {" "}
            <p>{evento.titulo}</p>
          </p>
        </div>
        <div className="description-first">
          <h3
            style={{
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}
          >
            HORA
          </h3>
          <p>{selectedInfo.servico.hora}</p>
        </div>

        <div className="footer-button-billete" style={{ marginBottom: 50 }}>
          <a href={"/cartelera"} className="button-cancel">
            <span>Volver</span>
          </a>
          <button onClick={finalizarCompra} className="button-continue">
            <span>Finalizar</span>
          </button>
        </div>
      </div>
    </div>
  );
};

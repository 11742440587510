import React, { useEffect, useState } from "react";
//components
import { Navbar } from "../../components/Navbar";
import { User } from "../../components/User";

import { api } from "../../services/api";

import Photocapa from "../../assets/photo-capa.png";

import "./Calendary.styles.css";
import { ButtonCalendary } from "../../components/Forms/ButtonCalendary";
import Item from "../../screens/Cartelera/item";
import { useHistory } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import HorariosEvento from "../../components/Horarios/HorariosEvento";

const breakPoints = [{ width: 1, itemsToShow: 4 }];

export const Calendary = () => {
  const [filmes, setFilmes] = useState([]);
  const [filme, setFilme] = useState(false);
  const [eventos, setEventos] = useState([]);
  const [evento, setEvento] = useState(false);

  const [queroExcluir, setQueroExcluir] = useState(false);
  const [queroExcluirFilme, setQueroExcluirFilme] = useState(false);

  let params = new URLSearchParams(window.location.search);

  const usuario = JSON.parse(localStorage.usuario);

  const history = useHistory();

  async function getFilme(filme_id) {
    let token = localStorage.token;

    history.push("/calendario?id=" + filme_id);

    const response = await api.get("v1/filme/pesquisar-info/" + filme_id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setFilme(response.data.data);
  }

  async function guardarPelicula() {
    let token = localStorage.token;

    const data = {
      id: filme.id,
      titulo: filme.titulo,
      classificacao: filme.classificacao,
      duracao_minutos: filme.duracao_minutos,
      diretor: filme.diretor,
      data_lancamento: filme.data_lancamento,
      distribuidor: filme.distribuidor,
      trailer: filme.trailer,
      estreia: filme.estreia,
    }

    const response = await api.post("v1/filme/salvar", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (response.status == 200) {
      alert('Pelicula actualizada correctamente')
    } else {
      alert('Error al guardar la película')
    }
  }

  const handleFilmeData = (e) => {
    e.preventDefault();
    setFilme({ ...filme, [e.target.name]: e.target.value });
  };

  const excluirHorario = (horario_id) => {
    let token = localStorage.token;
    api
      .post(
        "v1/filme/deletar-horario/" + horario_id,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function () {
        window.location.reload();
      })
      .catch(function (error) {
        alert(error.response.data.mensagem)
      });
  };

  const preexcluirFilme = (filme_id) => {
    setQueroExcluirFilme(filme_id);
  };

  const excluirFilme = (filme_id) => {
    let token = localStorage.token;
    api
      .post(
        "v1/filme/deletar/" + filme_id,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function () {
        history.push("/cartelera");
      })
      .catch(function () {
        history.push("/cartelera");
      });
  };

  const preexcluirHorario = (horario_id) => {
    setQueroExcluir(horario_id);
  };

  async function getFilmes() {
    let token = localStorage.token;
    const response = await api.get("v1/filme/pesquisar", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setFilmes(response.data.data.filme);
    setEventos(response.data.data.eventos);
  }

  useEffect(() => {
    getFilmes();

    if (params.get("id")) {
      getFilme(params.get("id"));
    }
  }, []);

  useEffect(() => {
  }, [filmes]);

  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      <div className="container-search-filmes">
        <h1>Seleccione una película para agregar funciones</h1>
        <input
          type="text"
          name="search-filme"
          placeholder="Buscar película"
          className="search-filme"
        />
      </div>
      <div className="container-category-filme">
        {/**PRIMEIROS  LANÇAMENTOS */}
        <div className="container-content">
          <div className="main-container">
            <Carousel breakPoints={breakPoints}>
              {filmes.map((item) => (
                <button
                  className="container-listing-filmes"
                  onClick={() => getFilme(item.id)}
                >
                  <Item>
                    <div className="container-main-image-listagem">
                      <img
                        src={item.link_img_capa}
                        alt={item.typefilmes}
                        className="image-listagem-filmes"
                      />
                      <div className="container-fotter-filmes">
                        <h3 className="container-title-filme">{item.titulo}</h3>
                      </div>
                    </div>
                  </Item>
                </button>
              ))}
            </Carousel>

            <Carousel breakPoints={breakPoints}>
              {eventos.map((item, key) => (
                <button
                  className="container-listing-filmes"
                  onClick={() => {
                    setFilme(false);
                    setEvento(eventos[key]);
                  }}
                >
                  <Item>
                    <div className="container-main-image-listagem">
                      <img
                        src={
                          api.defaults.baseURL.slice(0, -4) +
                          "/assets/eventos/" +
                          item.capa
                        }
                        alt={item.typefilmes}
                        className="image-listagem-filmes"
                      />
                      <div className="container-fotter-filmes">
                        <h3 className="container-title-filme">{item.titulo}</h3>
                      </div>
                    </div>
                  </Item>
                </button>
              ))}
            </Carousel>
          </div>
          {/**PRIMEIROS  LANÇAMENTOS */}
        </div>

        {filme && (
          <div className="container-pai">
            <div className="container-pai-filme">
              <div className="detalhes-filme">
                <img src={filme.link_img_capa} alt="" className="img-capa" />
                <div className="infos-filme-calendary">
                  <h2 className="title-sinopse">{filme.titulo}</h2>
                  <p>2019 - Ação | Fantasia</p>
                  <h6>
                    We all have a superhero inside us, it just takes a bit of
                    magic to bring it out. In Billy Batson's case, by shouting
                    out one word - SHAZAM. - this streetwise fourteen-year-old
                    foster kid can turn into the grown-up superhero Shazam
                  </h6>
                </div>
              </div>
              <div className="informacoes-detalhes">
                <div className="detalhes-filme-direita">
                  <h3 className="title-detalhes">
                    Detalles de la película
                  </h3>
                  <div className="container-input-main">
                    <div className="container-input" style={{ width: "100%" }}>
                      <h3 className="title-input">Link de la película</h3>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder=""
                        value={
                          "https://webcinesol.busticket.cl/pelicula?id=" +
                          filme.id
                        }
                        disabled={true}
                        className="input-detalhe"
                      />
                    </div>
                  </div>

                  <div className="container-input-main">
                    <div className="container-input">
                      <h3 className="title-input">Nombre de la película</h3>
                      <input
                        type="text"
                        name="titulo"
                        value={filme.titulo}
                        onChange={handleFilmeData}
                        required
                        className="input-detalhe"
                      />
                      <h3 className="title-input">Género</h3>
                      <input
                        ype="text"
                        name="name"
                        disabled={true}
                        placeholder=""
                        className="input-detalhe"
                      />
                      <h3 className="title-input">Director</h3>
                      <input
                        type="text"
                        name="diretor"
                        required
                        value={filme.diretor}
                        onChange={handleFilmeData}
                        className="input-detalhe"
                      />
                      <h3 className="title-input">Distribuidor</h3>
                      <input
                        type="text"
                        name="distribuidor"
                        required
                        value={filme.distribuidor}
                        onChange={handleFilmeData}
                        className="input-detalhe"
                      />
                    </div>
                    <div className="container-input">
                      <h3 className="title-input">Duración (Minutos)</h3>
                      <input
                        type="text"
                        name="duracao_minutos"
                        required
                        value={filme.duracao_minutos}
                        onChange={handleFilmeData}
                        className="input-detalhe"
                      />
                      <h3 className="title-input">Clasificación de edad</h3>
                      <input
                        type="text"
                        name="classificacao"
                        required
                        value={filme.classificacao}
                        onChange={handleFilmeData}
                        className="input-detalhe"
                      />
                      <h3 className="title-input">Fecha de lanzamiento</h3>
                      <input
                        type="date"
                        name="data_lancamento"
                        value={filme.data_lancamento}
                        onChange={handleFilmeData}
                        className="input-detalhe"
                      />
                      <h3 className="title-input">Trailer</h3>
                      <input
                        type="text"
                        name="trailer"
                        onChange={handleFilmeData}
                        value={filme.trailer}
                        className="input-detalhe"
                      />
                    </div>
                  </div>
                  <div>
                    
                    {(usuario.papel_id === 1 || usuario.papel_id === 4) && !queroExcluirFilme ? (
                      <button
                        className="btn-excluir"
                        onClick={() => preexcluirFilme(true)}
                      >
                        Eliminar película
                      </button>
                    ) : (
                      <></>
                    )}
                    {(usuario.papel_id === 1 || usuario.papel_id === 4) && queroExcluirFilme ? (
                      <>
                        <button
                          className="btn-excluir"
                          onClick={() => excluirFilme(filme.id)}
                        >
                          Confirmar
                        </button>
                        <button
                          className="btn-cancelar-excluir"
                          onClick={() => preexcluirFilme(false)}
                        >
                          Cancelar
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                    <button
                      className="btn-excluir" style={{ backgroundColor: '#00C455', marginLeft: '10%' }}
                      onClick={() => guardarPelicula(true)}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
                <div className="calendario-direita">
                  <div className="calendario-direita-cabecalho">
                    <h3 className="title-calendary">Calendario</h3>
                    <ButtonCalendary
                      title="AGREGAR HORARIO"
                      iteIid={params.get("id")}
                      itemid={params.get("id")}
                      type="film"
                    />
                  </div>
                  {filme.horarios.length ? (
                    <table className="tabela-cine">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Sala</th>
                          <th>Hora</th>
                          <th>Audio</th>
                          <th>Calidad</th>
                          <th>Precio</th>
                          {(usuario.papel_id === 1 || usuario.papel_id === 4) && (
                            <th className="text-center">Opciones</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {filme.horarios.map((horario) => (
                          <tr>
                            <td>{horario.data}</td>
                            <td>{horario.sala.nome}</td>
                            <td>{horario.horario}</td>
                            <td>{horario.audio == "dub" ? "DOB" : "SUB"}</td>
                            <td>{horario.qualidade}</td>
                            <td>{horario.valor}</td>
                            {(usuario.papel_id === 1 || usuario.papel_id === 4) &&
                              queroExcluir !== horario.id && (
                                <td className="text-center">
                                  <button
                                    className="btn-excluir"
                                    onClick={() =>
                                      preexcluirHorario(horario.id)
                                    }
                                  >
                                    Eliminar
                                  </button>
                                </td>
                              )}

                            {(usuario.papel_id === 1 || usuario.papel_id === 4) &&
                              queroExcluir === horario.id && (
                                <td className="text-center">
                                  <button
                                    className="btn-excluir"
                                    onClick={() => excluirHorario(horario.id)}
                                  >
                                    Confirmar
                                  </button>
                                  <button
                                    className="btn-cancelar-excluir"
                                    onClick={() => preexcluirHorario()}
                                  >
                                    Cancelar
                                  </button>
                                </td>
                              )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Ningún horario registrado hasta el momento!</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {evento && (
          <HorariosEvento
            eventoPai={evento}
            usuario={usuario}
            setEventoPai={setEvento}
          />
        )}
      </div>
    </div>
  );
};

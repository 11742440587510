import React from "react";

import { Box, DataTable, Heading, Meter, Text } from "grommet";
import Pagination from "@mui/material/Pagination";
import { api } from "../../services/api";

const amountFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "NIS",
  minimumFractionDigits: 2,
});

const columns = [
  {
    property: "transacao_numero",
    header: "Número de transacción",
    primary: true,
  },
  {
    property: "transacao_rescode",
    header: "Codigo",
    render: (datum) =>
      datum.transacao_rescode === null ? "-7" : datum.transacao_rescode,
  },
  {
    property: "transacao_restext",
    header: "Respuesta",
  },
  {
    property: "email_enviado",
    header: "Email",
    render: (datum) => (datum.email_enviado === 1 ? "Sí" : "No"),
  },
  {
    property: "pago",
    header: "Pago",
    render: (datum) => (datum.pago === 1 ? "Sí" : "No"),
  },
  {
    property: "valor",
    header: "Valor",
    render: (datum) =>
      "$" +
      datum.valor.toLocaleString("pt-br", {
        currency: "CLP",
      }),
    align: "end",
    aggregate: "sum",
  },
];

function TableOrder({ cliente, modal }) {
  const [page, setPage] = React.useState(1);
  const [npage, setNPage] = React.useState(0);
  const [pedidos, setPedidos] = React.useState([]);

  const [loaded, setLoaded] = React.useState(false);

  const load = () => {

    api
      .get(`/v2/pedidos/${cliente.id}/${page}`)
      .then(({ data }) => {
        setPedidos(data.pedidos);
        setNPage(data.n_pages);
        setLoaded(true);
      })
      .catch((error) => console.log("error"));
  };

  const handlePaginate = (pg) => {
    setLoaded(false);

    api
      .get(`/v2/pedidos/${cliente.id}/${pg}`)
      .then(({ data }) => {
        setPage(pg);
        setPedidos(data.pedidos);
        setLoaded(true);
      })
      .catch((error) => console.log("error"));
  };

  React.useEffect(() => {
    if (cliente.id) {
      setPage(1);
      load();
    }
  }, [cliente]);
  return (
    <Box
      align="center"
      pad="large"
      style={{ maxHeight: "90vh", overflowY: "scroll" }}
      gap="small"
    >
      <Heading level={3}>
        <Box gap="small">
          <h5 style={{ padding: '0px', margin: '0px' }}><b>Pedidos de {cliente.nome}</b></h5>
          <h5 style={{ padding: '0px', margin: '0px' }}><b>Email: {cliente.email}</b></h5>
          <p style={{ fontSize: '17px', padding: '0px', margin: '0px' }}>Deslizando hacia abajo podrá ver el detalle de cada compra.</p>
        </Box>
      </Heading>
      {loaded && <DataTable columns={columns} data={pedidos} />}

      <Pagination
        page={page}
        count={npage}
        variant="outlined"
        shape="rounded"
        onChange={(event, page) => handlePaginate(page)}
      />
    </Box>
    // </Grommet>
  );
}

TableOrder.storyName = "Infinite Scroll";

export default TableOrder;

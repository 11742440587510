import React, { useState, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { User } from "../../components/User";

import "antd/dist/antd.css";

import "./Cupon.styles.css";
import { useHistory } from "react-router-dom";
import { CupomScreen } from "../PromocionesScreens/Cupons";
import { CreateCupon } from "../PromocionesScreens/CreateCupon";
import { CreateCuponForSale } from "../PromocionesScreens/CreateCuponForSale";

export const Cupon = () => {
  const history = useHistory();
  const [viewCupom, setView] = useState(true);
  const [dataView, setDataView] = useState({});
  const [forSale, setForSale] = useState(false);

  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    if (usuario.papel_id != 1) {
      history.push("/cartelera");
    }
  }, []);

  const handleView = (d, forSale) => {
    setView(!viewCupom);
    setForSale(forSale);
    if (typeof d != "undefined" && d != null) {
      setDataView(d);
    } else {
      setDataView(false);
    }
  };

  return (
    <div>
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      {viewCupom ? (
        <CupomScreen changeScreen={(d, forSale = false) => handleView(d, forSale)} abaInitial={forSale === true ? 1 : 0}  />
      ) : forSale ? (
        <CreateCuponForSale dataC={dataView} changeScreen={(d) => handleView(d, true)} />
      ) : (
        <CreateCupon dataC={dataView} changeScreen={(d) => handleView(d, false)} />
      )}
    </div>
  );
};

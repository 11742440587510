import React from "react";
import { api } from "../../services/api";
import { ButtonCalendary } from "../Forms/ButtonCalendary";
import moment from "moment";
const HorariosEvento = ({ eventoPai, usuario, setEventoPai }) => {
  const [queroExcluirFilme, setQueroExcluirFilme] = React.useState(false);
  const [queroExcluir, setQueroExcluir] = React.useState(false);
  const [evento, setEvento] = React.useState();

  React.useEffect(() => {
    let token = localStorage.token;
    api.defaults.headers.Authorization = `Bearer ${token}`;
    setEvento(eventoPai);
  }, [eventoPai]);

  return (
    <div className="container-pai">
      {evento && (
        <div className="container-pai-filme">
          <div className="detalhes-filme">
            <img
              src={
                api.defaults.baseURL.slice(0, -4) +
                `/assets/eventos/${evento?.capa}`
              }
              alt=""
              className="img-capa"
            />
            <div className="infos-filme-calendary">
              <h2 className="title-sinopse">{evento.titulo}</h2>
              <p>2019 - Ação | Fantasia</p>
              <h6>
                We all have a superhero inside us, it just takes a bit of magic
                to bring it out. In Billy Batson's case, by shouting out one
                word - SHAZAM. - this streetwise fourteen-year-old foster kid
                can turn into the grown-up superhero Shazam
              </h6>
            </div>
          </div>
          <div className="informacoes-detalhes">
            <div className="detalhes-filme-direita">
              <h3 className="title-detalhes">
                Detalles del evento
                {(usuario.papel_id === 1 || usuario.papel_id === 4) && !queroExcluirFilme ? (
                  <div>
                    <button
                      className="btn-excluir"
                      onClick={() => setQueroExcluirFilme(true)}
                    >
                      Eliminar evento
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                {(usuario.papel_id === 1 || usuario.papel_id === 4) && queroExcluirFilme ? (
                  <div>
                    <button
                      className="btn-excluir"
                      onClick={() => {
                        api
                          .post(`v2/evento/deletar/${evento.id}`)
                          .then(() => window.location.reload());
                      }}
                    >
                      Confirmar
                    </button>
                    <button
                      className="btn-cancelar-excluir"
                      onClick={() => setQueroExcluirFilme(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </h3>
              <div className="container-input-main">
                <div className="container-input" style={{ width: "100%" }}>
                  <h3 className="title-input">Link del evento</h3>
                  <input
                    ype="text"
                    style={{ width: "100%" }}
                    placeholder=""
                    value={
                      "https://webcinesol.busticket.cl/eventos/" + evento.id
                    }
                    disabled={true}
                    className="input-detalhe"
                  />
                </div>
              </div>

              <div
                className="container-input-main"
                style={{ alignItems: "start" }}
              >
                <div className="container-input">
                  <h3 className="title-input">Nombre de la película</h3>
                  <input
                    ype="text"
                    name="name"
                    placeholder=""
                    value={evento.titulo}
                    disabled={true}
                    className="input-detalhe"
                  />
                  <h3 className="title-input">Distribuidor</h3>
                  <input
                    ype="text"
                    name="name"
                    disabled={true}
                    value={evento.distribuidor}
                    placeholder=""
                    className="input-detalhe"
                  />
                </div>
                <div className="container-input">
                  <h3 className="title-input">Duración</h3>
                  <input
                    ype="text"
                    name="name"
                    disabled={true}
                    placeholder=""
                    value={evento.duracao_minutos + " minutos"}
                    className="input-detalhe"
                  />
                </div>
              </div>
            </div>
            <div className="calendario-direita">
              <div className="calendario-direita-cabecalho">
                <h3 className="title-calendary">Calendario</h3>
                <ButtonCalendary
                  title="AGREGAR HORARIO"
                  itemId={evento.id}
                  type="event"
                />
              </div>
              {evento.calendario?.length ? (
                <table className="tabela-cine">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Sala</th>
                      <th>Hora</th>
                      <th>Tipo</th>
                      <th>Cantidad</th>
                      {(usuario.papel_id === 1 || usuario.papel_id === 4) && (
                        <th className="text-center">Opciones</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {evento.calendario.map((horario, index) => (
                      <tr>
                        <td>{moment(horario.data).format("DD/MM")}</td>
                        <td>{horario.sala}</td>
                        <td>{horario.hora}</td>
                        <td>{horario.tipo}</td>
                        <td>{horario.quantidade}</td>
                        {(usuario.papel_id === 1 || usuario.papel_id === 4) && queroExcluir !== horario.id && (
                          <td className="text-center">
                            <button
                              className="btn-excluir"
                              onClick={() => setQueroExcluir(horario.id)}
                            >
                              Eliminar
                            </button>
                          </td>
                        )}

                        {(usuario.papel_id === 1 || usuario.papel_id === 4) && queroExcluir === horario.id && (
                          <td className="text-center">
                            <button
                              className="btn-excluir"
                              onClick={() =>
                                api
                                  .post(
                                    `v2/evento/deletar/servico/${horario.id}`
                                  )
                                  .then(() => {
                                    let event = evento;

                                    event.calendario = event.calendario.filter(
                                      (item, i) => i !== index
                                    );

                                    setEvento(event);
                                    setQueroExcluir(false);
                                  })
                              }
                            >
                              Confirmar
                            </button>
                            <button
                              className="btn-cancelar-excluir"
                              onClick={() => setQueroExcluir(false)}
                            >
                              Cancelar
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <a>
                  Ningún horario registrado hasta el momento!
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HorariosEvento;

import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { SearchOutlined } from '@material-ui/icons';

export default function InputSearch({buscar}) {
  return (
    <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 250, height: '40px' }}>
      <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Buscar..." onChange={buscar} />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
        <SearchOutlined />
      </IconButton>
    </Paper>
  );
}
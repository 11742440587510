import { api } from "../../services/api";
import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { User } from "../../components/User";
import "./Clients.styles.css";
import AdicionarClienteModal from "../../components/ModalAdicionarCliente/AdiconarCliente.modal.component";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableOrder from "./TableOrders";
import { Layer } from "grommet";
import InputSearch from "../../components/InputSearch";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

export const Clients = () => {

  const history = useHistory();

  const [modalAddCliente, setModalAddCleinte] = useState({ active: false });
  const [modalVisuCliente, setModalVisuCleinte] = useState({ active: false });

  const [queroExcluirCliente, setQueroExcluirCliente] = useState(false);

  const usuario = JSON.parse(localStorage.usuario);

  const [funcionarios, setFuncionarios] = useState([]);
  const [users, setUsers] = useState([]);

  async function getClientes() {
    let token = localStorage.token;

    const res = await api.get("/v2/usuarios/pesquisar", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setFuncionarios(res.data.funcionarios);

  }

  const preexcluirCliente = (cliente_id) => {
    setQueroExcluirCliente(cliente_id);
  };

  const excluirCliente = (cliente_id) => {
    let token = localStorage.token;
    api
      .post(
        "v1/cliente/deletar/" + cliente_id,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function () {
        getClientes();
      })
      .catch(function () { });
  };

  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    if (usuario.papel_id != 1) {
      history.push("/cartelera");
    }
    getClientes();
    handleNextPageCliente(0, '');
  }, []);


  const [cliente, setCliente] = useState([]);
  const handleVisu = async (cli) => {
    setCliente(cli);
    setModalAddCleinte({ active: true });
  };

  const handleVisuCliente = async (cli) => {
    setCliente(cli);
    setModalPedidos(true);
  };
  const [aba, setAba] = useState(0);
  const [modalpedidos, setModalPedidos] = useState(false);

  const [page, setPage] = useState(0);

  const changeAba = async (aba) => {
    setAba(aba);
    setPage(aba);
    if (aba == 0) {
    } else if (aba == 1) {
      // getInfosInformes();
    } else if (aba == 2) {
      //getInformes();
    }
  };

  function formatRut(doc) {
    return doc.replace(/[^0-9]/g, '')
      .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
  }

  const [clientePage, setClientePage] = React.useState(1);
  const [clientePageCurrent, setClientePageCurrent] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  async function handleNextPageCliente (pg, search = '') {
    search = search === '' ? null : search;
    setLoading(true);
    setSearchText(search)
    setClientePageCurrent(pg);

    let token = localStorage.token;

    api.get(`/v2/clientes/web/${pg}/${search}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then(({ data }) => {
      if (data) {
        setUsers(data.clientes);
        setClientePage(data.pages)
        setLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <AdicionarClienteModal
        funcionario={cliente}
        show={modalAddCliente.active}
        close={() => setModalAddCleinte({ active: false })}
      />

      {modalpedidos && (
        <Layer
          onEsc={() => setModalPedidos(false)}
          onClickOutside={() => setModalPedidos(false)}
        >
          <TableOrder
            modal={modalVisuCliente.active}
            cliente={cliente}
          />
        </Layer>
      )}

      <div className="container-home">
        <div className="container-capa">
          <img src={Photocapa} alt="photo-capa" className="photo-capa" />
          <User />
        </div>
        <Navbar />

        <div className="container-main-pai">
          <div className="abas">
            <button
              className={"aba " + (aba == 0 ? "active" : "")}
              onClick={() => changeAba(0)}
            >
              Funcionarios
            </button>
            <button
              className={"aba " + (aba == 1 ? "active" : "")}
              onClick={() => changeAba(1)}
            >
              Clientes
            </button>
          </div>
        </div>

        <div className="container-main-clientes">
          {page === 0 ? (
            <>
              {" "}
              <div className="header-search-clients">
                <div className="container-button-clientes">
                  <button
                    onClick={() => setModalAddCleinte({ active: true })}
                    className="button-cadastrar-clientes"
                  >
                    <span>CREAR FUNCIONARIO</span>
                  </button>
                </div>
                <h1 className="title-serach">
                  Busque por nombre de funcionario a continuación:
                </h1>
                <input
                  type="text"
                  name="search"
                  placeholder="Buscar Usuario"
                  className="input-search-client"
                />
              </div>
              <div className="container-table-clientes">
                <h1> Funcionarios: </h1>
                <table className="tabela-cine-cliente">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Cargo</th>
                      <th> Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {funcionarios &&
                      funcionarios.map((c, k) => (
                        <tr>
                          <td>{k + 1}</td>
                          <td>{c.nome}</td>
                          <td>{c.email}</td>
                          <td>
                            {c.papel_id === 1 && "Admin"}{" "}
                            {c.papel_id === 2 && "Vendedor"}{" "}
                            {c.papel_id === 3 && "Operador"}{" "}
                            {c.papel_id === 4 && "Admin 2"}{" "}
                          </td>
                          <td>
                            <button
                              style={{
                                backgroundColor: "rgb(255,199,0)",
                                color: "black",
                                border: "none",
                                padding: "2px 8px",
                                cursor: "pointer",
                                borderRadius: "6px",
                                fontFamily: "Poppins",
                                marginRight: "1vw",
                                fontSize: 13,
                              }}
                              onClick={() => {
                                handleVisu(c, 2);
                              }}
                            >
                              Editar
                            </button>

                            {usuario.papel_id === 1 &&
                              queroExcluirCliente != c.id ? (
                              <div>
                                <button
                                  className="btn-excluir"
                                  style={{
                                    backgroundColor: "rgb(240,20,47)",
                                    color: "white",
                                    border: "none",
                                    padding: "2px 8px",
                                    cursor: "pointer",
                                    borderRadius: "6px",
                                    fontFamily: "Poppins",
                                    marginRight: "1vw",
                                    fontSize: 13,
                                  }}
                                  onClick={() => preexcluirCliente(c.id)}
                                >
                                  Eliminar
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}

                            {usuario.papel_id === 1 &&
                              queroExcluirCliente == c.id ? (
                              <div>
                                <button
                                  style={{
                                    backgroundColor: "rgb(240,20,47)",
                                    color: "white",
                                    border: "none",
                                    padding: "2px 8px",
                                    cursor: "pointer",
                                    borderRadius: "6px",
                                    fontFamily: "Poppins",
                                    marginRight: "1vw",
                                    fontSize: 13,
                                  }}
                                  onClick={() => excluirCliente(c.id)}
                                >
                                  Confirmar
                                </button>
                                <button
                                  style={{
                                    backgroundColor: "rgb(200,200,200)",
                                    color: "black",
                                    border: "none",
                                    padding: "2px 8px",
                                    cursor: "pointer",
                                    borderRadius: "6px",
                                    fontFamily: "Poppins",
                                    marginRight: "1vw",
                                    fontSize: 13,
                                  }}
                                  onClick={() => preexcluirCliente(false)}
                                >
                                  Cancelar
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>{" "}
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={2} md={2}>
                  <h2> Clientes: </h2>
                </Grid>
                <Grid item xs={7} md={7}>
                  <InputSearch buscar={e => {
                    if (e.target.value.length >= 3 || e.target.value === '') {
                      handleNextPageCliente(clientePageCurrent, e.target.value)
                    }
                  }} />
                </Grid>
                <Grid item xs={3} md={3}>
                  <Stack
                    direction="column-reverse"
                    justifyContent="center"
                    alignItems="flex-end"
                    spacing={2}
                    mr={5}

                  >
                    <a
                      style={{
                        backgroundColor: "#2d572c",
                        color: "#fff",
                        border: "none",
                        padding: "5px 12px",
                        cursor: "pointer",
                        borderRadius: "6px",
                        right: 0,
                      }}
                      href={`${api.defaults.baseURL.slice(0, -4)}/clientes/export/excel`}
                    >
                      EXCEL
                    </a>
                  </Stack>
                </Grid>
              </Grid>
              <div className="container-table-clientes">

                <table className="tabela-cine-cliente">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Apellido</th>
                      <th>Email</th>
                      <th>Documento</th>
                      <th>Pedidos</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <></>
                    ) : (
                      users &&
                      users.map((c, k) => (
                        <tr>
                          <td>{c.id}</td>
                          <td>{c.nome}</td>
                          <td>{c.sobrenome}</td>
                          <td>{c.email}</td>
                          <td>{formatRut(c.documento)}</td>
                          <td>
                            {c.quantidade_vendas} - $
                            {c.valor_vendas ? c.valor_vendas.toLocaleString("pt-br", {
                              currency: "CLP",
                            }) : 0}
                          </td>
                          <td>
                            <button
                              style={{
                                backgroundColor: "#00c455",
                                color: "#fff",
                                border: "none",
                                padding: "5px 12px",
                                cursor: "pointer",
                                borderRadius: "6px",
                              }}
                              onClick={() => {
                                handleVisuCliente(c);
                              }}
                            >
                              Ver pedidos
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "3vh",
                }}
              >
                <Pagination
                  count={clientePage}
                  onChange={(event, value) => handleNextPageCliente(value - 1, searchText)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

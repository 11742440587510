import React from "react";
import { BiUserCircle } from "react-icons/bi";
import "./User.styles.css";
// components
import { ButtonLogout } from "../Forms/ButtonLogout";

export const User = () => {
  return (
    <div className="container-user-main">
      <div className="container-user-icon-user">
        <div className="info-data">
          <h4>{JSON.parse(localStorage.usuario).nome}</h4>
          <h5>
            {JSON.parse(localStorage.usuario).papel_id === 1 && (
              "Admin"
            )}
            {JSON.parse(localStorage.usuario).papel_id === 4 && (
              "Admin 2"
            )}
            {JSON.parse(localStorage.usuario).papel_id === 2 && (
              "Vendedor"
            )}
            {JSON.parse(localStorage.usuario).papel_id === 3 && (
              "Operador"
            )}
          </h5>
        </div>
        <BiUserCircle size={48} color="#ffff" className="icon-user" />
      </div>
      <ButtonLogout title="SALIR" />
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";
import { ImCheckmark } from "react-icons/im";
import { User } from "../../components/User";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
export const EventoImprimir = () => {
  const [filme, setFilme] = useState({});

  const { vid } = useParams();

  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      <div className="container-category-filme">
        <div className="container-pai">
          <div className="container-pai-filme">
            <div className="detalhes-filme">
              <div className="infos-filme-carteira">
                <h2 className="title-sinopse">{filme.titulo}</h2>
                <p>2019 - Ação | Fantasia</p>
                <p>
                  {" "}
                  <AiFillStar className="iconStar" />
                  7.5 (85,741)
                </p>
                <h6>
                  We all have a superhero inside us, it just takes a bit of
                  magic to bring it out. In Billy Batson's case, by shouting out
                  one word - SHAZAM. - this streetwise fourteen-year-old foster
                  kid can turn into the grown-up superhero Shazam
                </h6>
              </div>
            </div>
            <div className="informacoes-detalhes">
              <div className="detalhes-filme-direita">
                <div className="container-main-sesion">
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>01</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>02</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>03</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>04</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="main-bolinha1">
                      <span>05</span>
                    </div>
                  </div>
                </div>
                <div className="description-bolinha">
                  <p>Selección</p>
                  <p>Butacas</p>
                  <p>Pago</p>
                  <p>Ticket</p>
                  <p>Finalizado</p>
                </div>
              </div>

              <div className="container-finaly-main">
                <div className="container-finaly">
                  <ImCheckmark size={28} color="#FFFF" />
                </div>
              </div>
              <div className="container-title-finnaly">
                <h1 className="title-finnaly">Compra completada con éxito</h1>
                <div className="tickets-list">
                  <a
                    href={api.defaults.baseURL.slice(0, -4) + "/ingresso_evento/" + vid}
                    target="_blank"
                  >
                    Imprimir tickets
                  </a>
                </div>
                <div className="tickets-button">
                  <a href="/cartelera">Nueva venta</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { User } from "../../components/User";
import { useHistory, useParams } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";

import { api } from "../../services/api";

import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { alpha, styled } from "@mui/material/styles";
import moment from "moment";
import { Box } from "grommet";
import { PaymentEvent } from "../eventosPagar/EventPay";
import { EventoAssent } from "../eventosAssentos";
export const EventoBuy = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "auto",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  let { eventoId } = useParams();

  const [evento, setEvento] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [paying, setPaying] = React.useState(false);
  const [loadingTck, setLoadingTck] = React.useState(false);
  const [selectedInfo, setSelcted] = React.useState(null);
  const [selectSeat, setSelectSeat] = React.useState(false);

  const getEvento = async () => {
    await setLoading(true);
    api.get("/get/eventos/" + eventoId).then(({ data }) => {
      const unique = [...new Set(data.servicos.map((item) => item.data))];
      let dt = data;
      dt.ListDays = unique;
      setEvento(dt);
      setLoading(false);
    });
  };

  const handleSelectedItem = (item) => {
    setSelcted({ ...selectedInfo, date: item, servico: null });
  };

  const handleSelectedService = (item) => {
    let dt = item;
    dt.n_ticket = [];
    item.precos.forEach((element, index) => {
      dt.n_ticket[index] = 0;
    });

    setSelcted({
      ...selectedInfo,
      servico: dt,
      assentos: [],
    });
  };

  const handleNumbTicket = async (key, add) => {
    await setLoadingTck(true);
    let serv = selectedInfo;
    if (!serv.servico.total) {
      serv.servico.total = 0;
    }

    if (add) {
      serv.servico.n_ticket[key]++;
      serv.servico.total +=
        serv.servico.precos[key].valor * serv.servico.n_ticket[key] -
        serv.servico.precos[key].valor * (serv.servico.n_ticket[key] - 1);
    } else if (serv.servico.n_ticket[key] - 1 >= 0) {
      serv.servico.n_ticket[key]--;
      serv.servico.total +=
        serv.servico.precos[key].valor * serv.servico.n_ticket[key] -
        serv.servico.precos[key].valor * (serv.servico.n_ticket[key] + 1);
    }

    setSelcted(serv);
    setLoadingTck(false);
  };
  useEffect(() => {
    getEvento();
  }, []);

  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      {loading === false && (
        <div className="container-category-filme">
          <div className="container-pai">
            <div className="container-pai-filme">
              <div className="detalhes-filme">
                <img
                  src={
                    api.defaults.baseURL.slice(0, -4) +
                    "/assets/eventos/" +
                    evento?.capa
                  }
                  alt=""
                  className="img-capa"
                />
                <div className="infos-filme-carteira">
                  <h2 className="title-sinopse">{evento?.titulo}</h2>
                  <p>2019 - Ação | Fantasia</p>
                  <p>
                    {" "}
                    <AiFillStar className="iconStar" />
                    7.5 (85,741)
                  </p>
                  <h6>
                    We all have a superhero inside us, it just takes a bit of
                    magic to bring it out. In Billy Batson's case, by shouting
                    out one word - SHAZAM. - this streetwise fourteen-year-old
                    foster kid can turn into the grown-up superhero Shazam
                  </h6>
                </div>
              </div>
              <div className="informacoes-detalhes">
                <div className="detalhes-filme-direita">
                  <div className="container-main-sesion">
                    <div className="container-sesion">
                      <div className="main-bolinha1">
                        <span>01</span>
                      </div>
                      <hr className="container-line" />
                    </div>
                    <div className="container-sesion">
                      <div className="bolinha1">
                        <span>02</span>
                      </div>
                      <hr className="container-line" />
                    </div>
                    <div className="container-sesion">
                      <div className="bolinha1">
                        <span>03</span>
                      </div>
                      <hr className="container-line" />
                    </div>
                    <div className="container-sesion">
                      <div className="bolinha1">
                        <span>04</span>
                      </div>
                      <hr className="container-line" />
                    </div>
                    <div className="container-sesion">
                      <div className="bolinha1">
                        <span>05</span>
                      </div>
                    </div>
                  </div>
                  <div className="description-bolinha">
                    <p>Selección</p>
                    <p>Entrada</p>
                    <p>Pago</p>
                    <p>Ticket</p>
                    <p>Finalizado</p>
                  </div>
                </div>
                <div className="calendario-direitaaa">
                  {selectSeat === false ? (
                    <div className="conainer-selecione-date">
                      <h6>SELECCIONE FECHA</h6>
                      <div className="container-DATAS">
                        {evento?.ListDays.map((item) => {
                          return (
                            <button
                              className={
                                selectedInfo?.date === item
                                  ? "content-selecione-date-date-Dom-active"
                                  : "content-selecione-date-date-Dom"
                              }
                              onClick={() => handleSelectedItem(item)}
                            >
                              <div className="main-content-date-date-Dom">
                                <p
                                  className={
                                    selectedInfo?.date === item
                                      ? "first-p-active"
                                      : "first-p"
                                  }
                                >
                                  {moment(item).locale("es").format("ddd")}
                                </p>
                                <p
                                  className={
                                    selectedInfo?.date === item
                                      ? "last-p-active"
                                      : "last-p"
                                  }
                                >
                                  {moment(item).locale("es").format("DD")}
                                </p>
                                <p
                                  className={
                                    selectedInfo?.date === item
                                      ? "tri-p-active"
                                      : "tri-p"
                                  }
                                >
                                  {/* {item.nome_mes} */}
                                  {moment(item).locale("es").format("MMM")}
                                </p>
                              </div>
                            </button>
                          );
                        })}

                        {selectedInfo !== null && (
                          <>
                            <div className="container-seccion-carteira">
                              <p>HORA / TIPO</p>

                              <div className="container-main-buuton">
                                {/** Final Button Seccicion 01*/}
                                {evento.servicos
                                  .filter((i) => i.data === selectedInfo.date)
                                  .map((item) => {
                                    return (
                                      <button
                                        className={
                                          selectedInfo.servico?.id === item.id
                                            ? "container-secction-button-selected"
                                            : "container-secction-button"
                                        }
                                        onClick={() => {
                                          handleSelectedService(item);
                                        }}
                                      >
                                        <p
                                          className={
                                            selectedInfo.servico?.id === item.id
                                              ? "button-first-txt-selected"
                                              : "button-first-txt"
                                          }
                                        >
                                          {item.sala}
                                        </p>
                                        <p
                                          className={
                                            selectedInfo.servico?.id === item.id
                                              ? "button-last-txt-selected"
                                              : "button-last-txt"
                                          }
                                        >
                                          {item.hora}
                                        </p>
                                        <p
                                          className={
                                            selectedInfo.servico?.id === item.id
                                              ? "button-tri-txt-selected"
                                              : "button-tri-txt"
                                          }
                                        >
                                          {item.qualidade}
                                        </p>
                                        <p
                                          className={
                                            selectedInfo.servico?.id === item.id
                                              ? "button-tri-txt-selected"
                                              : "button-tri-txt"
                                          }
                                        >
                                          {item.sala.nome}
                                        </p>
                                      </button>
                                    );
                                  })}
                              </div>
                            </div>

                            {selectedInfo?.servico !== null && (
                              <>
                                <h6 className="title-cantidad">CANTIDAD</h6>

                                <Box gap="small">
                                  {selectedInfo.servico.precos.map(
                                    (item, index) => {
                                      return (
                                        <div className="container-main-igresso-column01">
                                          <ButtonGroup
                                            sx={{ backgroundColor: "white" }}
                                          >
                                            <Button
                                              onClick={() =>
                                                handleNumbTicket(index, false)
                                              }
                                            >
                                              -
                                            </Button>
                                            <Button disable>
                                              {loadingTck ? (
                                                <></>
                                              ) : (
                                                selectedInfo.servico.n_ticket[
                                                  index
                                                ]
                                              )}
                                            </Button>
                                            <Button
                                              onClick={() =>
                                                handleNumbTicket(index, true)
                                              }
                                            >
                                              +
                                            </Button>
                                          </ButtonGroup>
                                          <p className="description-igresso">
                                            {item.tipo} -{" "}
                                            {"$" +
                                              (
                                                item.valor *
                                                selectedInfo.servico.n_ticket[
                                                  index
                                                ]
                                              ).toLocaleString("pt-br", {
                                                currency: "CLP",
                                              })}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}
                                </Box>
                                <h4
                                  style={{ marginTop: 20 }}
                                  className="description-igresso"
                                >
                                  {selectedInfo.servico.n_ticket.reduce(
                                    (previousValue, currentValue) =>
                                      previousValue + currentValue
                                  )}
                                  x Butaca Total: ${" "}
                                  {selectedInfo.servico.total?.toLocaleString(
                                    "pt-br",
                                    { currency: "CLP" }
                                  )}
                                </h4>
                                <br />

                                <hr
                                  style={{ width: "75%", marginBottom: "2%" }}
                                />

                                <h4> INGRESA AQUÍ TU CÓDIGO PROMOCIONAL </h4>
                                <p style={{ fontSize: "0.9rem" }}>
                                  {" "}
                                  Aprovecha tu descuento y ven a disfrutar de
                                  CINESOL !{" "}
                                </p>

                                <br />
                                <BootstrapInput
                                  id="bootstrap-input-1"
                                  autoFocus={true}
                                />

                                <BootstrapButton
                                  variant="contained"
                                >
                                  Agregar
                                </BootstrapButton>

                                <hr style={{ width: "75%", marginTop: "2%" }} />
                                <div
                                  style={{
                                    marginLeft: "25%",
                                    marginBottom: 50,
                                  }}
                                >
                                  <button
                                    onClick={() => {
                                      if (
                                        selectedInfo.servico.tipo === "asiento"
                                      )
                                        setSelectSeat(true);
                                      else {
                                        setLoading(true);
                                        setPaying(true);
                                      }
                                    }}
                                    className="button-continue"
                                  >
                                    <span>Continuar</span>
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <EventoAssent
                      assentos={selectedInfo.servico.assentos}
                      setSelected={setSelcted}
                      selectedInfo={selectedInfo}
                      nextStep={() => {
                        setLoading(true);
                        setPaying(true);
                      }}
                      prevStep={() => {
                        setSelectSeat(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {paying && <PaymentEvent evento={evento} selectedInfo={selectedInfo} />}
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import "./ModalAgregarHr.styles.css";
import { api } from "../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("es", es);

export const ModalAgregarHr = ({ filmeid }) => {
  let params = new URLSearchParams(window.location.search);

  const [isModalVisible, setisModalVisible] = useState(true);
  const _refDatePicker = useRef(null);

  function handleModalVisible() {
    window.location.reload();
  }

  const [salas, setSalas] = useState([]);
  const [horarioData, setHorarioData] = useState({});
  const [salvando, setSalvando] = useState(false);
  const [arrayDeData, setArrayDatas] = useState([]);
  const [arrayDeHoras, setArrayDeHoras] = useState([]);

  async function getSalas() {
    let token = localStorage.token;
    const response = await api.get("/v1/sala/pesquisar", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setSalas(response.data.data);
  }

  const handleHorarioData = (e) => {
    e.preventDefault();
    setHorarioData({ ...horarioData, [e.target.name]: e.target.value });
  };

  const salvarHorario = (event) => {
    event.preventDefault();

    setSalvando(true);

    let token = localStorage.token;

    let data = {
      ...horarioData,
      horas: JSON.stringify(arrayDeHoras),
      datas: JSON.stringify(arrayDeData),
    };

    api
      .post("/v1/filme/salvar-horario", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch(function () {
        window.location.reload();
      });
  };

  useEffect(() => {
    setisModalVisible(isModalVisible);
    setHorarioData({ ...horarioData, filme_id: params.get("id") });

    getSalas();
  }, []);

  useEffect(() => {
  }, [salas]);

  const selecionarDatePicker = () => {
    if (_refDatePicker !== null) {
      _refDatePicker.current.handleFocus();
    }
  };

  const formatData = (data) => {
    if (data instanceof Date) {
      let dataObj = {
        _day: data.getDate(),
        _mes: data.getMonth() + 1,
        _ano: data.getFullYear(),
      };

      return `${dataObj._day < 10 ? "0" + dataObj._day : dataObj._day}/${
        dataObj._mes < 10 ? "0" + dataObj._mes : dataObj._mes
      }/${dataObj._ano}`;
    }

    return undefined;
  };

  const getDateType = (format) => {
    if (typeof format === "undefined") return "";
    return format;
  };

  const updateHandlerData = (data) => {
    setArrayDatas([...arrayDeData, getDateType(formatData(data))]);
  };

  const removeElements = (index) => {
    let arr = [];
    arrayDeData.forEach((data, i) => {
      if (index !== i) arr.push(data);
    });
    setArrayDatas(arr);
  };

  const removeHora = (index) => {
    let arr = [];
    arrayDeHoras.forEach((data, i) => {
      if (index !== i) arr.push(data);
    });
    setArrayDeHoras(arr);
  };

  const updateHandleHora = (time) => {
    if (arrayDeData.length) {
      setArrayDeHoras([...arrayDeHoras, time.target.value]);
    }
  };

  return (
    <>
      {isModalVisible && (
        <div className="container-area-modal-agregrar-hr">
          <button
            className="button-closed-agregrar-hr"
            onClick={handleModalVisible}
          >
            X
          </button>
          <form
            className="modal-container-agregrar-hr"
            onSubmit={salvarHorario}
          >
            <div className="title-modal-agregrar-hr">
              <h1>Agregar Horario</h1>
            </div>
            <div className="cont-agr-hr">
              <div className="container-main-body">
                <div className="container-main-body-column-n">
                  {/* FINAL PRIMEIRO INPUT*/}
                  <div className="container-main-input-n">
                    <p>Sala</p>
                    <select
                      name="sala_id"
                      required
                      onChange={handleHorarioData}
                    >
                      <option value="">Seleccione</option>
                      {salas.map((item) => (
                        <option value={item.id}>{item.nome}</option>
                      ))}
                    </select>
                  </div>

                  <div className="container-main-input-n">
                    <p>Audio / Subtítulo</p>
                    <select
                      type="text"
                      name="audio"
                      required
                      onChange={handleHorarioData}
                    >
                      <option value="">Seleccione</option>
                      <option value="dub">Doblado</option>
                      <option value="leg">Subtitulado</option>
                    </select>
                  </div>
                </div>

                {/* FINAL SEGUNDO INPUT*/}
                <div
                  className="container-main-body-column-n"
                  style={{ height: "100px" }}
                >
                  <div className="container-main-input-n">
                    <p className="label-texto">Fecha</p>
                    <input
                      type="text"
                      name="datas"
                      onClick={selecionarDatePicker}
                      value={horarioData.datas}
                      autoComplete="off"
                    />
                    <DatePicker
                      locale="es"
                      className="input-invisivel"
                      ref={_refDatePicker}
                      selected={null}
                      onChange={updateHandlerData}
                    />
                  </div>
                  <div className="container-main-input-n">
                    <p className="label-texto">Hora</p>
                    <input
                      type="time"
                      name="hora"
                      onChange={updateHandleHora}
                    />
                  </div>
                </div>
                {arrayDeData.length || arrayDeHoras.length ? (
                  <div className="hora-flex">
                    <div>
                      {arrayDeData.length ? (
                        <span class="title-item">Fechas: </span>
                      ) : (
                        false
                      )}
                      {arrayDeData.length
                        ? arrayDeData.map((data, index) => (
                            <div key={index}>
                              <span class="item-text">{data}</span>
                              <span
                                className="button-remove"
                                onClick={() => removeElements(index)}
                              >
                                X
                              </span>
                              <br />
                            </div>
                          ))
                        : false}
                    </div>
                    <div>
                      {arrayDeHoras.length ? (
                        <span class="title-item">Horas: </span>
                      ) : (
                        false
                      )}
                      {arrayDeHoras.length
                        ? arrayDeHoras.map((data, index) => (
                            <div key={index}>
                              <span class="item-text">{data}</span>
                              <span
                                className="button-remove"
                                onClick={() => removeHora(index)}
                              >
                                X
                              </span>
                              <br />
                            </div>
                          ))
                        : false}
                    </div>
                  </div>
                ) : (
                  false
                )}

                {/* FINAL TERCEIRO  INPUT*/}
                <div className="container-main-body-column-n">
                  <div className="container-main-input-n">
                    <p>Calidad</p>
                    <select
                      type="text"
                      name="qualidade"
                      required
                      onChange={handleHorarioData}
                    >
                      <option value="">Seleccione</option>
                      <option value="3D">3D</option>
                      <option value="2D">2D</option>
                      <option value="XD">XD</option>
                      <option value="Premium">Premium</option>
                    </select>
                  </div>
                  <div className="container-main-input-n">
                    <p>Precio</p>
                    <input
                      type="number"
                      step="0.1"
                      name="valor"
                      required
                      onChange={handleHorarioData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <button className="footer-button-add-horario">
                <span>{salvando ? "Ingresando" : "Ingresar"}</span>
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

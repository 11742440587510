import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { Box, TextInput, Text } from 'grommet';
import { Divider, Checkbox, DatePicker, ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel';
import { Select, Space } from 'antd';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { api } from '../../services/api';
import 'moment/locale/es';
import locale from 'antd/es/locale/es_ES';


const { Option } = Select;

const options = [];
for (let i = 10; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
}

export const CreateCupon = ({ changeScreen, dataC }) => {

    const [dataset, setDataset] = useState({ tipo: "porcentagem" });
    const [data_canje, setData_canje] = React.useState([]);

    const { RangePicker } = DatePicker;

    const handleDataset = (e) => {
        e.preventDefault();
        setDataset({ ...dataset, [e.target.name]: e.target.value })
    }

    const salvarCupom = event => {
        event.preventDefault();

        let token = localStorage.token;

        let data = dataset;

        data.data_inicio = moment(data_canje[0]).format("YYYY-MM-DD");
        data.data_fim = moment(data_canje[1]).format("YYYY-MM-DD");

        api.post('/v2/promocao/criar', data, {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            changeScreen()
        }).catch(function (error) {
            changeScreen()
            console.error(error)
        })
    }

    useEffect(() => {
        if (dataC) {
            let data = {
                tipo: dataC.tipo,
                nome: dataC.nome,
                porcentagem: dataC.porcentagem,
                quantidade: dataC.quantidade,
                data_inicio: dataC.data_inicio,
                data_fim: dataC.data_fim,
                filmes: dataC.filmes_ids,
                seg: dataC.seg,
                ter: dataC.ter,
                qua: dataC.qua,
                qui: dataC.qui,
                sex: dataC.sex,
                sab: dataC.sab,
                dom: dataC.dom,
                id: dataC.id
            }
            setData_canje([moment(dataC.data_inicio), moment(dataC.data_fim)])
            setDataset(data);
            setFilmesSelected(dataC.filmes_ids)
        }
    }, [dataC]);

    const onChangeDate = (event) => {
        let promoUpdate = dataset;

        promoUpdate.data_inicio = moment(event[0]).format("YYYY-MM-DD");
        promoUpdate.data_fim = moment(event[1]).format("YYYY-MM-DD");

        setDataset(promoUpdate);
    };

    const [filmes, setFilmes] = React.useState([]);
    const [filmesSelected, setFilmesSelected] = React.useState([]);

    React.useEffect(() => {
        let token = localStorage.token;
        api.get('v1/filme/pesquisar', {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((response) => { setFilmes(response.data.data.filme) })
    }, [])

    const onChangeFilmes = (event) => {
        setDataset({ ...dataset, filmes: event })
        setFilmesSelected(event)
    }

    return (
        <Box
            border={[
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "top"
                },
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "right"
                },
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "left"
                },
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "bottom"
                },
            ]}
            pad="medium"
            width="100%"
            height="100%"
            background={'white'}
        >
            <Box gridArea="nav">
                <Button sx={{
                    backgroundColor: 'transparent', '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'rgb(0 0 0 / 85%)',
                    }, color: 'rgb(0 0 0 / 85%)',
                    width: '10%',
                    height: 'auto',
                    justifyContent: 'center',
                    alignItem: 'center',
                    display: 'flex',
                    fontFamily: 'Poppins',

                }}
                    onClick={() => changeScreen()}
                >
                    <ArrowBackIcon /> Volver
                </Button>
                <Divider />
                <Box direction="column" width={'50%'}  >
                    <h1> Crear cupón</h1>

                    <p> Nombre de la promoción* </p>
                    <TextInput placeholder="Ex: BANCO DEL CHILE" name="nome" value={dataset.nome}
                        onChange={handleDataset}
                    />

                    <p style={{ marginTop: "2%" }}> *Código único (Digitar un código sin espacios) </p>
                    <TextInput placeholder="Ex: BLACKFRIDAY20" name="code_unique" value={dataset.code_unique}
                        onChange={handleDataset}
                    />
                </Box>

                <FormControl style={{ marginTop: '1%' }}>
                    <FormLabel style={{ fontFamily: 'Poppins' }}>Tipo de descuento</FormLabel>
                    <RadioGroup
                        row
                    >
                        <FormControlLabel value="porcentagem" checked={dataset.tipo === 'porcentagem'} name="tipo" onChange={handleDataset} control={<Radio />} label="Porcentaje (%)" />
                        <FormControlLabel value="valor" checked={dataset.tipo === 'valor'} name="tipo" onChange={handleDataset} control={<Radio />} label="Valor Fijo" />
                        <FormControlLabel value="quantidade" checked={dataset.tipo === 'quantidade'} name="tipo" onChange={handleDataset} control={<Radio />} label="Cantidad" />
                    </RadioGroup>
                </FormControl>

                <Box direction="row" gap="medium" style={{ marginTop: "1%" }}>
                    {dataset.tipo === 'porcentagem' ?
                        <Box direction="column" width={'30%'}  >
                            <p> Porcentaje del descuento (%)*</p>
                            <TextInput placeholder="%" name="porcentagem" value={dataset.porcentagem} onChange={handleDataset}
                            />

                        </Box>
                        : dataset.tipo === 'valor'
                            ?
                            <Box direction="column" width={'30%'}  >
                                <p> Valor del descuento ($)*</p>
                                <TextInput placeholder="$" name="valor" value={dataset.valor} onChange={handleDataset}
                                />

                            </Box>
                            : <Box direction="column" width={'30%'}>
                                <p> Cantidad de entradas a descontar por cada cupón</p>
                                <TextInput placeholder="cantidad" name="tickets_afetados" value={dataset.tickets_afetados} onChange={handleDataset}
                                />
                            </Box>
                    }


                    <Box direction="column" width={'50%'}>
                        <p> Cantidad total de cupones</p>
                        <TextInput placeholder="cantidad" name="quantidade" value={dataset.quantidade} onChange={handleDataset}
                        />

                    </Box>



                </Box>

                <h3 style={{ marginTop: "1%", marginBottom: "1%" }}> DISPONIBILIDAD </h3>
                <Box direction="row" gap="35%"  >
                    <Box direction="column" width={'30%'}  >
                        <p> Disponible entre:* </p>
                        <ConfigProvider locale={locale}>
                            <RangePicker style={{ witdh: '50%' }} value={data_canje} onChange={e => setData_canje(e)}/>
                        </ConfigProvider>
                    </Box>
                </Box>

                <Space
                    style={{
                        width: '30%',
                        marginTop: "1%"
                    }}
                    direction="vertical"
                >
                    <p> Peliculas permitidas:* </p>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Seleccine las peliculas vigentes"
                        value={filmesSelected}
                        optionLabelProp="label"
                        onChange={(opt) => onChangeFilmes(opt)}
                    >
                        <Option value={0} label="TODAS">
                            <Space>TODAS</Space>
                        </Option>
                        {filmes.map((filme) => {
                            return (<Option value={filme.id} label={filme.titulo}>
                                <Space>
                                    {filme.titulo}
                                </Space>
                            </Option>)
                        })}

                    </Select>
                </Space>


                <Box direction="row" gap="35%" style={{ marginTop: '2%' }}  >

                    <Box direction="column" width={'20%'}   >
                        <p> El cupón de días está disponible:* </p>
                        <Box direction="row"   >
                            <Checkbox checked={dataset.seg} onChange={(e) => setDataset({ ...dataset, seg: e.target.checked })} >Lun</Checkbox>
                            <Checkbox checked={dataset.ter} onChange={(e) => setDataset({ ...dataset, ter: e.target.checked })} >Mar</Checkbox>
                            <Checkbox checked={dataset.qua} onChange={(e) => setDataset({ ...dataset, qua: e.target.checked })} >Mie</Checkbox>
                            <Checkbox checked={dataset.qui} onChange={(e) => setDataset({ ...dataset, qui: e.target.checked })} >Jue</Checkbox>
                            <Checkbox checked={dataset.sex} onChange={(e) => setDataset({ ...dataset, sex: e.target.checked })} >Vie</Checkbox>
                            <Checkbox checked={dataset.sab} onChange={(e) => setDataset({ ...dataset, sab: e.target.checked })} >Sáb</Checkbox>
                            <Checkbox checked={dataset.dom} onChange={(e) => setDataset({ ...dataset, dom: e.target.checked })} >Dom</Checkbox>
                        </Box>
                    </Box>

                </Box>
                <Button sx={{
                    backgroundColor: '#2b00ff', '&:hover': {
                        backgroundColor: '#2b00ff',
                        color: '#white',
                    }, color: 'white',
                    width: '10%',
                    height: 'auto',
                    justifyContent: 'center',
                    alignItem: 'center',
                    display: 'flex',
                    fontFamily: 'Poppins',
                    float: 'right',
                    marginLeft: '80%',
                    marginTop: '2%'
                }}
                    onClick={(e) => salvarCupom(e)}
                >
                    {dataset.id ? 'Guardar' : 'Crear Nuevo'}
                </Button>
            </Box>
        </Box>
    );
};
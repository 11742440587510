import React from 'react';
import { useHistory } from 'react-router-dom';
import './ButtonLogout.styles.css';


export const ButtonLogout= ({ title }) => {
  const history = useHistory();

  const  handleNavigation=()=> {
      localStorage.clear();
      history.push("/");
    }
    return(
        <div className="container-input">
          <button className="container-button-logout" onClick={handleNavigation}>
            {title}
          </button>
        </div>
    )
};
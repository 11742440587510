import { Box } from "grommet";
import React, { useEffect } from "react";
import userIcon from "../../assets/user-icon.png";

export const EventoAssent = ({
  assentos,
  setSelected,
  selectedInfo,
  nextStep,
  prevStep,
}) => {
  const [blocos, setBlocos] = React.useState([]);
  const [loading, setLoad] = React.useState(false);

  const handleAssentos = () => {
    let blc = [];

    assentos.forEach((assento) => {
      if (blc[assento.bloco] === undefined) blc[assento.bloco] = [];
      if (blc[assento.bloco][assento.linha] === undefined)
        blc[assento.bloco][assento.linha] = [];
      blc[assento.bloco][assento.linha].push(assento);
    });

    setBlocos(blc);
  };

  useEffect(() => {
    if (assentos) handleAssentos();
  }, [assentos]);

  const handleSelectAssento = async (i1, i2, i3) => {
    let bloco = blocos[i1][i2][i3];

    let slct = selectedInfo.assentos;

    if (bloco.ocupado !== 1 && bloco.ocupado !== -1) {
      if (bloco.ocupado === 2) {
        bloco.ocupado = 0;
        slct = slct.filter((item) => item.id !== bloco.id);
      } else {
        if (
          slct.length + 1 <=
          selectedInfo.servico.n_ticket.reduce(
            (previousValue, currentValue) => previousValue + currentValue
          )
        ) {
          bloco.ocupado = 2;
          slct.push(bloco);
        }
      }

      setSelected({
        ...selectedInfo,
        assentos: slct,
      });
    }

    let newBloco = blocos;
    newBloco[i1][i2][i3] = bloco;

    setBlocos(newBloco);
    setLoad(false);
  };
  return (
    <div className="content-payment">
      <div className="infos-compra">
        <h4 className="title-selecione-header">
          Selección de asientos: {selectedInfo.assentos.length} de{" "}
          {selectedInfo.servico.n_ticket.reduce(
            (previousValue, currentValue) => previousValue + currentValue
          )}{" "}
          (Sala 2)
        </h4>
        <h4 className="title-selecione-header">
          {assentos.filter((item) => item.ocupado === 1).length} de{" "}
          {assentos.filter((item) => item.ocupado !== -1).length}
          <span> plazas ocupadas</span> ({" "}
          {assentos.filter((item) => item.ocupado === 1).length > 0
            ? Math.round(
                assentos.filter((item) => item.ocupado !== -1).length /
                  assentos.filter((item) => item.ocupado === 1).length
              )
            : "0"}
          %)
        </h4>
      </div>
      <p className="subtitle-header">Pantalla</p>
      <hr className="container-line-butacas" />
      {!loading && (
        <div className="sala">
          {blocos.map((bloco, i1) => (
            <div className="bloco">
              <div className="linha">
                {bloco.map((linhas, i2) => (
                  <Box direction="row">
                    {linhas.map((linha, i3) => (
                      <button
                        className={`assento 
                      ${assentos.length < 200 && "x15"} 
                      ${linha.ocupado === 2 && "selecionado"}`}
                        style={{
                          backgroundColor:
                            linha.ocupado === -1 ? "transparent" : "",
                          color: linha.ocupado === -1 ? "transparent" : "",
                          cursor: linha.ocupado === -1 ? "default" : "",
                        }}
                        onClick={() => {
                          handleSelectAssento(i1, i2, i3);
                        }}
                      >
                        {linha.ocupado !== 1 ? (
                          linha.nome
                        ) : (
                          <img src={userIcon} alt="use" />
                        )}
                      </button>
                    ))}
                  </Box>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex-column">
        <div className="display-flex">
          <div className={"assento ocupado "}>
            {" "}
            <img src={userIcon} alt="use" />
          </div>
          <p style={{ margin: 0 }}>Ocupado</p>
        </div>
        <div className="display-flex">
          <div className={"assento selecionado "}></div>
          <p style={{ margin: 0 }}>Seleccionado</p>
        </div>
      </div>

      <div className="text-right" style={{ marginBottom: 50 }}>
        <a onClick={prevStep} className="button-cancel">
          <span>Volver</span>
        </a>

        <button
          className="button-continue"
          onClick={() => {
            if (
              selectedInfo.assentos.length ===
              selectedInfo.servico.n_ticket.reduce(
                (previousValue, currentValue) => previousValue + currentValue
              )
            )
              nextStep();
            else alert("Seleccione los asientos");
          }}
        >
          <span>Continuar</span>
        </button>
      </div>
    </div>
  );
};

import React from 'react';
import './Button.styles.css';


export const ButtonSignIn = ({ title }) => {

  const  handleNavigation=()=> {
      // history.push("/cartelera");
    }
    return(
        <div className="container-input">
          <button className="container-button-signin" onClick={handleNavigation}>
            {title}
          </button>
        </div>
    )
};
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ModalIngressarPelicula.styles.css";
import { api } from "../../services/api";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
export const ModalIngressarPelicula = () => {
  const history = useHistory();
  const [isModalVisible, setisModalVisible] = useState(true);
  const [filmeData, setFilmeData] = useState({
    estreia: false,
  });
  const [uploading, setUploading] = useState(false);

  const handleFilmeData = (e) => {
    e.preventDefault();
    setFilmeData({ ...filmeData, [e.target.name]: e.target.value });
  };

  const formData = new FormData();

  const handleFile = (e) => {
    e.preventDefault();

    setFilmeData({ ...filmeData, file: e.target.files[0] });
  };

  function handleModalVisible() {
    window.location.reload();
    history.push("/cartelera");
  }

  const salvarFilme = (event) => {
    event.preventDefault();

    setUploading(true);

    formData.append("titulo", filmeData.titulo);
    formData.append("diretor", filmeData.diretor);
    formData.append("duracao_minutos", filmeData.duracao_minutos);
    formData.append("data_lancamento", "2007-06-05");
    formData.append("classificacao", filmeData.classificacao);
    formData.append("trailer", filmeData.trailer);
    formData.append("distribuidor", filmeData.distribuidor);
    formData.append("file", filmeData.file, filmeData.file.name);
    if (filmeData.estreia === true) formData.append("estreia", 1);
    else formData.append("estreia", 0);

    let token = localStorage.token;

    api
      .post("/v1/filme/salvar", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data;",
          Accept: "*/*",
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch(function (response) {
        window.location.reload();
      });
  };

  useEffect(() => { }, []);
  return (

    <div className="container-area-modal-Ingressar-pelicula">
      <button
        className="button-closed-modal-Ingressar-pelicula"
        onClick={handleModalVisible}
      >
        X
      </button>
      <form
        className="modal-container-modal-Ingressar-pelicula"
        onSubmit={salvarFilme}
      >
        <div className="title--modal-Ingressar-pelicula">
          <h1 style={{ color: "#fff" }}>Ingresar Película</h1>
        </div>

        <div className="body-modal-Ingressar-pelicula01">
          <div className="container-input-ingressar-pelicula01">
            <p>Nombre de la película</p>
            <input
              type="text"
              required
              name="titulo"
              onChange={handleFilmeData}
            />
          </div>

          <div className="container-input-ingressar-pelicula01">
            <p>Director</p>
            <input
              type="text"
              required
              name="diretor"
              onChange={handleFilmeData}
            />
          </div>
        </div>
        <div className="body-modal-Ingressar-pelicula01">
          <div className="container-input-ingressar-pelicula01">
            <p>Tiempo (en minutos)</p>
            <input
              type="number"
              name="duracao_minutos"
              required
              onChange={handleFilmeData}
            />
          </div>

          <div className="container-input-ingressar-pelicula01">
            <p>Clasificación película</p>
            <select
              className="slc-chil"
              name="classificacao"
              required
              onChange={handleFilmeData}
            >
              <option value="">Seleccione</option>
              <option value="te">TE</option>
              <option value="te7">TE+7</option>
              <option value="14">14</option>
              <option value="18">18</option>
            </select>
          </div>
        </div>

        <div className="body-modal-Ingressar-pelicula01">
          <div className="container-input-ingressar-pelicula01">
            <p>Trailer</p>
            <input
              type="text"
              name="trailer"
              required
              onChange={handleFilmeData}
            />
          </div>

          <div className="container-input-ingressar-pelicula01">
            <p>Distribuidor</p>
            <input
              type="text"
              name="distribuidor"
              required
              onChange={handleFilmeData}
            />
          </div>
        </div>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  setFilmeData({ ...filmeData, estreia: e.target.checked })
                }
              />
            }
            label="Estreno"
          />
        </FormGroup>

        <div className="body-modal-Ingressar-pelicula01">
          <input type="file" required onChange={handleFile} />
        </div>

        <div className="footer-pelicula">
          {uploading ? (
            <button className="footer-button-add-pelicula" disabled>
              <span>Adicionando</span>
            </button>
          ) : (
            <button className="footer-button-add-pelicula">
              <span>Guardar</span>
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

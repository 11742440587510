import React from 'react';

import { Box, Button, DataTable, Heading, Text } from 'grommet';
import Pagination from '@mui/material/Pagination';
import { api } from '../../services/api';
import { Checkbox, Button as FormButton } from 'antd';
import {AiFillFileExcel} from 'react-icons/ai'

function TableCupom({ id, modal, name, cupom, forSale }) {

    const columns = [
        {
            property: 'codigo',
            header: 'Código',
            search: true,
            primary: true,
        },
        {
            property: 'tipo',
            search: true,
            header: 'Tipo',
            render: (datum) => datum.tipo === 'porcentagem' ? 'Porcentaje' : datum.tipo === 'quantidade' ? 'Cantidad' : 'Valor',
        },
        {
            property: 'porcentagem',
            search: true,
            header: 'Valor'
        },
        {
            property: 'usado',
            search: true,
            header: 'DISPONIBLE',
            render: (datum) => datum.usado === 1 ? "USADO" : "DISPONIBLE",
            align: 'end',
            aggregate: 'sum',
        },
        {
            property: 'created_at',
            search: true,
            header: 'CREADO',
            render: (datum) => <Text style={{ whiteSpace: 'nowrap' }}>{datum.created_at}</Text>,
            align: 'center',
            aggregate: 'sum',
        },
        {
            property: 'id',
            header: 'CREADO',
            render: (datum) => <Button label={<Text color="white">Eliminar</Text>} onClick={() => handleDeleteButton(datum.id)} style={{ backgroundColor: "red" }} color="red" />,
            align: 'center',
            aggregate: 'sum',
        },
    ];

    const columnsForSale = [
        {
            property: 'codigo',
            search: true,
            size: "small",
            header: 'Código',
        },
        {
            property: 'cantidad',
            search: true,
            size: "small",
            header: 'Cantidad',
        },
        {
            property: 'saldo',
            search: true,
            size: "small",
            header: 'Saldo',
        },
        {
            property: 'created_at',
            search: true,
            size: "small",
            header: 'Fecha de compra',
        },
        {
            property: 'cliente',
            search: true,
            size: "small",
            header: 'Cliente'
        },
        {
            property: 'email',
            search: true,
            size: "small",
            header: 'Email'
        },
    ];

    const handleDeleteButton = async (id) => {

        let confirmA = window.confirm('¿Está seguro de que desea eliminar este cupón?');

        if (confirmA) {
            let token = localStorage.token;

            api.get(`v2/delete-cupom-unique/${id}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).finally(() => {
                setCupoms(cupoms.filter(cup => cup.id !== id))
                setCupomsAll(cupomsAll.filter(cup => cup.id !== id))
            })


        }
    };
    const [page, setPage] = React.useState(1);
    const [npage, setNPage] = React.useState(0);

    const [cupoms, setCupoms] = React.useState([]);
    const [cupomsAll, setCupomsAll] = React.useState([]);

    const [loaded, setLoaded] = React.useState(false);

    const load = () => {
        api.get(`/v2/cupoms/${id}/${page}/${forSale}`).then(({ data }) => {
            setCupoms(data.cupom);
            setCupomsAll(data.cupom);
            setNPage(data.n_pages);
            setLoaded(true);

        }).catch((error) => console.log('error'));
    };

    const handlePaginate = (pg) => {
        setLoaded(false);

        api.get(`/v2/cupoms/${id}/${pg}/${forSale}`).then(({ data }) => {
            setPage(pg)
            setCupoms(data.cupom);
            setCupomsAll(data.cupom);
            setLoaded(true);
        }).catch((error) => console.log('error'));

    };

    React.useEffect(() => {
        let token = localStorage.token;
        api.defaults.headers.Authorization = `Bearer ${token}`;

        if (id) {
            setPage(1);
            load();
        }
    }, [id])

    const buscar = (info) => {
        let filtros = [];
        for(let i in info)  {
            if (info[i] != '') filtros.push([i, info[i]]);
        }
        let data = cupomsAll;
        let text
        for (let f = 0; f < filtros.length; f++) {
            data = data.filter(e => {
                if (filtros[f][0] == 'tipo') {
                    text = e[filtros[f][0]] === 'porcentagem' ? 'Porcentaje' : 'Valor';
                } else if (filtros[f][0] == 'usado') {
                    text = e[filtros[f][0]] === 1 ? "USADO" : "DISPONIBLE";
                } else {
                    text = e[filtros[f][0]].toString()
                }
                return text.search(filtros[f][1]) !== -1
            });
        }
        setCupoms(data);
    }

    async function exportar() {
        let url
        if (forSale) {
            url = "https://api-cinesol.busticket.cl/export-ventas-cupon/"+id;
        } else {
            url = "https://api-cinesol.busticket.cl/export-promociones-cupon/"+id;
        }
        window.open(url, "_blank");
    }

    return (
        <Box align="center" pad="large" style={{ maxHeight: '90vh', overflowY: 'scroll' }} gap="small">
            <Heading level={3}>
                <Box gap="small">
                    <strong>Promoción {name}</strong>

                    {!forSale && (
                        <Box direction="column" width={'100%'}   >
                            <Text> El cupón de días está disponible:* </Text>
                            <Box direction="row"   >
                                <Checkbox checked={cupom.seg}>Lun</Checkbox>
                                <Checkbox checked={cupom.ter}>Mar</Checkbox>
                                <Checkbox checked={cupom.qua}>Mie</Checkbox>
                                <Checkbox checked={cupom.qui}>Jue</Checkbox>
                                <Checkbox checked={cupom.sex}>Vie</Checkbox>
                                <Checkbox checked={cupom.sab}>Sáb</Checkbox>
                                <Checkbox checked={cupom.dom}>Dom</Checkbox>
                            </Box>
                        </Box>
                    )}
                    <Text>
                        Deslizando hacia abajo podrá ver el detalle de cada código utilizado.
                    </Text>
                    <Box align="end">
                        <FormButton type="text" 
                            icon={<AiFillFileExcel style={{ color: 'green' }} size={30} />}
                            onClick={() => exportar()}
                            size={'medium'}
                        />
                    </Box>
                </Box>
            </Heading>
            {modal && loaded && <DataTable onSearch={e => buscar(e)}
                columns={forSale ? columnsForSale : columns}
                data={cupoms}
            />}


            <Pagination page={page} count={npage} variant="outlined" shape="rounded" onChange={(event, page) => handlePaginate(page)} />
        </Box>
    );
}

TableCupom.storyName = 'Infinite Scroll';

export default TableCupom;
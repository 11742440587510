import { DatePicker, Input } from "antd";
import { Box, Button, DataTable, Image, Text } from "grommet";
import React from "react";
import moment from "moment/moment";
import TicketIcon from "../../assets/ticket.svg";
import MoneyIcon from "../../assets/money.svg";
import EyeIcon from "../../assets/icon_eye.png";
import { api } from "../../services/api";
import "./Informations.styles.css";

const columns = [
  {
    property: "id",
    header: "Item",
  },
  {
    property: "vid",
    header: 'Ver copia Tickets',
    render: (datum) => (
      <Button
        style={{ alignSelf: 'center' }}
        onClick={() =>
          window.open(
            `${api.defaults.baseURL.slice(0, -4)}/ingresso/${datum.vid}`,
            "_blank"
          )
        }
      >
        <Image src={EyeIcon} height={"30px"} />
      </Button>
    ),
    primary: true,
  },
  {
    property: "fecha",
    header: "Fecha Función",
  },
  {
    property: "hora",
    header: "Hora Función",
  },
  {
    property: "transacao_numero",
    header: "Número transacción",
  },
  {
    property: "filme",
    header: "Película"
  },
  {
    property: "sala",
    header: "Sala",
  },
  {
    property: "documento",
    header: "Documento",
  },
  {
    property: "nome",
    header: "Nombre",
    render: (datum) => (
      <Text style={{ textTransform: "uppercase" }}>{datum.nome}</Text>
    ),
  },
  {
    property: "sobrenome",
    header: "Apellido",
    render: (datum) => (
      <Text style={{ textTransform: "uppercase" }}>
        {datum.sobrenome}
      </Text>
    ),
  },
  {
    property: "email",
    header: "Email",
    render: (datum) => (
      <Text style={{ textTransform: "uppercase" }}>{datum.email}</Text>
    ),
  },
];

export const ListarPedidosOnline = ({ onEsc }) => {
  const [pedidos, setPedidos] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [exportando, setExportando] = React.useState(false);
  const [day, setDay] = React.useState(moment().format("YYYY-MM-DD"));

  const handleUpdate = (data) => {
    api
      .post("/v2/get/pedidos", data)
      .then(({ data }) => {
        setPedidos(data);
      });
  };

  const onChange = (date, dateString) => {
    setDay(dateString);
    handleUpdate({
      date: dateString,
      search: search,
    });
  };

  React.useEffect(() => {
    let token = localStorage.token;
    api.defaults.headers.Authorization = `Bearer ${token}`;
    handleUpdate({
      date: day,
      search: search,
    });
  }, []);

  const handleSearch = (searchTxt) => {
    setSearch(searchTxt)
    handleUpdate({
      date: day,
      search: searchTxt,
    });
  }

  const exportarExcel = () => {
    setExportando(true);
    let url = "https://api-cinesol.busticket.cl/pedidos_online/export?date=" + day + "&search=" + search;
    window.open(url, "_blank");
    setExportando(false);
  }

  return (
    <Box
      width={"100%"}
      pad={"50px"}
      gap="small"
      style={{
        maxHeight: "80vw",
        overflowY: "scroll",
        display: "block",
        maxWidth: "98vw",
        paddingTop: '10px'
      }}
    >
      <Box direction="row" justify="between">
        <h4>Muestra las entradas vendidas online ordenadas por fecha de la función. Sirve para reimprimir un ticket de un cliente que no encuentra copia de su entrada. Puede buscar por fecha o rut</h4>
      </Box>
      <Box direction="row" gap="small" align="center">
        <Box gap="small">
          <Box direction="row" align="center" gap="small">
            <Text>Fecha Función:</Text>
            <DatePicker
              placeholder={moment().format("YYYY-MM-DD")}
              onChange={onChange}
            />
          </Box>
        </Box>

      </Box>

      <Box
        className="tickets-boxes"
        gap="small"
        direction="row"
        justify="start"
      >
        <div className="ticket-box blue">
          <div className="infos-left">
            <h2>{pedidos?.numero}</h2>
            <p>Tickets</p>
          </div>
          <div className="icon-box">
            <img src={TicketIcon} alt="" />
          </div>
        </div>

        <div className="ticket-box green">
          <div className="infos-left">
            <h2>
              {Intl.NumberFormat("es-CL", {
                currency: "CLP",
                style: "currency",
              }).format(pedidos?.vendas)}
            </h2>
            <p>Ventas</p>
          </div>
          <div className="icon-box">
            <img src={MoneyIcon} alt="" />
          </div>
        </div>
      </Box>

      <Box direction="row">
        <Box direction="row" style={{ flex: 1 }}>
          <Text style={{ marginRight: 10 }}>Buscar: </Text>
          <Input
            style={{ width: '20%' }}
            placeholder="Buscar..."
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>
        <Box>
          <button
            className="btn-generar-excel"
            onClick={() => exportarExcel()}
          >
            {exportando ? "Aguarde" : "Exportar Excel"}
          </button>
        </Box>
      </Box>
      <Box>
        <DataTable style={{ fontSize: '13px', borderCollapse: 'collapse' }} columns={columns} data={pedidos.tickets} />
      </Box>
    </Box>
  );
};

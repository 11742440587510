import React from  "react"
import { AppRoutes } from "../src/routes/app.routes";
function App() {


  return (
   <>
      <AppRoutes />
   </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { api } from "../../../services/api";
import "./manifiesto.styles.css";
import Swal from "sweetalert2";


export const Manifiesto = () => {
    const usuario = JSON.parse(localStorage.getItem("usuario"));

    const letras = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "y",
        "x",
        "z",
        "a1",
        "b1",
        "c1",
        "d1",
        "e1",
        "f1",
        "j1",
        "k1",
        "l1",
        "m1",
        "n1",
        "o1",
        "p1",
        "q1",
        "r1",
        "s1",
        "t1",
        "u1",
        "v1",
        "w1",
        "y1",
        "x1",
        "z1",
    ];

    const [pesquisado, setPesquisado] = useState(false);
    const [gerando, setGerando] = useState(false);
    const [filmes, setFilmes] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [salas, setSalas] = useState([]);
    const [qtdBlocos, setQtdBlocos] = useState(0);
    const [salaFinal, setSalaFinal] = useState([]);
    const [infoGeneral, setInfoGeneral] = useState({});
    const [infoButacas, setInfoButacas] = useState({});
    const [infoTabla, setInfoTabla] = useState([]);
    const [filterData, setFilterData] = useState({
        data: null,
        filme_id: 0,
        horario_id: 0,
        sala_id: 0,
    });

    async function getFilmesManifiesto() {
        let token = localStorage.token;
        const response = await api.get("/v1/relatorios/manifiesto/filmes", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        setFilmes(response.data.data.filmes);
    }

    async function getSalas() {
        let token = localStorage.token;
        const response = await api.get("/v1/sala/pesquisar", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        setSalas(response.data.data);
    }

    const getParamsManifiesto = () => {
        let paramsUrl = "";
        if (filterData.data) {
            paramsUrl += "&data=" + filterData.data;
        }
        if (filterData.filme_id) {
            paramsUrl += "&filme_id=" + filterData.filme_id;
        }
        if (filterData.horario_id) {
            paramsUrl += "&horario_id=" + filterData.horario_id;
        }
        if (filterData.sala_id) {
            paramsUrl += "&sala_id=" + filterData.sala_id;
        }
        return paramsUrl
    }

    const handleData = (e) => {
        e.preventDefault();
        let filtros = { data: e.target.value, filme_id: 0, horario_id: 0, sala_id: 0 };
        setHorarios([])
        setFilterData(filtros);
    }

    const handleFilme = (e) => {
        setHorarios([])
        e.preventDefault();
        let value = e.target.value;
        let filtros = { ...filterData, filme_id: value, horario_id: 0, sala_id: 0 };
        setFilterData(filtros);

        let filme = filmes.find(e => e.id == value);

        let horariosSet = filme.horarios_set.filter(e => e.hora.split(' ')[0] == filterData.data)
        setHorarios(horariosSet)

    }

    const handleHorario = (e) => {
        e.preventDefault();

        let value = e.target.value;
        let horario = horarios.find(e => e.id == value);

        let filtros = { ...filterData, horario_id: value, sala_id: horario.sala_id };
        setFilterData(filtros);

    }

    async function getManifiesto() {

        let token = localStorage.token;

        setGerando(true);
        setPesquisado(false);
        setSalaFinal([]);

        let params = getParamsManifiesto();

        const response = await api.get("/v1/relatorios/manifiesto?d=1" + params, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        setInfoGeneral(response.data.data.general)
        setInfoButacas(response.data.data.infoButacas)
        setInfoTabla(response.data.data.infoTabla)

        let sala = response.data.data.infoButacas.horario.sala;
        let reservados = response.data.data.infoButacas.reservados;
        let sin_confirmar = response.data.data.infoButacas.sin_confirmar;

        let final = [];

        setQtdBlocos(sala.blocos.length);

        let sinConfirmar = null;

        await sala.blocos.map((bloco, k) => {
            final[bloco.id] = [];
            bloco.assentos.map(async (assento, key) => {
                assento.letra = letras[assento.linha];

                if (reservados.indexOf(assento.id) !== -1) {
                    assento.ocupado = 1;
                }

                sinConfirmar = await sin_confirmar.find(e => e.assento_id == assento.id)
                if (sinConfirmar !== undefined) {
                    assento.ocupado = 2;
                }

                if (typeof final[bloco.id][assento.coluna] == "undefined") {
                    final[bloco.id][assento.coluna] = {
                        [assento.linha]: assento,
                    };
                } else {
                    final[bloco.id][assento.coluna][assento.linha] = assento;
                }
            });
        });
        setSalaFinal(final);

        setGerando(false);
        setPesquisado(true);

    }

    const desbloqAssiento = async (assento) => {
        if (assento.ocupado == 2) {
            let token = localStorage.token;

            let registro = await infoButacas.sin_confirmar.find(e => e.assento_id == assento.id)

            Swal.fire({
                title: 'Liberar asiento',
                text: 'Este asiento empezó proceso de compra el ' + registro.updated_at + ', ¿Desea liberar el asiento?',
                showConfirmButton: true,
                confirmButtonText: 'Confirmar',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
            }).then(async (e) => {
                if (e.isConfirmed) {
                    let params = {ingressos: [registro.id]};
                    await api.post("/v1/ingresso/cancelar-ingressos", params, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    }).then(resp => {
                        alert(resp.data.mensagem);
                    }).catch(error => {
                        alert(error.response.data.mensagem)
                    }).finally(() => {
                        getManifiesto()
                    })
                }
            })

            
        }
    }

    async function exportManifiesto() {
        let paramsUrl = getParamsManifiesto();
        let url = "https://api-cinesol.busticket.cl/relatorios/manifiesto/export?d=1" + paramsUrl;
        window.open(url, "_blank");
    }

    useEffect(() => {
        getSalas();
        getFilmesManifiesto();
    }, []);

    return (
        <div className="container-home">

            <div className="container-main-pai">
                <h4>Muestra la ocupación de una sala para una función específica con el detalle de cada butaca vendida que se encuentra ocupada, si la entrada fue vendida online muestra los datos del comprador y si fue validado el código QR de la entrada.</h4>

                <div className="filtros">
                    <div className="filtro-field">
                        <label style={{ marginRight: '5px' }}>Fecha</label>
                        <input
                            type="date"
                            name="data"
                            onChange={handleData}
                        />
                    </div>
                </div>
                <div className="filtros">
                    <div className="filtro-field">
                        <p>Función</p>
                        <select name="filme_id" value={filterData.filme_id} id="funcion_id" onChange={handleFilme} disabled={filterData.data == null}>
                            <option value={0}>Seleccione</option>
                            {filmes.map((filme) => (
                                <option value={filme.id}>{filme.titulo}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filtro-field">
                        <p>Horario</p>
                        <select name="horario_id" value={filterData.horario_id} onChange={handleHorario} disabled={filterData.filme_id == 0}>
                            <option value={0}>Seleccione</option>
                            {horarios.map((item) => (
                                <option value={item.id}>{item.horario}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filtro-field">
                        <p>Sala</p>
                        <select name="sala_id" value={filterData.sala_id} disabled>
                            <option value={0}>Seleccione</option>
                            {salas.map((item) => (
                                <option value={item.id}>{item.nome}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="filtros">
                    <button className="btn-generar" onClick={() => getManifiesto()} disabled={filterData.sala_id == 0}>
                        {gerando ? "Aguarde" : "Generar"}
                    </button>
                    <button className="btn-generar-excel" onClick={() => exportManifiesto()} disabled={filterData.sala_id == 0}>
                        {gerando ? "Aguarde" : "Exportar Excel"}
                    </button>
                </div>
            </div>
            <br />
            <br />
            {pesquisado && (
                <>
                    <div className="container-main-pai" style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <table style={{ border: 0 }}>
                            <tbody>
                                <tr>
                                    <th style={{ border: 0 }}>Función</th>
                                    <td style={{ border: 0 }}>{infoGeneral.hora}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: 0 }}>sala</th>
                                    <td style={{ border: 0 }}>{infoGeneral.sala}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: 0 }}>Película</th>
                                    <td style={{ border: 0 }}>{infoGeneral.pelicula_evento}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: 0 }}>Hora emisión Manifiesto</th>
                                    <td style={{ border: 0 }}>{infoGeneral.hora_emision}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: 0 }}>Vendidos</th>
                                    <td style={{ border: 0 }}>{infoGeneral.vendidos}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: 0 }}>Qr Validados</th>
                                    <td style={{ border: 0 }}>{infoGeneral.leidos}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: 0 }}>Disponibles</th>
                                    <td style={{ border: 0 }}>{infoGeneral.disponibles}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: 0 }}>Capacidad Sala</th>
                                    <td style={{ border: 0 }}>{infoGeneral.capacidad}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container-main-pai" style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <h4 className="title-selecione-header">
                            {infoGeneral.vendidos} de {infoGeneral.capacidad} plazas ocupadas (
                            <span>
                                {parseInt((infoGeneral.vendidos / infoGeneral.capacidad) * 100)}%
                            </span>
                            )
                        </h4>
                    </div>
                    <div className="pantalla"></div>
                    <div className="sala">
                        {salaFinal.map((bloco, k) => (
                            <div className="bloco">
                                {bloco.map((linha, key) => (
                                    <div className="linha">
                                        {Object.keys(linha).map((assento) => (
                                            <>
                                                {linha[assento].selecionado === 1 && linha[assento].ignorar == 0 ? (
                                                    <button className={"assento selecionado" + (qtdBlocos <= 2 ? "x15" : "")} >
                                                        <p>
                                                            {letras[linha[assento].linha] +
                                                                linha[assento].assento_nome.substring(1, 3)}
                                                        </p>
                                                    </button>
                                                ) : linha[assento].ignorar == 0 ? (
                                                    <button onClick={() => desbloqAssiento(linha[assento])} className={linha[assento].ocupado === 1 ? "assento comprado " + (qtdBlocos <= 2 ? "x15" : "")
                                                        : (linha[assento].ocupado == 2 ? "assento sinconfirmar " + (qtdBlocos <= 2 ? "x15" : "") : "assento " + (qtdBlocos <= 2 ? "x15" : ""))}>
                                                        <p>
                                                            {letras[linha[assento].linha] +
                                                                linha[assento].assento_nome.substring(1, 3)}
                                                        </p>
                                                    </button>
                                                ) : (
                                                    <button className={"assento " + (qtdBlocos <= 2 ? "x15" : "")} style={{ opacity: 0, cursor: "default" }} >
                                                    </button>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="flex-colunm" >
                        <div className="display-flex">
                            <div className="assento comprado"></div>
                            <span style={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: 'bold' }}>Comprado</span>
                        </div>
                        <div className="display-flex">
                            <div className="assento sinconfirmar"></div>
                            <span style={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: 'bold' }}>Sin confirmar</span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Asientos vendidos</h3>
                    <div className="container-main-pai" style={{
                        justifyContent: 'center',
                        display: 'flex',
                        paddingBottom: 30
                    }}>
                        <table style={{ borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Asiento</th>
                                    <th>Rut Cliente</th>
                                    <th>Nombre y apellido Cliente</th>
                                    <th>Validación QR</th>
                                    <th>Usuario QR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoTabla.map((venta) => (
                                    <tr>
                                        <td>{venta.id}</td>
                                        <td>{venta.asiento}</td>
                                        <td>{venta.rut}</td>
                                        <td>{venta.cliente}</td>
                                        <td>{venta.hora_leido}</td>
                                        <td>{venta.usuario_lee}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>


            )}
        </div>
    );
};

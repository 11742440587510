import React, { useEffect, useState } from "react";
import '../ModalAgregarHr/ModalAgregarHr.styles.css'
export const ModalVisualizarTicket = (props) => {

    useEffect(() => {
    }, []);
    return (
        <>
            {
                props.ticketInfo && props.filmeInfo ? 
                <div className="container-area-modal-agregrar-hr" style={{ zIndex: 100 }}>
                    <button className="button-closed-agregrar-hr" onClick={() => {
                        props.closemodal()
                    }}>X</button>

                    <div className="title-modal-agregrar-hr">
                        <h1>Editar Entrada</h1>
                    </div>

                    <form className="modal-container-agregrar-hr" onSubmit={false}>

                        <div className="container-main-body">
                            <div className="container-main-body-column-n">

                                <div className="container-main-input-n">
                                    <p>Película</p>
                                    <input value={props.filmeInfo.titulo} />
                                </div>

                                <div className="container-main-input-n">
                                    <p>Número de Asiento</p>
                                    <input value={props.ticketInfo.assento_nome} />
                                </div>

                                <div className="container-main-input-n">
                                    <p>Audio</p>
                                    <input value={props.ticketInfo.horario.audio === 'dub' ? 'dob' : props.ticketInfo.horario.audio} />
                                </div>

                                <div className="container-main-input-n">
                                    <p>Fecha</p>
                                    <input value={props.ticketInfo.horario.data_hora} />
                                </div>

                                <div className="container-main-input-n">
                                    <p>Día de la Semana</p>
                                    <input value={props.ticketInfo.horario.dia_da_semana} />
                                </div>
                                <div className="container-main-input-n">
                                    <p>Precio Entrada</p>
                                    <input value={ '$' + props.ticketInfo.horario.valor.toLocaleString('pt-br', { currency: 'CLP' })} />
                                </div>
                            </div>
                        </div>

                        <div className="footer" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <button className="footer-button-add-horario" onClick={(e) => { e.preventDefault(); props.redirect() }}>
                                <span>Editar</span>
                            </button>
                        </div>

                    </form>

                </div>
                :
                <></>
            }
        </>
    );
};


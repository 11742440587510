import styled from "styled-components";

export default styled.div`
  display: flex;
  height: 313px;
  width: 100%;
  color: #fff;
  border-radius:  20px;
  font-size: 1em;
  position: relative;
  align-items: flex-start;

  
`;
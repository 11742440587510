import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import './ModalIngressarSala.styles.css';
import { api } from '../../services/api';
export const ModalBanner = () => {
    const history = useHistory()
    const [isModalVisible, setisModalVisible] = useState(true)
    const [salaData, setSalaData] = useState({})
    const [selectedFile, setSelectedFile] = useState([]);
    const [salvando, setSalvando] = useState(false);

    const handleSalaData = (e) => {
        e.preventDefault();
        setSalaData({ ...salaData, [e.target.name]: e.target.value })
    }

    function handleModalVisible() {
        window.location.reload();
        history.push('/calendario');
    };

    const salvarFilme = event => {
        event.preventDefault();

        setSalvando(true)
        const formData = new FormData();
        formData.append("nome", salaData.nome);
        formData.append("foto", filmeData.foto);

        let token = localStorage.token;
        api.post('/v2/criar-banner', formData, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((response) => {
            setSalvando(false)
            window.location.reload();
        }).catch(function () {
            // window.location.reload();
            // history.push('/cartelera');
        })
    }

    const [filmeData, setFilmeData] = useState({});

    const handleFile = (e) => {
        e.preventDefault();

        // formData.append("file", e.target.files[0], e.target.files[0].name);
        setFilmeData({ ...filmeData, 'foto': e.target.files[0] })
    }

    useEffect(() => {
    }, []);
    return (
        <>
            {
                isModalVisible &&
                <div className="container-area-modal-Ingressar-pelicula">
                    <button className="button-closed-modal-Ingressar-pelicula" onClick={handleModalVisible}>X</button>
                    <form className="modal-container-modal-Ingressar-pelicula" onSubmit={salvarFilme}>
                        <div className="title--modal-Ingressar-pelicula">
                            <h1 style={{ color: "#fff" }}>Agregar Banner</h1>
                        </div>
                        <div className="body-modal-Ingressar-pelicula01">
                            <div className="container-input-ingressar-pelicula01">
                                <p>Nombre</p>
                                <input type="text" required name="nome" onChange={handleSalaData} />
                            </div>

                            <div className="body-modal-Ingressar-pelicula01" style={{ marginLeft: '8%', marginTop: '4%' }}>
                                <input type="file" required onChange={handleFile} />

                            </div>

                        </div>



                        <div className="footer-pelicula">
                            {
                                salvando ?
                                    <button className="footer-button-add-pelicula" disabled>
                                        <span>Adicionando</span>
                                    </button>
                                    :
                                    <button className="footer-button-add-pelicula">
                                        <span>Adicionar</span>
                                    </button>
                            }

                        </div>
                    </form>

                </div>
            }
        </>
    );
};


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { SignIn } from "../screens/SignIn";
import { Cartelera } from "../screens/Cartelera";
import { Calendary } from "../screens/Calendary";
import { Wallet } from "../screens/Wallet";
import { Butacas } from "../screens/Butacas";
import { Payment } from "../screens/Payment";
import { Billete } from "../screens/Billete";
import { Finally } from "../screens/Finally";
import { Clients } from "../screens/Clients";
import { Rooms } from "../screens/Rooms";
import { Cupon } from "../screens/Cupon";
import { Informations } from "../screens/Informations";
import { Autenticacion } from "../screens/Autenticacion";
import { RelatorioVendedor } from "../screens/RelatorioVendedor";
import { Ingresso } from "../screens/Ingresso";
import { EventoBuy } from "../screens/eventos";
import { EventoImprimir } from "../screens/eventosImprimir/EventoImprimir";

export const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route path="/cartelera" component={Cartelera} />
        <Route path="/calendario" component={Calendary} />
        <Route path="/pelicula" component={Wallet} />
        <Route path="/eventos/:eventoId" component={EventoBuy} />
        <Route path="/butacas" component={Butacas} />
        <Route path="/pago" component={Payment} />
        <Route path="/ticket" component={Billete} />
        <Route path="/finalizado" component={Finally} />
        <Route path="/evento/finalziado/:vid" component={EventoImprimir} />
        <Route path="/informes" component={Informations} />
        <Route path="/usuarios" component={Clients} />
        <Route path="/salas" component={Rooms} />
        <Route path="/cupon" component={Cupon} />
        <Route path="/autenticacion" component={Autenticacion} />
        <Route path="/relatorio-vendedor" component={RelatorioVendedor} />
        <Route path="/ingresso" component={Ingresso} />
      </Switch>
    </Router>
  );
};

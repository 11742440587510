import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import './ModalIngressarSala.styles.css';
import { api } from '../../services/api';
export const ModalIngressarSala = () => {
    const history = useHistory()
    const [salaData, setSalaData] = useState({})
    const [salvando, setSalvando] = useState(false);

    const handleSalaData = (e) => {
        e.preventDefault();
        setSalaData({ ...salaData, [e.target.name]: e.target.value })
    }

    function handleModalVisible() {
        window.location.reload();
        history.push('/calendario');
    };

    const salvarFilme = event => {
        event.preventDefault();

        setSalvando(true)

        let token = localStorage.token;
        api.post('/v1/sala/salvar', salaData, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((response) => {
            window.location.reload();
            history.push('/cartelera');

        }).catch(function () {
            window.location.reload();
            history.push('/cartelera');
        })
    }

    useEffect(() => {
    }, []);
    return (
        <div className="container-area-modal-Ingressar-pelicula">
            <button className="button-closed-modal-Ingressar-pelicula" onClick={handleModalVisible}>X</button>
            <form className="modal-container-modal-Ingressar-pelicula" onSubmit={salvarFilme}>
                <div className="title--modal-Ingressar-pelicula">
                    <h1 style={{ color: "#fff" }}>Agregar Espacio</h1>
                </div>
                <div className="body-modal-Ingressar-pelicula01">
                    <div className="container-input-ingressar-pelicula01">
                        <p>Nombre</p>
                        <input type="text" required name="nome" onChange={handleSalaData} />
                    </div>

                    <div className="container-input-ingressar-pelicula01">
                        <p>Blocos (JSON)</p>
                        <input type="text" required name="blocos" onChange={handleSalaData} />
                    </div>

                </div>



                <div className="footer-pelicula">
                    {
                        salvando ?
                            <button className="footer-button-add-pelicula" disabled>
                                <span>Adicionando</span>
                            </button>
                            :
                            <button className="footer-button-add-pelicula">
                                <span>Adicionar</span>
                            </button>
                    }

                </div>
            </form>

        </div>
    );
};
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";
import "./Butacas.styles.css";
import userIcon from "../../assets/user-icon.png";
import { api } from "../../services/api";
import { User } from "../../components/User";
export const Butacas = () => {
  const [sala, setSala] = useState({});
  const [filme, setFilme] = useState({});
  const [qtdBlocos, setQtdBlocos] = useState(0);
  const [salaFinal, setSalaFinal] = useState([]);
  const [ingressosSelecionados, setIngressosSelecionados] = useState([]);
  const [ingresso, setIngresso] = useState({});
  const [letras, setLetras] = useState([
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "x",
    "z",
    "a1",
    "b1",
    "c1",
    "d1",
    "e1",
    "f1",
    "j1",
    "k1",
    "l1",
    "m1",
    "n1",
    "o1",
    "p1",
    "q1",
    "r1",
    "s1",
    "t1",
    "u1",
    "v1",
    "w1",
    "y1",
    "x1",
    "z1",
  ]);
  const [reservados, setReservados] = useState([]);
  const history = useHistory();
  const paramsG = new URLSearchParams(window.location.search);
  const [isEditing, setIsEditing] = useState(paramsG.get("edit"));

  async function getFilme() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/v1/filme/pesquisar-info/" + params.get("id"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setFilme(response.data.data);
  }

  const verificaSelecionado = function (assento) {
    let verify = false;
    ingressosSelecionados.map((ing) => {
      if (
        ing.linha === assento.linha &&
        ing.coluna === assento.coluna &&
        ing.bloco_id === assento.bloco_id
      ) {
        verify = true;
      }
    });
    return verify;
  };

  const reservarAssentos = function () {
    let ingresso = JSON.parse(localStorage.ingresso);
    ingresso.assentos = ingressosSelecionados;
    localStorage.ingresso = JSON.stringify(ingresso);
    let token = localStorage.token;

    if (!ingresso || !ingresso.horario_id) {
      history.push("/cartelera");
    }

    if (localStorage.ingressos) {
      let ingS = JSON.parse(localStorage.ingressos);

      if (typeof ingS[0] != "undefined" && ingS[0].venda_id) {
        ingresso.venda_id = ingS[0].venda_id;
      }
    }

    ingresso.edit = isEditing;
    ingresso['plataforma_id'] = 1;

    api
      .post("/v1/ingresso/reservar-assentos", ingresso, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let params = new URLSearchParams(window.location.search);

        if (!response.data.data) {
          alert(
            response.data.mensagem
          );
          window.location.reload();
        } else {
          localStorage.ingressos = JSON.stringify(response.data.data);
          history.push("/pago?id=" + params.get("id"));
        }
      })
      .catch(function (response) {
        return 1;
      });
  };


  async function getAssentos() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);
    let ingresso = JSON.parse(localStorage.ingresso);

    if (!ingresso || !ingresso.horario_id) {
      history.push("/cartelera");
    }

    const response = await api.get(
      "/v1/ingresso/pesquisar-assentos/" + ingresso.horario_id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    let sala = response.data.data.horario.sala;
    let reservados = response.data.data.reservados;
    setSala(sala);
    setReservados(reservados);

    let final = [];

    setQtdBlocos(sala.blocos.length);

    sala.blocos.map((bloco, k) => {
      final[bloco.id] = [];
      bloco.assentos.map((assento, key) => {
        assento.letra = letras[assento.linha];

        ingressosSelecionados.map((ing) => {
          if (
            ing.linha === assento.linha &&
            ing.coluna === assento.coluna &&
            ing.bloco_id === assento.bloco_id
          ) {
            assento.selecionado = 1;
          }
        });

        if (reservados.indexOf(assento.id) !== -1) {
          assento.ocupado = 1;
        }

        if (typeof final[bloco.id][assento.coluna] == "undefined") {
          final[bloco.id][assento.coluna] = {
            [assento.linha]: assento,
          };
        } else {
          final[bloco.id][assento.coluna][assento.linha] = assento;
        }
      });
    });

    setSalaFinal(final);

    getButacasLocal(final);
  }

  const arrayRemove = function (arr, value) {
    return arr.filter(function (geeks) {
      return geeks != value;
    });
  };

  var ingSelected = [];

  const selecionarAssentoAlt = function (assento, force) {
    if (typeof force != "undefined" && force) {
      assento.selecionado = 1;
      assento.ocupado = 0;
      ingSelected.push(assento);
    }

    if (force) {
      setTimeout(function () {
        setIngressosSelecionados(ingSelected);
      });
    }
  };

  const selecionarAssento = function (assento, force) {
    let qtdIngressos = parseInt(ingresso.qtd_total);

    if (typeof force != "undefined" && force) {
      assento.selecionado = 1;
      assento.ocupado = 0;
      ingSelected.push(assento);
    } else if (
      ingressosSelecionados.length < qtdIngressos &&
      assento.ocupado != 1
    ) {
      if (!verificaSelecionado(assento)) {
        assento.selecionado = 1;
        setIngressosSelecionados((prevArray) => [...prevArray, assento]);
      } else {
        assento.selecionado = 0;

        setIngressosSelecionados(arrayRemove(ingressosSelecionados, assento));
      }
    } else if (assento.selecionado == 1) {
      assento.selecionado = 0;

      setIngressosSelecionados(arrayRemove(ingressosSelecionados, assento));
    } else {
    }

    if (force) {
      setIngressosSelecionados(ingSelected);
    }
  };

  const getButacasLocal = function (final) {
    if (localStorage.ingressos) {
      let ingressosL = JSON.parse(localStorage.ingressos);

      final.map((bloco, k) => {
        bloco.map((linha, key) => {
          Object.keys(linha).map((assento) => {
            ingressosL.map((ingL) => {
              if (linha[assento].id == ingL.assento_id) {
                selecionarAssentoAlt(linha[assento], true);
              }
            });
          });
        });
      });
    }
  };

  useEffect(() => {
    getAssentos();
    getFilme();
  }, []);

  useEffect(() => {
    setIngressosSelecionados(ingressosSelecionados);
    setIngresso(JSON.parse(localStorage.ingresso));
  }, [ingressosSelecionados]);

  useEffect(() => {}, [ingressosSelecionados]);

  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      <div className="container-category-filme">
        <div className="container-pai">
          <div className="container-pai-filme">
            <div className="detalhes-filme">
              <img src={filme.link_img_capa} alt="" className="img-capa" />
              <div className="infos-filme-carteira">
                <h2 className="title-sinopse">{filme.titulo}</h2>
                <p>2019 - Ação | Fantasia</p>
                <p>Diretor: {filme.diretor}</p>
                <p>
                  {" "}
                  <AiFillStar className="iconStar" />
                  7.5 (85,741)
                </p>
                <h6>
                  We all have a superhero inside us, it just takes a bit of
                  magic to bring it out. In Billy Batson's case, by shouting out
                  one word - SHAZAM. - this streetwise fourteen-year-old foster
                  kid can turn into the grown-up superhero Shazam
                </h6>
              </div>
            </div>
            <div className="informacoes-detalhes">
              <div className="detalhes-filme-direita">
                <div className="container-main-sesion">
                  <div className="container-sesion">
                    <a
                      href={"/pelicula?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      <div className="bolinha1">
                        <span>01</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="main-bolinha1">
                      <span>02</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>03</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>04</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>05</span>
                    </div>
                  </div>
                </div>
                <div className="description-bolinha">
                  <p>
                    <a
                      href={"/pelicula?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      Selección
                    </a>
                  </p>
                  <p>Butacas</p>
                  <p>Pago</p>
                  <p>Ticket</p>
                  <p>Finalizado</p>
                </div>
              </div>

              <div className="content-payment">
                <div className="infos-compra">
                  <h4 className="title-selecione-header">
                    Selección de asientos: {ingressosSelecionados.length} de{" "}
                    {parseInt(ingresso.qtd_total)} ({sala.nome})
                  </h4>
                  <h4 className="title-selecione-header">
                    {reservados.length} de {sala.qtd_assentos} plazas ocupadas (
                    <span>
                      {parseInt((reservados.length / sala.qtd_assentos) * 100)}%
                    </span>
                    )
                  </h4>
                </div>
                <p className="subtitle-header">Pantalla</p>
                <hr className="container-line-butacas" />
                <div className="sala">
                  {salaFinal.map((bloco, k) => (
                    <div className="bloco">
                      {bloco.map((linha, key) => (
                        <div className="linha">
                          {Object.keys(linha).map((assento) => (
                            <>
                              {linha[assento].selecionado === 1 && linha[assento].ignorar == 0 ? (
                                <button
                                  className={
                                    "assento selecionado " +
                                    (qtdBlocos <= 2 ? "x15" : "")
                                  }
                                  onClick={() =>
                                    selecionarAssento(linha[assento])
                                  }
                                >
                                  {typeof salaFinal[k - 2] != "undefined"
                                    ? letras[linha[assento].linha] +
                                      (parseInt(salaFinal[k - 2].length) +
                                        parseInt(salaFinal[k - 1].length) +
                                        linha[0].coluna +
                                        1)
                                    : typeof salaFinal[k - 1] != "undefined"
                                    ? letras[linha[assento].linha] +
                                      (parseInt(salaFinal[k - 1].length) +
                                        linha[0].coluna +
                                        1)
                                    : letras[linha[assento].linha] +
                                      (linha[0].coluna + 1)}
                                </button>
                              ) : linha[assento].ignorar == 0 ? (
                                <button
                                  className={ linha[assento].ocupado === 1
                                      ? "assento bloqueado " +
                                        (qtdBlocos <= 2 ? "x15" : "")
                                      : "assento " +
                                        (qtdBlocos <= 2 ? "x15" : "")
                                  }
                                  onClick={() =>
                                    selecionarAssento(linha[assento])
                                  }
                                >
                                  {linha[assento].ocupado === 1 ? (
                                    <img src={userIcon} alt="use" />
                                  ) : typeof salaFinal[k - 2] != "undefined" ? (
                                    letras[linha[assento].linha] +
                                    (parseInt(salaFinal[k - 2].length) +
                                      parseInt(salaFinal[k - 1].length) +
                                      linha[0].coluna +
                                      1)
                                  ) : typeof salaFinal[k - 1] != "undefined" ? (
                                    letras[linha[assento].linha] +
                                    (parseInt(salaFinal[k - 1].length) +
                                      linha[0].coluna +
                                      1)
                                  ) : (
                                    letras[linha[assento].linha] +
                                    (linha[0].coluna + 1)
                                  )}
                                </button>
                              ) : (
                                <button
                                  className={ "assento " + (qtdBlocos <= 2 ? "x15" : "") }
                                  style={{ opacity: 0, cursor: "default" }}
                                >
                                  
                                </button>
                              )}
                            </>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <div className="flex-column">
                  <div className="display-flex">
                    <div className={"assento bloqueado "}>
                      <img src={userIcon} alt="user icon ocupado" />
                    </div>
                    <p style={{ margin: 0 }}>Ocupado</p>
                  </div>
                  <div className="display-flex">
                    <div className={"assento selecionado "}></div>
                    <p style={{ margin: 0 }}>Seleccionado</p>
                  </div>
                </div>

                {ingressosSelecionados.length ? (
                  <div className="assentos-selecionados">
                    <h4>Asientos seleccionados</h4>
                    {ingressosSelecionados.map((ing) => (
                      <div className="assento-selecionado">
                        {ing.assento_nome}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                <div className="text-right" style={{ marginBottom: 50 }}>
                  <a
                    href={"/pelicula?id=" + paramsG.get("id")}
                    className="button-cancel"
                  >
                    <span>Volver</span>
                  </a>
                  {ingressosSelecionados.length ===
                  parseInt(ingresso.qtd_total) ? (
                    <button
                      onClick={reservarAssentos}
                      className="button-continue"
                    >
                      <span>Continuar</span>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
// Componnets
import { ButtonSignIn } from "../../components/Forms/Button";
import { useHistory } from "react-router-dom";

import { MdMarkunread } from "react-icons/md";
import { FaAsterisk } from "react-icons/fa";

import { api } from "../../services/api";

import "./Signin.styles.css";
import logo from "../../assets/logo.png";
import { Box, Main, TextInput } from "grommet";
import backgroundImage from "../../assets/background.png";
export const SignIn = () => {
  const history = useHistory();
  const [messageError, setMessageError] = useState(false);

  const [userData, setUserData] = useState({});

  const handleLogin = (e) => {
    e.preventDefault();

    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const login = (event) => {
    event.preventDefault();
    api
      .post("login", userData)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("usuario", JSON.stringify(response.data.usuario));
        setMessageError(false);
        history.push("/cartelera");
      })
      .catch(function () {
        setMessageError(true);
      });
  };

  useEffect(() => {}, []);

  return (
    <Main
      // justify="center"
      align="center"
      style={{ backgroundImage: `url(${backgroundImage})`, minHeight: "100vh" }}
    >
      <Box gap="large" align="center" justify="center">
        <div className="logo-div">
          <img src={logo} alt="logo" className="image-logo" />
        </div>

        <Box
          align="center"
          justify="center"
          style={{
            backgroundColor: "rgba(0,0,0,.5)",
            boxShadow:
              "inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%)",
          }}
          pad="small"
        >
          <h1 className="title-bemvindo">Bienvenidos a Cinesol</h1>
          <p
            className="subtitle"
            style={{
              marginTop: "5px",
              width: "24rem",
              color: "white",
              textAlign: "center",
              fontSize: "26px",
            }}
          >
            Ingrese su nombre de usuario y contraseña{" "}
            <span>para acceder al sistema</span>
          </p>

          {messageError && (
            <p
              className="subtitle-error"
              style={{
                marginTop: "5px",
                width: "24rem",
                color: "red",
                textAlign: "center",
                fontSize: "18px",
              }}
            >
              Úsuario o contraseña inválidas
            </p>
          )}

          <form onSubmit={login}>
            {/* <InputForm /> */}

            <Box gap="small">
              <TextInput
                placeholder=" Usuario"
                type="text"
                name="email"
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  borderRadius: "18px",
                }}
                icon={<MdMarkunread color="#CECECE" />}
                onChange={handleLogin}
              />

              <TextInput
                placeholder="Contraseña"
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  borderRadius: "18px",
                }}
                icon={<FaAsterisk color="#CECECE" />}
                type="password"
                name="password"
                onChange={handleLogin}
              />
            </Box>
            <Box alignSelf="center" align="center" justify="center">
              <div className="button">
                <ButtonSignIn title="Entrar" />
              </div>
            </Box>
          </form>
        </Box>
      </Box>
    </Main>
  );
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ModalIngressarPelicula.styles.css";
import { api } from "../../services/api";
import { Box } from "grommet";

export const ModalIngressarEvento = () => {
  const history = useHistory();
  const [filmeData, setFilmeData] = useState({});
  const [uploading, setUploading] = useState(false);

  const handleFilmeData = (e) => {
    e.preventDefault();
    setFilmeData({ ...filmeData, [e.target.name]: e.target.value });
  };

  const formData = new FormData();

  const handleFile = (e) => {
    e.preventDefault();

    setFilmeData({ ...filmeData, file: e.target.files[0] });
  };

  function handleModalVisible() {
    window.location.reload();
    history.push("/cartelera");
  }

  const salvarFilme = (event) => {
    event.preventDefault();

    setUploading(true);

    formData.append("titulo", filmeData.titulo);
    formData.append("duracao_minutos", filmeData.duracao_minutos);
    formData.append("distribuidor", filmeData.distribuidor);
    formData.append("file", filmeData.file, filmeData.file.name);

    let token = localStorage.token;
    api
      .post("/v2/evento/salvar", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data;",
          Accept: "*/*",
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch(function () {
        window.location.reload();
        history.push("/cartelera");
      });
  };

  useEffect(() => { }, []);

  return (
    <div className="container-area-modal-Ingressar-pelicula">
      <button
        className="button-closed-modal-Ingressar-pelicula"
        onClick={handleModalVisible}
      >
        X
      </button>
      <form
        className="modal-container-modal-Ingressar-pelicula"
        onSubmit={salvarFilme}
      >
        <div className="title--modal-Ingressar-pelicula">
          <h1 style={{ color: "#fff" }}>Ingresar Evento</h1>
        </div>

        <Box direction="row">
          <div className="container-input-ingressar-pelicula01">
            <p>Nombre Evento:</p>
            <input
              type="text"
              required
              name="titulo"
              onChange={handleFilmeData}
            />
          </div>

          <div className="container-input-ingressar-pelicula01">
            <p>Duración (en minutos):</p>
            <input
              type="number"
              name="duracao_minutos"
              required
              onChange={handleFilmeData}
            />
          </div>
        </Box>

        <div className="body-modal-Ingressar-pelicula01">
          <div className="container-input-ingressar-pelicula01">
            <p>Distribuidor</p>
            <input
              type="text"
              name="distribuidor"
              required
              onChange={handleFilmeData}
            />
          </div>
        </div>

        <div className="body-modal-Ingressar-pelicula01">
          <input type="file" required onChange={handleFile} />
        </div>
        <div className="footer-pelicula">
          {uploading ? (
            <button className="footer-button-add-pelicula" disabled>
              <span>Adicionando</span>
            </button>
          ) : (
            <button className="footer-button-add-pelicula">
              <span>Guardar</span>
            </button>
          )}
        </div>
      </form>
    </div>

  );
};

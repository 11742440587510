import React, { useState } from "react";
import "./ButtonCalendary.styles.css";
import { ModalAgregarHr } from "../../ModalAgregarHr";
import { ModalAgregarHrEvento } from "../../Horarios/AgregarHoarioEvento";

export const ButtonCalendary = ({ title, itemId, type }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCupon = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      {isModalVisible && <> {type === "film" ? <ModalAgregarHr filmeid={itemId} /> : <ModalAgregarHrEvento eventoId={itemId}/>} </>}
      <div className="container-input">
        <button className="container-button" onClick={handleCupon}>
          {title}
        </button>
      </div>
    </>
  );
};

import { api } from '../../services/api';
import React, { useEffect, useState } from "react";
import '../ModalAgregarHr/ModalAgregarHr.styles.css';

const AdicionarClienteModal = ({ show, close, funcionario = null }) => {

    const [salvando, setSalvando] = useState(false);

    const [formState, setFormState] = useState({
        nome: "",
        email: "",
        password: "",
        papel_id: "",
    });

    const handleUpdateForm = (key, value) => setFormState({ ...formState, [key]: value });

    const salvarCliente = event => {
        event.preventDefault();
        setSalvando(true);
        let token = localStorage.token;

        if (!funcionario.id)
            api.post('registrar', formState
            ).then((response) => {
                window.location.reload();
            }).catch(function () {
                window.location.reload();
            })
        else
            api.post('v2/user/update', formState, { headers: { Authorization: "Bearer " + token } })
                .then((response) => {
                    window.location.reload();
                })
    }

    useEffect(() => {
        setFormState(funcionario);
    }, [funcionario]);

    return (
        <React.Fragment>
            {show &&
                <div className="container-area-modal-agregrar-hr" style={{ zIndex: 100 }}>
                    <button className="button-closed-agregrar-hr" onClick={close}>X</button>

                    <div className="title-modal-agregrar-hr">
                        <h1>{funcionario.id ? "Editar usuario" : "Agregar usuario"}</h1>
                    </div>

                    <form className="modal-container-agregrar-hr" onSubmit={false}>

                        <div className="container-main-body">
                            <div className="container-main-body-column-n">
                                {/* FINAL PRIMEIRO INPUT*/}
                                <div className="container-main-input-n">
                                    <p>Nombre</p>
                                    <input value={formState.nome} onChange={({ target: { value } }) => handleUpdateForm('nome', value)} name="nome" type="text" placeholder="Nombre" />
                                </div>

                                <div className="container-main-input-n">
                                    <p>Cargo</p>
                                    <select id={'genero'} style={{ width: '100%', height: '52%' }} value={formState.papel_id} onChange={({ target: { value } }) => handleUpdateForm('papel_id', value)}>
                                        <option value="" selected>Cargo</option>
                                        <option value={1} >Admin</option>
                                        <option value={2}>Vendedor</option>
                                        <option value={3}>Operador</option>
                                        <option value={4}>Admin 2</option>
                                    </select>
                                </div>


                                <div className="container-main-input-n">
                                    <p>E-mail</p>
                                    <input value={formState.email} onChange={({ target: { value } }) => handleUpdateForm('email', value)} name="email" type="text" placeholder="E-mail" />
                                </div>

                                <div className="container-main-input-n">
                                    <p>Contraseña</p>
                                    <input value={formState.password} onChange={({ target: { value } }) => handleUpdateForm('password', value)} name="password" type="password" placeholder="Contraseña" />
                                </div>


                            </div>
                        </div>

                        <div className="footer">
                            <button className="footer-button-add-horario"
                                onClick={salvarCliente}
                            >
                                <span>{salvando ? 'Creando' : 'Crear Usuario'}</span>
                            </button>
                        </div>

                    </form>

                </div>
            }
        </React.Fragment>
    );
};

export default AdicionarClienteModal;
import React, { useState, useEffect } from "react";
import { Navbar } from '../../components/Navbar';
import Photocapa from "../../assets/photo-capa.png";
import { api } from '../../services/api';
import { SiDolby } from 'react-icons/si';
import { User } from "../../components/User";
import { Switch } from 'antd';

import './Rooms.styles.css';
import { ModalIngressarSala } from "../../components/ModalIngressarSala";

import { ModalBanner } from "../../components/ModalIngressarSala/modalbanner";
import { Box } from "grommet";
import Swal from "sweetalert2";
import { ModalHistoricoSuspensiones } from "../../components/ModalHistoricoSuspensiones";

export const Rooms = () => {

    const [selectedSala, setSelectedSala] = useState(false);
    const [mostrarEstado, setMostrarEstado] = useState(false);
    const [salaActiva, setSalaActiva] = useState({});
    const [rows, setRows] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [pagina, setPagina] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [plataformas, setPlataformas] = useState([]);

    const usuario = JSON.parse(localStorage.usuario);

    const token = localStorage.token;

    //buscar salas
    async function getSalas() {

        const response = await api.get('/v1/sala/pesquisar', {
            headers: {
                Authorization: "Bearer " + token
            }
        })

        setRows(response.data.data)
    }

    //bsucar horarios
    async function getHorarios(sala_id, page) {

        const response = await api.get('/v1/sala/pesquisar-horarios/' + sala_id + '/' + page, {
            headers: {
                Authorization: "Bearer " + token
            }
        })

        setHorarios(response.data.data.data)
        setTotalPaginas(response.data.data.totalPaginas)
    }

    //bsucar plataformas
    async function getPlataformas() {
        const response = await api.get('/v1/plataformas', {
            headers: {
                Authorization: "Bearer " + token
            }
        })
        setPlataformas(response.data.data)
    }
    //selecionar salas
    const selectSala = (sala_id) => {

        let itemSala = rows.find(e => e.id == sala_id)
        setSalaActiva(itemSala.activo);
        setMostrarEstado((usuario.papel_id == 1 || usuario.papel_id == 4))
        setSelectedSala(sala_id);
        setTotalPaginas(0);
        setPagina(0);
        getHorarios(sala_id, 0);
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleBanner, setIsModalVisibleBanner] = useState(false);

    //handle sala
    const handleSala = () => {
        setIsModalVisible(true)
    };

    const [isModalHistorico, setIsModalHistorico] = useState(false);

    const verHistorico = () => {
        setIsModalHistorico(true)
    };
    
    async function cambiarEstadoSala(value) {
        console.log(value)
        Swal.fire({
            title: !value ? 'Suspender ventas' : 'Reactivar ventas',
            text: !value ? 'Ha seleccionado suspender ventas de esta sala. \n\n Permanecerán suspendidas hasta que vuelvas a activarlas.'
                : 'Al confirmar, las ventas de esta sala serán activadas nuevamente.',
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
        }).then(async (e) => {
            if (e.isConfirmed) {
                let data = {
                    sala_id: selectedSala,
                    usuario_id: usuario.id,
                    activo: value ? 1 : 0
                }
                const response = await api.post('/v1/administrar_compras/sala', data, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                })
                if (response.data.sucesso) {
                    setSalaActiva(value ? 1 : 0);
                    let salas = rows;
                    salas.map(e => {
                        if (e.id === selectedSala) {
                            e.activo = value ? 1 : 0
                        }
                    })
                    setRows(salas)
                }
            }
        })
    }

    async function cambiarEstadoHorario(value, index) {
        Swal.fire({
            title: !value ? 'Suspender ventas' : 'Reactivar ventas',
            text: !value ? 'Ha seleccionado suspender ventas de esta función. \n\n Permanecerán suspendidas hasta que vuelvas a activarlas.'
                : 'Al confirmar, las ventas de esta función serán activadas nuevamente.',
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
        }).then(async (e) => {
            if (e.isConfirmed) {
                let horariosTemp = horarios;
                setHorarios([]);
                let data = {
                    horario_id: horariosTemp[index].id,
                    usuario_id: usuario.id,
                    activo: value ? 1 : 0
                }
                const response = await api.post('/v1/administrar_compras/horario', data, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                })
                if (response.data.sucesso) {
                    horariosTemp[index].activo = value ? 1 : 0;
                    setHorarios(horariosTemp)
                }
            }
        })
    }

    async function cambiarEstadoPlataforma(value, index) {
        
        Swal.fire({
            title: !value ? 'Suspender ventas' : 'Reactivar ventas',
            text: !value ? 'Ha seleccionado suspender ventas para el canal ' + plataformas[index].nombre +'. \n\n Permanecerán suspendidas hasta que vuelvas a activarlas.'
                : 'Al confirmar, las ventas en el canal ' + plataformas[index].nombre + ' serán activadas nuevamente.',
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
        }).then(async (e) => {
            if (e.isConfirmed) {
                let plataformasTemp = plataformas;
                setPlataformas([]);
                let data = {
                    plataforma_id: plataformasTemp[index].id,
                    usuario_id: usuario.id,
                    activo: value ? 1 : 0
                }
                const response = await api.post('/v1/administrar_compras/plataforma', data, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                })
                if (response.data.sucesso) {
                    plataformasTemp[index].activo = value ? 1 : 0;
                    setPlataformas(plataformasTemp)
                }
            }
        })
    }

    let params = new URLSearchParams(window.location.search);
    const salaid = params.get('id')

    const cambiarPagina = (pag) => {
        if ((pagina + pag) >= 0 && (pagina + pag) < totalPaginas) {
            let newPage = pagina + pag;
            setPagina(newPage);
            getHorarios(selectedSala, newPage)
        }
    }

    useEffect(() => {
        getSalas()
        getPlataformas()
    }, []);

    useEffect(() => {
    }, [rows]);
    return (

        <div className="container-home">
            <div className="container-capa">
                <img src={Photocapa} alt="photo-capa" className="photo-capa" />
                <User />
            </div>
            <Navbar />
            <div className="container-category-filme">


                <div className="container-pai">

                    <div className="container-main-header-admin">

                        {isModalVisible && < ModalIngressarSala salaid={salaid} />}

                        {isModalHistorico && < ModalHistoricoSuspensiones />}

                        {isModalVisibleBanner && < ModalBanner />}

                        <div className="container-button-ingressar" >
                            {/* <button className="button-ingressar" onClick={() => setIsModalVisibleBanner(true)}>
                                <span>INGRESAR BANNER</span>
                            </button> */}

                            <button className="button-ingressar" onClick={handleSala} style={{ marginLeft: '4%' }}>
                                <span>INGRESAR SALA</span>
                            </button>
                        </div>
                        <div className="container-main-box-sala">

                            {
                                rows.map((item) => (
                                    <Box direction="column">
                                        <button className={selectedSala === item.id ? "box-sala-main-active" : "box-sala-main"} onClick={() => selectSala(item.id)}>
                                            <SiDolby className={selectedSala === item.id ? "first-text-box-sala-main-active" : "first-text-box-sala-main"} />
                                            <p className={selectedSala === item.id ? "last-text-box-sala-main-active" : "last-text-box-sala-main"}>{item.nome}</p>
                                            <p className={selectedSala === item.id ? "tri-text-box-sala-main-active" : "tri-text-box-sala-main"}>{item.qtd_assentos}</p>
                                        </button>
                                        {selectedSala === item.id && mostrarEstado && (
                                            <>
                                                <br />
                                                <Box direction="row">
                                                    <h4 style={{ marginRight: 10 }}>Venta</h4>
                                                    <Switch checked={salaActiva === 1} onChange={(e) => cambiarEstadoSala(e)} />
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                ))
                            }
                        </div>


                        <div className="container-table-admin">
                            {horarios.length ? <> <table className="tabela-cine-admin-main">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Película</th>
                                        <th>Hora</th>
                                        <th>Audio</th>
                                        <th>Calidad</th>
                                        <th>Precio</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Venta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {horarios.map((horario, index) =>
                                    (
                                        <tr>
                                            <td>{horario.data}</td>
                                            <td>{horario.filme.titulo}</td>
                                            <td>{horario.horario}</td>
                                            <td>{horario.audio == 'dub' ? 'DOB' : 'SUB'}</td>
                                            <td>{horario.qualidade}</td>
                                            <td>{horario.valor}</td>
                                            <td style={{ textAlign: 'center' }}><Switch checked={horario.activo === 1} onChange={(e) => cambiarEstadoHorario(e, index)} /></td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                                <div className="pagination">
                                    <button onClick={() => cambiarPagina(-1)}>Anterior</button>
                                    <button onClick={() => cambiarPagina(1)}>Siguiente</button>
                                </div></> : <>

                            </>}
                        </div>
                        {plataformas.length > 0 && (
                            <>
                                <br />
                                <br />
                                <h3 style={{ fontWeight: 'bold' }}>Suspender ventas por canales</h3>
                                <div className="tabla-plataformas">
                                    <table style={{ border: 0 }}>
                                        <tbody>
                                            {plataformas.map((plataforma, index) => (
                                                <tr>
                                                    <th style={{ border: 0, padding: 3, paddingRight: 10 }}>{plataforma.nombre}</th>
                                                    <td style={{ border: 0, padding: 3, paddingLeft: 10 }}><Switch checked={plataforma.activo === 1} onChange={(e) => cambiarEstadoPlataforma(e, index)} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}

                        <br />
                        <br />
                        <button className="button-historial" onClick={verHistorico} style={{}}>
                            <span>VER HISTORIAL</span>
                        </button>
                        <br />
                        <br />
                    </div>

                </div>
            </div>

        </div>
    );
};
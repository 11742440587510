import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { Box, TextInput, Text } from 'grommet';
import { Divider, DatePicker, ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { api } from '../../services/api';
import 'moment/locale/es';
import locale from 'antd/es/locale/es_ES';
import { Select, Space } from 'antd';

const { Option } = Select;


export const 
CreateCuponForSale = ({ changeScreen, dataC }) => {

    const [dataset, setDataset] = useState({ tipo: "quantidade", tickets_afetados: 1, tipo_cupon: 2 });
    const [filmes, setFilmes] = React.useState([]);
    const [data_canje, setData_canje] = React.useState([]);
    const [data_venta, setData_venta] = React.useState([]);
    const [filmesSelected, setFilmesSelected] = React.useState([]);

    const { RangePicker } = DatePicker;

    const handleDataset = (e) => {
        e.preventDefault();
        setDataset({ ...dataset, [e.target.name]: e.target.value })
    }

    const salvarCupom = event => {
        event.preventDefault();

        let token = localStorage.token;

        let data = dataset;

        data.data_inicio = moment(data_canje[0]).format("YYYY-MM-DD");
        data.data_fim = moment(data_canje[1]).format("YYYY-MM-DD");
        data.data_inicio_venta = moment(data_venta[0]).format("YYYY-MM-DD");
        data.data_fim_venta = moment(data_venta[1]).format("YYYY-MM-DD");

        api.post('/v2/promocao/criar-for-sale', data, {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            changeScreen()
        }).catch(function (error) {
            changeScreen()
            console.error(error)
        })
    }

    useEffect(() => {
        let token = localStorage.token;
        api.get('v1/filme/pesquisar', { headers: { Authorization: "Bearer " + token } }).then((response) => { setFilmes(response.data.data.filme) })
        if (dataC) {
            let data = {
                tipo_cupon: dataC.tipo_cupon,
                tipo: dataC.tipo,
                nome: dataC.nome,
                descripcion: dataC.descripcion ? dataC.descripcion : '',
                quantidade: dataC.quantidade,
                cant_limite_x_venta: dataC.cant_limite_x_venta,
                valor_venta: dataC.valor_venta,
                cant_limite_ventas: dataC.cant_limite_ventas,
                data_inicio: dataC.data_inicio,
                data_fim: dataC.data_fim,
                data_inicio_venta: dataC.data_fim_venta,
                data_fim_venta: dataC.data_fim_venta,
                filmes: dataC.filmes_ids,
                id: dataC.id
            }
            setData_canje([moment(dataC.data_inicio), moment(dataC.data_fim)])
            setData_venta([dataC.data_inicio_venta ? moment(dataC.data_inicio_venta) : null, dataC.data_fim_venta ? moment(dataC.data_fim_venta) : null])
            setDataset(data);
            setFilmesSelected(dataC.filmes_ids)
        }
    }, [dataC]);


    const onChangeFilmes = (event) => {
        if (event.includes(0)) {
            setDataset({ ...dataset, filmes: [0] })
            setFilmesSelected([0])
        } else {
            setDataset({ ...dataset, filmes: event })
            setFilmesSelected(event)
        }
    }

    return (
        <Box
            border={[
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "top"
                },
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "right"
                },
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "left"
                },
                {
                    color: "#F1F1F1",
                    size: "xlarge",
                    style: "solid",
                    side: "bottom"
                },
            ]}
            pad="medium"
            width="100%"
            height="100%"
            background={'white'}
        >
            <Box gridArea="nav">
                <Button sx={{
                    backgroundColor: 'transparent', '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'rgb(0 0 0 / 85%)',
                    }, color: 'rgb(0 0 0 / 85%)',
                    width: '10%',
                    height: 'auto',
                    justifyContent: 'center',
                    alignItem: 'center',
                    display: 'flex',
                    fontFamily: 'Poppins',

                }}
                    onClick={() => changeScreen()}
                >
                    <ArrowBackIcon /> Volver
                </Button>
                <Divider />
                <Box direction="column" width={'50%'}  >
                    <h1>Crear cupón para venta</h1>
                    <p>Nombre del cupón* </p>
                    <TextInput placeholder="Ex: 10 ENTRADAS A $25.000" name="nome" value={dataset.nome}
                        onChange={handleDataset}
                    />
                </Box>
                <Box direction="column" width={'50%'} >
                    <p>Descripción </p>
                    <textarea name="descripcion" value={dataset.descripcion} onChange={handleDataset} style={{ borderRadius: 5, borderColor: '#00000054' }}></textarea>
                </Box>
                <Box direction="row" gap="medium" style={{ marginTop: "1%" }}>

                    <Box direction="column">
                        <p>Precio cupón</p>
                        <TextInput placeholder="Valor" name="valor_venta" type="number" value={dataset.valor_venta} onChange={handleDataset} />
                    </Box>
                    <Box direction="column">
                        <p>Cantidad limite de ventas</p>
                        <TextInput placeholder="Cantidad limite" name="cant_limite_ventas" type="number" value={dataset.cant_limite_ventas} onChange={handleDataset} />
                    </Box>
                    <Box direction="column">
                        <p>Límite por cada venta</p>
                        <TextInput placeholder="Límite por cada venta" name="cant_limite_x_venta" type="number" value={dataset.cant_limite_x_venta} onChange={handleDataset} />
                    </Box>

                </Box>

                <Box direction="row" gap="medium" style={{ marginTop: "1%" }}>
                    <Box direction="column">
                        <p>Cantidad de entradas por cupón</p>
                        <TextInput placeholder="Cantidad" name="quantidade" type="number" value={dataset.quantidade} onChange={handleDataset} />
                    </Box>
                </Box>

                <h3 style={{ marginTop: "1%", marginBottom: "1%" }}> DISPONIBILIDAD CANJE</h3>
                <Box direction="row">
                    <Box direction="column" >
                        <p> Disponible entre:* </p>
                        <ConfigProvider locale={locale}>
                            <RangePicker value={data_canje} onChange={e => {setData_canje(e)}} />
                        </ConfigProvider>
                    </Box>
                </Box>

                <h3 style={{ marginTop: "1%", marginBottom: "1%" }}> DISPONIBILIDAD VENTA</h3>
                <Box direction="row">
                    <Box direction="column" >
                        <p> Disponible entre:* </p>
                        <ConfigProvider locale={locale}>
                            <RangePicker value={data_venta} name="venta" onChange={e => setData_venta(e)} />
                        </ConfigProvider>
                    </Box>
                </Box>

                <Space style={{ width: '30%', marginTop: "1%" }} direction="vertical">
                    <p> Peliculas permitidas:* </p>
                    <Select mode="multiple" style={{ width: '100%' }} placeholder="Seleccine las peliculas vigentes" value={filmesSelected} optionLabelProp="label" onChange={(opt) => onChangeFilmes(opt)}>
                        <Option value={0} label="TODAS">
                            <Space>TODAS</Space>
                        </Option>
                        {filmes.map((filme) => {
                            return (<Option value={filme.id} label={filme.titulo}><Space>{filme.titulo}</Space></Option>)
                        })}
                    </Select>
                </Space>

                <Button sx={{
                    backgroundColor: '#2b00ff', '&:hover': {
                        backgroundColor: '#2b00ff',
                        color: '#white',
                    }, color: 'white',
                    width: '10%',
                    height: 'auto',
                    justifyContent: 'center',
                    alignItem: 'center',
                    display: 'flex',
                    fontFamily: 'Poppins',
                    float: 'right',
                    marginLeft: '80%',
                    marginTop: '2%'
                }}
                    onClick={(e) => salvarCupom(e)}
                >
                    {dataset.id ? 'Guardar' : 'Crear'}
                </Button>
            </Box>
        </Box>
    );
};
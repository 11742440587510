import React from "react";
import './Ingresso.styles.css';

export const Ingresso = () => {
    
    let params = new URLSearchParams(window.location.search);

    return (
        <div className="teste">
            <iframe src={"https://api-cinesol.busticket.cl/ingresso/" + params.get('vid')} frameborder="0" style={{overflow:'hidden',height:'100%',width:'100%'}} />
        </div>
    );
};
import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { User } from "../../components/User";
import { api } from "../../services/api";
import "./Informations.styles.css";
import TicketIcon from "../../assets/ticket.svg";
import MoneyIcon from "../../assets/money.svg";
import { Box, IconButton, Modal, Pagination, Pagination as Pagination2, Stack, Tooltip } from "@mui/material";
import { ListarPedidosOnline } from "./ListarPedidosOnline";
import Button from "@mui/material/Button";
import GenerarCierre from "./../../components/GenerarCierre";
import { VisibilityOutlined, LocalPrintshopOutlined } from '@material-ui/icons';
import { Manifiesto } from "./Manifiesto/manifiesto";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '98%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll'
};


export const Informations = () => {
  const [pesquisado, setPesquisado] = useState(false);
  const [pesquisadoVentasCierre, setPesquisadoVentasCierre] = useState(false);

  const [vendedores, setVendedores] = useState([]);
  const [distribuidores, setDistribuidores] = useState([]);
  const [gerando, setGerando] = useState(false);
  const [generandoCierre, setGenerandoCierre] = useState(false);
  const [cierresDiarios, setCierresDiarios] = useState([]);
  const [ventasCierre, setVentasCierre] = useState([]);
  const [filmes, setFilmes] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [salas, setSalas] = useState([]);
  const [gerais, setGerais] = useState({});
  const [consultas, setConsultas] = useState({});
  const [funcionesF, setFuncionesF] = useState({});
  const [informes, setInformes] = useState({});
  const [filtrosVenta, setFiltrosVenta] = useState({});
  const [page, setPage] = useState(0);
  const [qtdPagesCierre, setQtdPagesCierre] = useState(0);
  const [qtdPagesVentasCierre, setQtdPagesVentasCierre] = useState(0);
  const [pageCierre, setPageCierre] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
    setQtdPagesVentasCierre(0);

  };

  const usuario = JSON.parse(localStorage.getItem("usuario"));

  const [papelId, setPapelId] = useState(usuario.papel_id);
  const [aba, setAba] = useState((usuario.papel_id == 1 || usuario.papel_id == 4) ? 0 : 3);

  const handleFilterData = (e) => {
    e.preventDefault();
    let filtros = { ...filterData, [e.target.name]: e.target.value };
    setFilterData(filtros);
    if (e.target.name === 'usuario_vendedor_id_cierre' || e.target.name === 'fecha_desde' || e.target.name === 'fecha_hasta') {
      getCierres(pageCierre, filtros)
    }
  };

  async function getFuncionesList() {
    let token = localStorage.token;
    const response = await api.get("/v1/filme/pesquisar_all", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setFilmes(response.data.data.filme);
    setEventos(response.data.data.eventos);
  }

  async function getSalas() {
    let token = localStorage.token;
    const response = await api.get("/v1/sala/pesquisar", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setSalas(response.data.data);
  }

  async function getVendedores() {
    let token = localStorage.token;
    const response = await api.get("/v1/relatorios/pesquisar-vendedores", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setVendedores(response.data.data);
  }

  async function getDistribuidores() {
    let token = localStorage.token;
    const response = await api.get("/v1/relatorios/pesquisar-distribuidores", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setDistribuidores(response.data.data);
  }

  async function getGerais() {
    let token = localStorage.token;
    const response = await api.get("/v1/relatorios/gerais", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setGerais(response.data.data);
  }

  async function getConsultas(pg) {
    setGerando(true);
    setPesquisado(false);

    let token = localStorage.token;
    let paramsUrl = "";
    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.data_inicio) {
      paramsUrl += "&data_inicio=" + filterData.data_inicio;
    }
    if (filterData.data_fim) {
      paramsUrl += "&data_fim=" + filterData.data_fim;
    }
    if (filterData.filme_id) {
      paramsUrl += "&filme_id=" + filterData.filme_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.sala_id) {
      paramsUrl += "&sala_id=" + filterData.sala_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.qualidade) {
      paramsUrl += "&qualidade=" + filterData.qualidade;
    }
    if (filterData.usuario_vendedor_id) {
      paramsUrl += "&usuario_vendedor_id=" + filterData.usuario_vendedor_id;
    }
    if (filterData.distribuidor) {
      paramsUrl += "&distribuidor=" + filterData.distribuidor;
    }
    if (filterData.forma_pagamento) {
      paramsUrl += "&forma_pagamento=" + filterData.forma_pagamento;
    }
    if (filterData.origem) {
      paramsUrl += "&origem=" + filterData.origem;
    }

    if (typeof pg != "undefined" && pg) {
      paramsUrl += "&page=" + pg;
    }

    const response = await api.get("/v1/relatorios/consultas?d=1" + paramsUrl, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setGerando(false);
    setPesquisado(true);
    setConsultas(response.data.data);
  }

  async function getConsultasPdf() {
    let paramsUrl = "";
    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.data_inicio) {
      paramsUrl += "&data_inicio=" + filterData.data_inicio;
    }
    if (filterData.data_fim) {
      paramsUrl += "&data_fim=" + filterData.data_fim;
    }
    if (filterData.filme_id) {
      paramsUrl += "&filme_id=" + filterData.filme_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.sala_id) {
      paramsUrl += "&sala_id=" + filterData.sala_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.qualidade) {
      paramsUrl += "&qualidade=" + filterData.qualidade;
    }
    if (filterData.usuario_vendedor_id) {
      paramsUrl += "&usuario_vendedor_id=" + filterData.usuario_vendedor_id;
    }
    if (filterData.distribuidor) {
      paramsUrl += "&distribuidor=" + filterData.distribuidor;
    }
    if (filterData.forma_pagamento) {
      paramsUrl += "&forma_pagamento=" + filterData.forma_pagamento;
    }
    if (filterData.origem) {
      paramsUrl += "&origem=" + filterData.origem;
    }

    let url = "https://api-cinesol.busticket.cl/relatorios/consultas-pdf?d=1" + paramsUrl;
    window.open(url, "_blank");
  }

  async function getConsultasExcel() {
    let paramsUrl = "";
    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.data_inicio) {
      paramsUrl += "&data_inicio=" + filterData.data_inicio;
    }
    if (filterData.data_fim) {
      paramsUrl += "&data_fim=" + filterData.data_fim;
    }
    if (filterData.filme_id) {
      paramsUrl += "&filme_id=" + filterData.filme_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.sala_id) {
      paramsUrl += "&sala_id=" + filterData.sala_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.qualidade) {
      paramsUrl += "&qualidade=" + filterData.qualidade;
    }
    if (filterData.usuario_vendedor_id) {
      paramsUrl += "&usuario_vendedor_id=" + filterData.usuario_vendedor_id;
    }
    if (filterData.distribuidor) {
      paramsUrl += "&distribuidor=" + filterData.distribuidor;
    }
    if (filterData.forma_pagamento) {
      paramsUrl += "&forma_pagamento=" + filterData.forma_pagamento;
    }
    if (filterData.origem) {
      paramsUrl += "&origem=" + filterData.origem;
    }

    let url = "https://api-cinesol.busticket.cl/relatorios/consultas-excel?d=1" + paramsUrl;

    window.open(url, "_blank");
  }

  async function getInformes() {
    setGerando(true);
    setPesquisado(false);

    let token = localStorage.token;
    let paramsUrl = "";
    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.data_inicio) {
      paramsUrl += "&data_inicio=" + filterData.data_inicio;
    }
    if (filterData.data_fim) {
      paramsUrl += "&data_fim=" + filterData.data_fim;
    }
    if (filterData.filme_id) {
      paramsUrl += "&filme_id=" + filterData.filme_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.sala_id) {
      paramsUrl += "&sala_id=" + filterData.sala_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.qualidade) {
      paramsUrl += "&qualidade=" + filterData.qualidade;
    }
    if (filterData.usuario_vendedor_id) {
      paramsUrl += "&usuario_vendedor_id=" + filterData.usuario_vendedor_id;
    }
    if (filterData.distribuidor) {
      paramsUrl += "&distribuidor=" + filterData.distribuidor;
    }
    if (filterData.forma_pagamento) {
      paramsUrl += "&forma_pagamento=" + filterData.forma_pagamento;
    }
    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.origem) {
      paramsUrl += "&origem=" + filterData.origem;
    }

    const response = await api.get("/v1/relatorios/informes?d=1" + paramsUrl, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setGerando(false);
    setPesquisado(true);
    setInformes(response.data.data);
  }

  async function getInformesPdf() {
    let paramsUrl = "";

    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.data_inicio) {
      paramsUrl += "&data_inicio=" + filterData.data_inicio;
    }
    if (filterData.data_fim) {
      paramsUrl += "&data_fim=" + filterData.data_fim;
    }
    if (filterData.filme_id) {
      paramsUrl += "&filme_id=" + filterData.filme_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.sala_id) {
      paramsUrl += "&sala_id=" + filterData.sala_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.qualidade) {
      paramsUrl += "&qualidade=" + filterData.qualidade;
    }
    if (filterData.usuario_vendedor_id) {
      paramsUrl += "&usuario_vendedor_id=" + filterData.usuario_vendedor_id;
    }
    if (filterData.distribuidor) {
      paramsUrl += "&distribuidor=" + filterData.distribuidor;
    }
    if (filterData.forma_pagamento) {
      paramsUrl += "&forma_pagamento=" + filterData.forma_pagamento;
    }
    if (filterData.origem) {
      paramsUrl += "&origem=" + filterData.origem;
    }

    let url =
      "https://api-cinesol.busticket.cl/relatorios/informes-pdf?d=1" +
      paramsUrl;
    window.open(url, "_blank");
  }

  async function getFunciones() {
    setGerando(true);
    setPesquisado(false);

    let token = localStorage.token;
    let paramsUrl = "";

    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.data) {
      paramsUrl += "&data=" + filterData.data;
    }
    if (filterData.filme_id) {
      paramsUrl += "&filme_id=" + filterData.filme_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.sala_id) {
      paramsUrl += "&sala_id=" + filterData.sala_id;
    }
    if (filterData.qualidade) {
      paramsUrl += "&qualidade=" + filterData.qualidade;
    }
    if (filterData.usuario_vendedor_id) {
      paramsUrl += "&usuario_vendedor_id=" + filterData.usuario_vendedor_id;
    }
    if (filterData.distribuidor) {
      paramsUrl += "&distribuidor=" + filterData.distribuidor;
    }
    if (filterData.forma_pagamento) {
      paramsUrl += "&forma_pagamento=" + filterData.forma_pagamento;
    }
    if (filterData.origem) {
      paramsUrl += "&origem=" + filterData.origem;
    }

    const response = await api.get("/v1/relatorios/funciones?d=1" + paramsUrl, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setGerando(false);
    setPesquisado(true);

    setFuncionesF(response.data.data);
  }

  async function getFuncionesPdf() {
    let paramsUrl = "";
    if (filterData.tipo_ingresso) {
      paramsUrl += "&tipo_ingresso=" + filterData.tipo_ingresso;
    }
    if (filterData.data) {
      paramsUrl += "&data=" + filterData.data;
    }
    if (filterData.filme_id) {
      paramsUrl += "&filme_id=" + filterData.filme_id;
    }
    if (filterData.audio) {
      paramsUrl += "&audio=" + filterData.audio;
    }
    if (filterData.sala_id) {
      paramsUrl += "&sala_id=" + filterData.sala_id;
    }
    if (filterData.qualidade) {
      paramsUrl += "&qualidade=" + filterData.qualidade;
    }
    if (filterData.usuario_vendedor_id) {
      paramsUrl += "&usuario_vendedor_id=" + filterData.usuario_vendedor_id;
    }
    if (filterData.distribuidor) {
      paramsUrl += "&distribuidor=" + filterData.distribuidor;
    }
    if (filterData.forma_pagamento) {
      paramsUrl += "&forma_pagamento=" + filterData.forma_pagamento;
    }
    if (filterData.origem) {
      paramsUrl += "&origem=" + filterData.origem;
    }

    let url =
      "https://api-cinesol.busticket.cl/relatorios/funciones-pdf?d=1" +
      paramsUrl;
    window.open(url, "_blank");
  }

  const changePage = (page) => {
    setPage(page);
    getConsultas(page);
  };

  async function getCierres(pg, params = {}) {
    setGerando(true);
    setPesquisado(false);

    let token = localStorage.token;
    let paramsSet = {
      page: pg,
      usuario_vendedor_id: (params.usuario_vendedor_id_cierre) ? params.usuario_vendedor_id_cierre : null,
      fecha_desde: (params.fecha_desde) ? params.fecha_desde : null,
      fecha_hasta: (params.fecha_hasta) ? params.fecha_hasta : null,
    }

    const resp = await api.post("/v1/cierre_caja_diario/data", paramsSet, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setCierresDiarios(resp.data.data.cierres);
    setQtdPagesCierre(resp.data.data.qtd_pages);
    setGerando(false);
    setPesquisado(true);
  }

  async function getVentasCierre(pg, filtrosVenta = {}) {
    setPesquisadoVentasCierre(false);

    let token = localStorage.token;
    let paramsSet = { ...filtrosVenta, page: pg }

    const resp = await api.post("/v1/cierre_caja_diario/ventas_cierre/data", paramsSet, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setVentasCierre(resp.data.data.ventasCierre);
    setQtdPagesVentasCierre(resp.data.data.qtd_pages);
    setPesquisadoVentasCierre(true);
  }

  async function getVentasCierreExcel() {
    let paramsUrl = "usuario_vendedor_id=" + filtrosVenta.usuario_vendedor_id + "&fecha=" + filtrosVenta.fecha;
    let url = "https://api-cinesol.busticket.cl/cierre_caja_diario/ventas_cierre_excel?" + paramsUrl;
    window.open(url, "_blank");
  }

  const changeAba = async (aba) => {
    setAba(aba);
    setPesquisado(false);

    switch (aba) {
      case 0:
        getGerais();
        break;
      case 3:
        getCierres(0);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getVendedores();
    getDistribuidores();
    getFuncionesList();
    getSalas();
    getGerais();
  }, []);

  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />

      <div className="container-main-pai">
        <div className="abas" style={{ marginBottom: 10 }}>
          {(papelId == 1 || papelId == 4) ? (
            <>
              <button
                className={"aba " + (aba == 0 ? "active" : "")}
                onClick={() => changeAba(0)}
              >
                Resumen
              </button>
              <button
                className={"aba " + (aba == 1 ? "active" : "")}
                onClick={() => changeAba(1)}
              >
                Consultas
              </button>
              <button
                className={"aba " + (aba == 2 ? "active" : "")}
                onClick={() => changeAba(2)}
              >
                Informe
              </button>
              <button
                className={"aba " + (aba == 4 ? "active" : "")}
                onClick={() => changeAba(4)}
              >
                Funciones
              </button>
              <button
                className={"aba " + (aba == 3 ? "active" : "")}
                onClick={() => changeAba(3)}
              >
                Cierre de caja
              </button>

              <button
                className={"aba " + (aba == 5 ? "active" : "")}
                onClick={() => changeAba(5)}
              >
                Ventas online
              </button>
              <button
                className={"aba " + (aba == 6 ? "active" : "")}
                onClick={() => changeAba(6)}
              >
                Manifiesto
              </button>
            </>
          ) : papelId == 2 ? (
            <>
              <button
                className={"aba " + (aba == 3 ? "active" : "")}
                onClick={() => changeAba(3)}
              >
                Cierre de caja
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      {aba == 0 ? (
        <div className="container-main-pai">
          <h4>Muestra las ventas del día y las ventas totales del mes en curso</h4>
          <h1 className="title-informations">Resumen</h1>

          <div className="relatorio-vendas-dia">
            {gerais.resumo ? (
              <>
                <div className="titulos-box">
                  <div className="titulo-box">
                    <h2>Ventas del día</h2>
                  </div>
                  <div className="titulo-box">
                    <h2>Ventas del mes</h2>
                  </div>
                </div>
                <div className="tickets-boxes">
                  <div className="ticket-box blue">
                    <div className="infos-left">
                      <h2>{gerais.resumo.dia_qtd}</h2>
                      <p>Tickets</p>
                    </div>
                    <div className="icon-box">
                      <img src={TicketIcon} alt="" />
                    </div>
                  </div>

                  <div className="ticket-box green">
                    <div className="infos-left">
                      <h2>{gerais.resumo.dia_vendas}</h2>
                      <p>Ventas</p>
                    </div>
                    <div className="icon-box">
                      <img src={MoneyIcon} alt="" />
                    </div>
                  </div>

                  <div className="divisor"></div>

                  <div className="ticket-box blue">
                    <div className="infos-left">
                      <h2>{gerais.resumo.mes_qtd}</h2>
                      <p>Tickets</p>
                    </div>
                    <div className="icon-box">
                      <img src={TicketIcon} alt="" />
                    </div>
                  </div>

                  <div className="ticket-box green">
                    <div className="infos-left">
                      <h2>{gerais.resumo.mes_vendas}</h2>
                      <p>Ventas</p>
                    </div>
                    <div className="icon-box">
                      <img src={MoneyIcon} alt="" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <h1 className="title-informations">Consulta</h1>

          <div className="relatorio-vendas-dia">
            {gerais.consulta ? (
              <>
                <div className="titulos-box">
                  <div className="titulo-box">
                    <h2>Ventas del día</h2>
                  </div>
                  <div className="titulo-box">
                    <h2>Ventas del mes</h2>
                  </div>
                </div>
                <div className="tickets-boxes">
                  <div className="ticket-box blue-rounded">
                    <div className="infos-left">
                      <h2>Nº {gerais.consulta.qtd_presencial_dia}</h2>
                      <p>Presencial</p>
                    </div>
                    <div className="icon-box">
                      <h2>{gerais.consulta.valor_presencial_dia}</h2>
                    </div>
                  </div>
                  <div className="ticket-box blue-rounded">
                    <div className="infos-left">
                      <h2>Nº {gerais.consulta.qtd_online_dia}</h2>
                      <p>Online</p>
                    </div>
                    <div className="icon-box">
                      <h2>{gerais.consulta.valor_online_dia}</h2>
                    </div>
                  </div>

                  <div className="divisor"></div>
                  <div className="ticket-box blue-rounded">
                    <div className="infos-left">
                      <h2>Nº {gerais.consulta.qtd_presencial_mes}</h2>
                      <p>Presencial</p>
                    </div>
                    <div className="icon-box">
                      <h2>{gerais.consulta.valor_presencial_mes}</h2>
                    </div>
                  </div>
                  <div className="ticket-box blue-rounded">
                    <div className="infos-left">
                      <h2>Nº {gerais.consulta.qtd_online_mes}</h2>
                      <p>Online</p>
                    </div>
                    <div className="icon-box">
                      <h2>{gerais.consulta.valor_online_mes}</h2>
                    </div>
                  </div>
                </div>
                <br />
                <div className="tickets-boxes">
                  <div className="ticket-box blue-rounded">
                    <div className="infos-left">
                      <h2>Nº {gerais.consulta.qtd_app_dia}</h2>
                      <p>App</p>
                    </div>
                    <div className="icon-box">
                      <h2>{gerais.consulta.valor_app_dia}</h2>
                    </div>
                  </div>
                  <div className="ticket-box blue-rounded" style={{ backgroundColor: 'transparent' }}>
                    
                  </div>
                  <div className="divisor"></div>
                  <div className="ticket-box blue-rounded">
                    <div className="infos-left">
                      <h2>Nº {gerais.consulta.qtd_app_mes}</h2>
                      <p>App</p>
                    </div>
                    <div className="icon-box">
                      <h2>{gerais.consulta.valor_app_mes}</h2>
                    </div>
                  </div>
                  <div className="ticket-box blue-rounded" style={{ backgroundColor: 'transparent' }}>
                    
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="flex-consultas">
            <div className="item-flex">
              <h1 className="title-informations">Vendedor</h1>
              <div className="relatorio-vendas-dia">
                <table>
                  <tr>
                    <th>Tipo</th>
                    <th>Monto</th>
                  </tr>
                  {gerais.vendedores && gerais.vendedores.length ? (
                    gerais.vendedores.map((v) => (
                      <tr>
                        <td>{v.vendedor}</td>
                        <td className="text-right">{v.valor}</td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                  <tr>
                    <th>Total</th>
                    <td className="text-right">{gerais.total_vendedores}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="item-flex">
              <h1 className="title-informations">Forma de pago</h1>
              <div className="relatorio-vendas-dia">
                <table>
                  <tr>
                    <th>Tipo</th>
                    <th>Monto</th>
                  </tr>
                  {gerais.formas ? (
                    Object.keys(gerais.formas).map((f) => (
                      <tr>
                        <td>{f}</td>
                        <td className="text-right">{gerais.formas[f]}</td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                  <tr>
                    <th>Total</th>
                    <td className="text-right">{gerais.total_formas}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="item-flex">
              <h1 className="title-informations">Funciones</h1>
              <div className="relatorio-vendas-dia">
                <table>
                  <tr>
                    <th>Nombre</th>
                    <th>Cantidad</th>
                    <th>Monto</th>
                  </tr>
                  {gerais.filmes && gerais.filmes.length ? (
                    gerais.filmes.map((f) => (
                      <tr>
                        <td>{f.filme}</td>
                        <td className="text-center">{f.qtd}</td>
                        <td className="text-right">{f.valor}</td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                  <tr>
                    <th>Total</th>
                    <td className="text-center">{gerais.total_filmes_qtd}</td>
                    <td className="text-right">{gerais.total_filmes}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {aba == 1 || aba == 2 || aba == 4 ? (
        <div className="container-main-pai">
          {aba == 1 && (
            <h4>Muestra el detalle de cada venta emitida de acuerdo a un período de tiempo</h4>
          )}
          {aba == 2 && (
            <h4>Muestra la estadística de ventas de un periodo separado por película y audio. No muestra la ocupación de las salas</h4>
          )}
          {aba == 4 && (
            <h4>Este informe muestra la venta total de una función específica, sin importar cuando se han realizado las ventas.</h4>
          )}

          <div className="filtros">
            {aba == 4 ? (
              <div className="filtro-field">
                <label style={{ marginRight: '5px' }}>Fecha</label>
                <input
                  type="date"
                  name="data"
                  onChange={handleFilterData}
                />
              </div>
            ) : (
              <>
                <div className="filtro-field">
                  <p>Desde</p>
                  <input
                    type="date"
                    name="data_inicio"
                    onChange={handleFilterData}
                  />
                </div>
                <div className="filtro-field">
                  <p>Hasta</p>
                  <input type="date" name="data_fim" onChange={handleFilterData} />
                </div>
              </>
            )}

          </div>
          <div className="filtros">
            <div className="filtro-field">
              <p>Tipo de función</p>
              <select name="tipo_ingresso" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                <option value="0">Pelicula</option>
                <option value="1">Evento</option>
              </select>
            </div>
            <div className="filtro-field">
              <p>Función</p>
              <select name="filme_id" id="funcion_id" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                {filmes && filmes.map((filme) => (
                  <option value={'f-' + filme.id}>{filme.titulo}</option>
                ))}
                {eventos && eventos.map((evento) => (
                  <option value={'e-' + evento.id}>{evento.titulo}</option>
                ))}
              </select>
            </div>
            <div className="filtro-field">
              <p>Sala</p>
              <select name="sala_id" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                {salas.map((item) => (
                  <option value={item.id}>{item.nome}</option>
                ))}
              </select>
            </div>
            <div className="filtro-field">
              <p>Audio</p>
              <select name="audio" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                <option value="dub">Doblado</option>
                <option value="leg">Subtitulado</option>
              </select>
            </div>
            <div className="filtro-field">
              <p>Calidad</p>
              <select name="calidad" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                <option value="3D">3D</option>
                <option value="2D">2D</option>
                <option value="XD">XD</option>
                <option value="Premium">Premium</option>
              </select>
            </div>
          </div>
          <div className="filtros">
            <div className="filtro-field">
              <p>Vendedor</p>
              <select name="usuario_vendedor_id" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                {vendedores.map((item) => (
                  <option value={item.id}>{item.nome}</option>
                ))}
              </select>
            </div>
            <div className="filtro-field">
              <p>Distribuidor</p>
              <select name="distribuidor" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                {distribuidores.map((item) => (
                  <option value={item.distribuidor}>{item.distribuidor}</option>
                ))}
              </select>
            </div>
            <div className="filtro-field">
              <p>
                Forma <br />
                de pago
              </p>
              <select name="forma_pagamento" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                <option value="Efectivo">Efectivo</option>
                <option value="Tarjeta de d">Tarjeta de débito</option>
                <option value="Tarjeta de c">Tarjeta de crédito</option>
              </select>
            </div>
            <div className="filtro-field">
              <p>
                Canal
              </p>
              <select name="origem" onChange={handleFilterData}>
                <option value="">Seleccione</option>
                <option value="presencial">Presencial</option>
                <option value="online">Online</option>
                <option value="app">App</option>
              </select>
            </div>
          </div>
          <div className="filtros">
            {aba == 1 ? (
              <button className="btn-generar" onClick={() => getConsultas()}>
                {gerando ? "Aguarde" : "Generar"}
              </button>
            ) : (
              <></>
            )}

            {aba == 1 && pesquisado ? (
              <button
                className="btn-generar-pdf"
                onClick={() => getConsultasPdf()}
              >
                {gerando ? "Aguarde" : "Exportar PDF"}
              </button>
            ) : (
              <></>
            )}

            {aba == 1 && pesquisado ? (
              <button
                className="btn-generar-excel"
                onClick={() => getConsultasExcel()}
              >
                {gerando ? "Aguarde" : "Exportar Excel"}
              </button>
            ) : (
              <></>
            )}

            {aba == 2 ? (
              <button className="btn-generar" onClick={() => getInformes()}>
                {gerando ? "Aguarde" : "Generar"}
              </button>
            ) : (
              <></>
            )}

            {aba == 2 && pesquisado ? (
              <button
                className="btn-generar-pdf"
                onClick={() => getInformesPdf()}
              >
                {gerando ? "Aguarde" : "Exportar PDF"}
              </button>
            ) : (
              <></>
            )}

            {aba == 4 ? (
              <button className="btn-generar" onClick={() => getFunciones()}>
                {gerando ? "Aguarde" : "Generar"}
              </button>
            ) : (
              <></>
            )}

            {aba == 4 && pesquisado ? (
              <button
                className="btn-generar-pdf"
                onClick={() => getFuncionesPdf()}
              >
                {gerando ? "Aguarde" : "Exportar PDF"}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {aba == 1 && (
        <div className="container-main-pai">
          <div className="tickets-boxes center">
            <div className="ticket-box blue">
              <div className="infos-left">
                <h2>{consultas.vendas_qtd}</h2>
                <p>Cantidad Entradas</p>
              </div>
              <div className="icon-box">
                <img src={TicketIcon} alt="" />
              </div>
            </div>

            <div className="ticket-box green">
              <div className="infos-left">
                <h2>{consultas.vendas_valor ? consultas.vendas_valor.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0] : ''}</h2>
                <p>Total Venta</p>
              </div>
              <div className="icon-box">
                <img src={MoneyIcon} alt="" />
              </div>
            </div>
          </div>

          <div className="box-tbl-vendas">
            <table className="tbl-vendas">
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Vendedor</th>
                <th>Fecha de venta</th>
                <th>Hora de venta</th>
                <th>Monto total</th>
                <th>Cantidad de entradas</th>
                <th>Función</th>
                <th style={{ width: "50px" }}>Sala</th>
                <th>Audio</th>
                <th>Calidad</th>
                <th>Fecha Función</th>
                <th>Hora Función</th>
                <th>Forma de pago</th>
                <th>Presencial u Online</th>
                <th>Distribuidor</th>
              </tr>
              {pesquisado && consultas.vendas &&
                consultas.vendas.map((v, k) => (
                  <tr>
                    <td>{50 * page + k + 1}</td>
                    <td>{v.id}</td>
                    <td className="text-center">{v.vendedor}</td>
                    <td className="text-center">{v.fecha_venta}</td>
                    <td className="text-center">{v.hora_venta}</td>
                    <td className="text-center">{v.valor.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                    <td className="text-center">{v.cantidad}</td>
                    <td>{v.funcion}</td>
                    <td>{v.sala}</td>
                    <td>{v.audio}</td>
                    <td className="text-center">{v.calidad}</td>
                    <td className="text-center">{v.fecha_funcion}</td>
                    <td className="text-center">{v.hora_funcion}</td>
                    <td className="text-center">{v.json_formas_pagamento != null && v.json_formas_pagamento != '' ? JSON.parse(v.json_formas_pagamento)[0].forma_pagamento : ''}</td>
                    <td>{v.origem}</td>
                    <td>{v.distribuidor}</td>
                  </tr>
                ))}
            </table>
            <Pagination
              onChange={(obj, pag) => changePage(pag - 1)}
              count={consultas.qtd_pages}
              size="large"
            />
          </div>
        </div>
      )}

      {aba == 2 && pesquisado ? (
        <div className="container-main-pai">
          <h1 className="title-informations">Películas</h1>
          <div className="box-tables">
            <div className="box-table">
              <table>
                <tr>
                  <th>Nombre</th>
                  <th>Cantidad</th>
                  <th>Monto</th>
                </tr>
                {informes.filmes &&
                  informes.filmes.map((f) => (
                    <>
                      {f.qtd > 0 ? (
                        <>
                          <tr>
                            <th>{f.titulo}</th>
                            <td>Nº {f.qtd}</td>
                            <td>{f.vendas}</td>
                          </tr>
                          {f.qtd_leg > 0 || f.qtd_dub > 0 ? (
                            <tr>
                              <td colSpan="3">Lenguaje (Audio)</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {f.qtd_leg > 0 ? (
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                Subtitulada
                              </td>
                              <td>Nº {f.qtd_leg}</td>
                              <td>{f.vendas_leg}</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {f.qtd_dub > 0 ? (
                            <tr>
                              <td style={{ textAlign: "right" }}>Doblada</td>
                              <td>Nº {f.qtd_dub}</td>
                              <td>{f.vendas_dub}</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              -------------------------
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
              </table>
            </div>
          </div>

          <h1 className="title-informations">Eventos</h1>
          <div className="box-tables">
            <div className="box-table">
              <table>
                <tr>
                  <th>Nombre</th>
                  <th>Cantidad</th>
                  <th>Monto</th>
                </tr>
                {informes.eventos &&
                  informes.eventos.map((evento) => (
                    <>
                      <tr>
                        <th>{evento.titulo}</th>
                        <td>Nº {evento.qtd}</td>
                        <td>{evento.vendas}</td>
                      </tr>
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          -------------------------
                        </td>
                      </tr>
                    </>
                  ))}
              </table>
            </div>
          </div>

          <h1 className="title-informations">Cupones</h1>
          <div className="box-tables">
            <div className="box-table">
              <table>
                <tr>
                  <th>Nombre</th>
                  <th>Cantidad</th>
                  <th>Monto</th>
                </tr>
                {informes.cupones &&
                  informes.cupones.map((cupon) => (
                    <>
                      <tr>
                        <th>{cupon.titulo}</th>
                        <td>Nº {cupon.qtd}</td>
                        <td>{cupon.vendas}</td>
                      </tr>
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          -------------------------
                        </td>
                      </tr>
                    </>
                  ))}
              </table>
            </div>
          </div>

          <h1 className="title-informations">Vendedor</h1>
          <div className="box-table">
            <table>
              <tr>
                <th>Tipo</th>
                <th>Cantidad</th>
                <th>Monto</th>
              </tr>
              {informes.vendedores &&
                informes.vendedores.map((v) => (
                  <>
                    {v.qtd > 0 ? (
                      <tr>
                        <td>{v.vendedor}</td>
                        <td>Nº {v.qtd}</td>
                        <td>{v.valor}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
            </table>
          </div>

          <h1 className="title-informations">Distribuidor</h1>
          <div className="box-table">
            <table>
              <tr>
                <th>Tipo</th>
                <th>Cantidad</th>
                <th>Monto</th>
              </tr>
              {informes.distribuidores &&
                informes.distribuidores.map((d) => (
                  <>
                    {d.qtd > 0 ? (
                      <tr>
                        <td>{d.nome}</td>
                        <td>Nº {d.qtd}</td>
                        <td>{d.valor}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
            </table>
          </div>

        </div>
      ) : (
        <></>
      )}

      {aba == 3 ? (
        <>
          {generandoCierre == true ? (
            <GenerarCierre
              vendedores={vendedores}
              handleVolver={() => {
                setGenerandoCierre(false);
                getCierres(0)
              }}
            />
          ) : (
            <div className="container-main-pai">
              {(papelId == 1 || papelId == 4) && (
                <div>
                  <center>
                    <Button variant="contained" style={{
                      width: '150px',
                      height: '40px',
                      backgroundColor: '#00C455',
                      border: '1px solid #00C455',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize'
                    }} onClick={() => setGenerandoCierre(true)}>Generar cierre</Button>
                  </center>
                </div>

              )}
              <h1 className="title-informations">Cierre de caja</h1>
              <div className="filtros">
                <div className="filtro-field">
                  <label style={{ marginRight: '5px' }}>Vendedor</label>
                  <select name="usuario_vendedor_id_cierre" onChange={handleFilterData}>
                    <option value="">Seleccione</option>
                    {vendedores.map((item) => (
                      <option value={item.id}>{item.nome}</option>
                    ))}
                  </select>
                </div>
                <div className="filtro-field">
                  <label style={{ marginRight: '5px' }}>Fecha desde</label>
                  <input type="date" name="fecha_desde" onChange={handleFilterData} />
                </div>
                <div className="filtro-field">
                  <label style={{ marginRight: '5px' }}>Fecha hasta</label>
                  <input type="date" name="fecha_hasta" onChange={handleFilterData} />
                </div>
              </div>
              <div className="relatorio-vendas-dia" style={{ padding: 0 }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "right" }}>Total ventas</th>
                      <th style={{ textAlign: "right" }}>Valor rendir</th>
                      <th style={{ textAlign: "right" }}>Efectivo real</th>
                      <th style={{ textAlign: "right" }}>Crédito</th>
                      <th style={{ textAlign: "right" }}>Débito</th>
                      <th style={{ textAlign: "right" }}>Recaudación total</th>
                      <th style={{ textAlign: "right" }}>Saldo</th>
                      <th style={{ textAlign: "center", width: '110px' }}>Fecha cierre</th>
                      <th style={{ textAlign: "center", width: '110px' }}>Fecha día del cierre</th>
                      <th>Vendedor</th>
                      <th>Admin</th>
                      <th style={{ textAlign: "center" }}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pesquisado && cierresDiarios.map((cierre) => (
                      <tr key={cierre.id}>
                        <td style={{ textAlign: "right" }}>{cierre.total_ventas}</td>
                        <td style={{ textAlign: "right" }}>{cierre.valor_rendir.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                        <td style={{ textAlign: "right" }}>{cierre.efectivo_real.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                        <td style={{ textAlign: "right" }}>{cierre.credito.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                        <td style={{ textAlign: "right" }}>{cierre.debito.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                        <td style={{ textAlign: "right" }}>{cierre.recaudo_total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                        <td style={{ textAlign: "right" }}>{cierre.saldo.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).split(',')[0]}</td>
                        <td style={{ textAlign: "center" }}>{cierre.fecha_cierre}</td>
                        <td style={{ textAlign: "center" }}>{cierre.fecha_ejecucion_cierre}</td>
                        <td>{cierre.vendedor ? cierre.vendedor.nome : ''}</td>
                        <td>{cierre.admin ? cierre.admin.nome : ''}</td>
                        <td style={{ textAlign: "center" }}>
                          <Stack direction="row" spacing={1}>
                            <Tooltip title="Ver detalles">
                              <IconButton color="success" onClick={e => {
                                setFiltrosVenta({ fecha: cierre.fecha_cierre, usuario_vendedor_id: cierre.usuario_vendedor_id });
                                setOpenModal(true);
                                getVentasCierre(0, { fecha: cierre.fecha_cierre, usuario_vendedor_id: cierre.usuario_vendedor_id })
                              }}>
                                <VisibilityOutlined />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Imprimir">
                              <a href={"https://api-cinesol.busticket.cl/relatorios/vendas-vendedor/" + cierre.usuario_vendedor_id + "?data=" + cierre.fecha_cierre} target="_blank">
                                <IconButton>
                                  <LocalPrintshopOutlined />
                                </IconButton>
                              </a>
                            </Tooltip>
                          </Stack>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination key={"listaCierres"} id="listaCierresId"
                  onChange={(obj, pag) => getCierres(pag - 1, filterData)}
                  count={qtdPagesCierre}
                  size="large"
                />
              </div>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
              >
                <Box sx={style}>
                  <div className="relatorio-ventas-cierre">
                    <button className="btn-generar-excel" style={{ marginBottom: '5px' }} onClick={() => getVentasCierreExcel()}>
                      {gerando ? "Aguarde" : "Exportar Excel"}
                    </button>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "right" }}>ID</th>
                          <th>Vendedor</th>
                          <th style={{ textAlign: "center", width: '110px' }}>Fecha de venta</th>
                          <th style={{ textAlign: "right" }}>Monto total</th>
                          <th style={{ textAlign: "right" }}>Cantidad de entradas</th>
                          <th>Función</th>
                          <th>Sala</th>
                          <th>Audio</th>
                          <th style={{ textAlign: "center" }}>Calidad</th>
                          <th style={{ textAlign: "center", width: '110px' }}>Fecha función</th>
                          <th>Forma de pago</th>
                          <th>Tipo de pago</th>
                          <th>Distribuidor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pesquisadoVentasCierre && ventasCierre.map((ventaCierre) => (
                          <tr key={ventaCierre.id}>
                            <td style={{ textAlign: "right" }}>{ventaCierre.id}</td>
                            <td>{ventaCierre.vendedor}</td>
                            <td style={{ textAlign: "center" }}>{ventaCierre.fecha_venta}</td>
                            <td style={{ textAlign: "right" }}>{ventaCierre.monto_total}</td>
                            <td style={{ textAlign: "right" }}>{ventaCierre.cantidad_entradas}</td>
                            <td>{ventaCierre.funcion}</td>
                            <td>{ventaCierre.sala}</td>
                            <td>{ventaCierre.audio}</td>
                            <td style={{ textAlign: "center" }}>{ventaCierre.calidad}</td>
                            <td style={{ textAlign: "center" }}>{ventaCierre.fecha_funcion}</td>
                            <td>{JSON.parse(ventaCierre.forma_pago)[0].forma_pagamento}</td>
                            <td>{ventaCierre.tipo_pago}</td>
                            <td>{ventaCierre.distribuidor}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination2 key={"ventasCierre"} id="ventasCierreId"
                      onChange={(obj, pag1) => getVentasCierre(pag1 - 1, filtrosVenta)}
                      count={qtdPagesVentasCierre}
                      size="large"
                    />
                  </div>

                </Box>
              </Modal>
            </div>

          )}
        </>
      ) : (
        <></>
      )}

      {aba == 4 && pesquisado ? (
        <div className="container-main-pai">
          <h1 className="title-informations">Funciones</h1>

          <div className="box-tables table-center">
            <div className="box-table table-60">
              <table>
                <tr>
                  <th>Película</th>
                  <th style={{ textAlign: "center" }}>Hora función</th>
                  <th style={{ textAlign: "center", width: '75px' }}>Sala</th>
                  <th style={{ textAlign: "center" }}>Audio</th>
                  <th style={{ textAlign: "center" }}>Cantidad de ingresos</th>
                  <th style={{ textAlign: "center" }}>Monto total</th>
                </tr>
                {funcionesF &&
                  funcionesF.filmes &&
                  funcionesF.filmes.map((f, kF) => (
                    <>
                      {f.horarios &&
                        f.horarios.map((h, kH) => (
                          <>
                            <tr>
                              <td>{kH == 0 ? f.titulo : ""}</td>
                              <td style={{ textAlign: "center" }}>{h.horario_t}</td>
                              <td style={{ textAlign: "center" }}>{h.sala}</td>
                              <td style={{ textAlign: "center" }}>{h.audio === 'dub' ? 'dob' : h.audio}</td>
                              <td style={{ textAlign: "center" }}>{h.qtd}</td>
                              <td style={{ textAlign: "center" }}>{h.total}</td>
                            </tr>
                          </>
                        ))}
                      {f.horarios.length ? (
                        <>
                          <tr>
                            <td colspan="4"> <b>Monto total {f.titulo}</b></td>
                            <td style={{ textAlign: "center" }}> <b>{f.qtd}</b></td>
                            <td style={{ textAlign: "center" }}><b>{f.valor}</b></td>
                          </tr>
                          <tr>
                            <td colSpan={6} style={{ textAlign: "center" }}>
                              ---------------------------------------------------------------------
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
              </table>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {aba === 5 && <ListarPedidosOnline />}

      {aba == 6 && <Manifiesto />}
    </div>
  );
};

import React , { useEffect, useRef, useState }from "react";
import { Navbar } from '../../components/Navbar';
import Photocapa from "../../assets/photo-capa.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { User } from "../../components/User";
import { api } from '../../services/api';
import './RelatorioVendedor.styles.css';
export const RelatorioVendedor = () => {

    const _refDatePicker = useRef(null);
    const [dataSelected, setDataSelected] = useState();
    const [relatorios, setRelatorios] = useState({});

    const params = new URLSearchParams(window.location.search);
    
    async function getRelatorios(data){

        let token = localStorage.token;       
        const response = await api.get('/v1/relatorios/vendas-vendedor/' + params.get('id') + '?data=' + data, {
           headers:{
                Authorization: "Bearer " + token
           }
       })

       setRelatorios(response.data.data)
    }

    const updateHandlerData = data => {
        
        setDataSelected(formatData(data));
        getRelatorios(formatData(data));
    }

    const formatData = data => {
        if(data instanceof Date){
            let dataObj = {
                _day: data.getDate(),
                _mes: data.getMonth() + 1,
                _ano: data.getFullYear(),
            };

            return `${dataObj._day < 10 ? "0" + dataObj._day : dataObj._day }-${dataObj._mes < 10 ? "0" + dataObj._mes : dataObj._mes}-${dataObj._ano}`;
        }

        return undefined;
    }

    const selecionarDatePicker = () => {
        if(_refDatePicker !== null) {
           _refDatePicker.current.handleFocus()
        }
    }

    useEffect( ()=> {
    },  [relatorios]);

    return (
        <div className="container-home">
            <div className="container-capa">
                <img src={Photocapa} alt="photo-capa" className="photo-capa" />
                <User />
            </div>
            <Navbar />
            
           <div className="container-main-pai">
                <div className="relatorio-vendas-dia1">
                    <div className="box-infos-rel">
                        <input className="input-pesquisar-rel" autoComplete="off" type="text" name="datas"  onClick={selecionarDatePicker}  value={dataSelected} />
                        { relatorios.vendedor ? 
                        <a className="btn-gerar-pdf" href={"https://api-cinesol.busticket.cl/relatorios/vendas-vendedor/"+params.get('id')+"?data="+dataSelected} target="_blank">
                            PDF
                        </a>
                        : <></> }
                        <DatePicker locale="es"  className="input-invisivel"  ref={_refDatePicker} selected={null} onChange={updateHandlerData} />
                    </div>
                    { relatorios.vendedor ? 
                    <>
                        <div className="box-infos-rel">
                            <h2>Datos de abertura del sistema</h2>
                            <p>- Operador: {relatorios.vendedor.nome}</p>
                            <p>- Fecha/hora: {relatorios.data_abertura}</p>
                        </div>
                        <div className="box-infos-rel">
                            <h2>Datos del cierre</h2>
                            <p>- Operador: {relatorios.vendedor.nome}</p>
                            <p>- Fecha/hora: {relatorios.data_fechamento}</p>
                            <p>- Cant. ventas: {relatorios.qtd_vendas}</p>
                            <p>- Cant. tickets: {relatorios.qtd_ingressos}</p>
                            <p>- Ventas totales: {relatorios.valor_bruto}</p>
                        </div>
                        {
                            relatorios.filmes.length ?
                            <div className="box-infos-rel">
                                <h2>Detalle por función</h2>
                                {
                                    relatorios.filmes.map(filme => (
                                        <div className="item-pelicula">
                                            <p>- {filme.titulo}: {filme.total} ({filme.total_valor})</p>
                                            <p>- SUB: {filme.leg}</p>
                                            <p>- DOB: {filme.dub}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            : <></>
                        }
                    </> : <>
                        <h4>Seleccione una fecha para ver el informe</h4>
                    </> }
                </div>
            </div> 
        </div>
    );
};
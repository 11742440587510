import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import { api } from "../../services/api";
import moment from "moment";
import { EventFinally } from "../eventosFinalizar/EventFinally";
export const PaymentEvent = ({ evento, selectedInfo }) => {
  const [filme, setFilme] = useState({});
  const [valorRestante, setValorRestante] = useState(0);
  const [valorPagamento, setValorPagamento] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("");
  const [formasPagamento, setFormasPagamento] = useState([]);
  const paramsG = new URLSearchParams(window.location.search);


  const handleFormaPagamento = (e) => {
    e.preventDefault();
    setFormaPagamento(e.target.value);
  };

  const handleValorPagamento = (e) => {
    e.preventDefault();
    setValorPagamento(e.target.value);
  };

  const adicionarFormaPagamento = (e) => {
    e.preventDefault();

    let fp = formasPagamento;

    let vr = valorRestante - valorPagamento;

    let troco = 0;

    if (formaPagamento != "Efectivo" && vr < 0) {
      alert("Error: Debe ingresar un valor menor o igual al valor restante");
    } else {
      if (valorRestante - valorPagamento <= 0) {
        if (formaPagamento == "Efectivo") {
          troco = valorPagamento - valorRestante;
        }

        vr = 0;
      }
      if (formaPagamento && valorPagamento) {
        fp.push({
          forma_pagamento: formaPagamento,
          valor: valorPagamento,
          troco: troco,
        });

        setValorPagamento("");
        setFormaPagamento("");
        setValorRestante(vr);
        setFormasPagamento(fp);
      }
    }
  };

  const formatN = (n) => {
    let pesos = Intl.NumberFormat("es-CL");

    return pesos.format(n);
  };


  const excluirFormaPagamento = (e, key, forma) => {
    e.preventDefault();
    let fp = formasPagamento;

    fp.splice(key, 1);

    let vr =
      parseInt(valorRestante) + parseInt(forma.valor) - parseInt(forma.troco);

    setValorRestante(vr);
    setFormasPagamento(fp);
  };

  const [finalizando, setFinalizando] = React.useState(false);

  useEffect(() => {
    setValorRestante(selectedInfo.servico.total);
  }, [formasPagamento]);
  return (
    <div className="container-category-filme">
      <div className="container-pai">
        <div className="container-pai-filme">
          <div className="detalhes-filme">
            <img
              src={
                api.defaults.baseURL.slice(0, -4) +
                "/assets/eventos/" +
                evento?.capa
              }
              alt=""
              className="img-capa"
            />

            <div className="infos-filme-carteira">
              <h2 className="title-sinopse">{evento?.titulo}</h2>
              <p>2019 - Ação | Fantasia</p>
              <p>Diretor: {filme.diretor}</p>
              <p>
                {" "}
                <AiFillStar className="iconStar" />
                7.5 (85,741)
              </p>
              <h6>
                We all have a superhero inside us, it just takes a bit of magic
                to bring it out. In Billy Batson's case, by shouting out one
                word - SHAZAM. - this streetwise fourteen-year-old foster kid
                can turn into the grown-up superhero Shazam
              </h6>
            </div>
          </div>
          {finalizando === false ? (
            <div className="informacoes-detalhes">
              <div className="detalhes-filme-direita">
                <div className="container-main-sesion">
                  <div className="container-sesion">
                    <a
                      href={"/pelicula?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      <div className="bolinha1">
                        <span>01</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <a href={"/cartelera"} style={{ color: "#777777" }}>
                      <div className="bolinha1">
                        <span>02</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="main-bolinha1">
                      <span>03</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>04</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>05</span>
                    </div>
                  </div>
                </div>
                <div className="description-bolinha">
                  <p>
                    <a href={"/cartelera"} style={{ color: "#777777" }}>
                      Selección
                    </a>
                  </p>
                  <p>
                    <a href={"/cartelera"} style={{ color: "#777777" }}>
                      Entradas
                    </a>
                  </p>
                  <p>Pago</p>
                  <p>Ticket</p>
                  <p>Finalizado</p>
                </div>
              </div>

              <div className="content-header" style={{ position: "relative" }}>
                <h1 className="title-payment">DETALLE DE LA COMPRA</h1>
                <div className="box-total-payment">
                  <h4>TOTAL</h4>
                  <p>${formatN(parseInt(selectedInfo.servico.total))}</p>
                </div>

                <div className="description-first">
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    FECHA
                  </h3>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      width: "60%",
                    }}
                  >
                    {moment(selectedInfo.servico.data).format("ddd DD/MM MMM")} <br />
                    Hora: {selectedInfo.servico.hora}
                  </p>
                </div>

                <div className="description-first">
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    ENTRADAS
                  </h3>
                  <p
                    style={{
                      fontFamily: "Poppins",
                    }}
                  >
                    {selectedInfo.servico.n_ticket.map((item, index) => {
                      if (item !== 0)
                        return (
                          <>
                            {item +
                              ` butaca ${selectedInfo.servico.precos[index].tipo}`}

                            <br />
                          </>
                        );
                    })}
                    <br />
                  </p>
                </div>

                {/* <div className="description-first">
                    <h3
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                    >
                      BUTACAS
                    </h3>
                    {ingresso.assentos ? (
                      ingresso.assentos.map((item) => {
                        return (
                          <>
                            <p
                              style={{
                                fontFamily: "Poppins",
                                color: "white",
                                padding: "8px",
                                backgroundColor: "#00C455",
                                borderRadius: "4px",
                                width: 40,
                                float: "left",
                                textAlign: "center",
                                marginRight: "10px",
                              }}
                            >
                              {item.assento_nome}
                            </p>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    <div style={{ clear: "both" }}></div>
                  </div> */}

                <div className="forma-pagamento">
                  <div className="form-control">
                    <h3
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                    >
                      FORMA DE PAGO
                    </h3>

                    <select
                      name="forma_pagamento"
                      value={formaPagamento}
                      className="input-forma-pagamento"
                      onChange={handleFormaPagamento}
                    >
                      <option value="">Seleccione</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Tarjeta de crédito">
                        Tarjeta de crédito
                      </option>
                      <option value="Tarjeta de débito">
                        Tarjeta de débito
                      </option>
                    </select>

                    <input
                      name="valor-pagamento"
                      type="number"
                      onChange={handleValorPagamento}
                      value={valorPagamento}
                      min={1}
                      className="input-valor-pagamento"
                    />

                    <button
                      className="btn-incluir-forma-pagamento"
                      onClick={adicionarFormaPagamento}
                    >
                      Agregar
                    </button>

                    {formasPagamento && formasPagamento.length ? (
                      <>
                        <p>
                          Valor restante: ${formatN(parseInt(valorRestante))}
                        </p>
                        <table className="tbl-formas-pagamento">
                          <thead>
                            <tr>
                              <th>Forma de pago</th>
                              <th>Valor</th>
                              <th>Cambio</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {formasPagamento.map((fp, key) => (
                              <tr key={key}>
                                <td>{fp.forma_pagamento}</td>
                                <td>${formatN(fp.valor)}</td>
                                <td>${formatN(fp.troco)}</td>
                                <td>
                                  <button
                                    className="btn-excluir-forma-pagamento"
                                    onClick={(e) =>
                                      excluirFormaPagamento(e, key, fp)
                                    }
                                  >
                                    Excluir
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {valorRestante <= 0 ? (
                  <div className="footer-button-payment">
                    <a href={"/cartelera"} className="button-cancel">
                      <span>Volver</span>
                    </a>
                    <button
                      //   onClick={handleNavigation}
                      onClick={() => setFinalizando(true)}
                      className="button-continue"
                    >
                      <span>Continuar</span>
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <EventFinally
              data={{ servico: selectedInfo, forma_pagamento: formasPagamento }}
              evento={evento}
              selectedInfo={selectedInfo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { api } from "../../services/api";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Box, DataTable, Layer, Button as Btn, TextInput, Text } from 'grommet';
import { Switch, Button as FormButton, Input } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import TableCupom from "../../components/Cupons/TableCupom";
import './cupon.css'

export const CupomScreen = ({ changeScreen, abaInitial }) => {

    const [currentId, setId] = useState();
    const [dataCupons, setDataCupons] = useState([]);
    const [dataCuponsForSale, setDataCuponsForSale] = useState([]);
    const [dataReport, setDataReport] = useState([]);
    const [editing, setEdit] = useState(false);
    const [generado, setGenerado] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [promo, setPromo] = useState(null);
    const [qtd, setQtd] = useState(0);
    const [aba, setAba] = useState(abaInitial);

    const [filmes, setFilmes] = useState([]);
    const [salas, setSalas] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [search, setSearch] = useState('');

    async function pesquisarCupons() {

        let token = localStorage.token;

        const response = await api.get('/v1/ingresso/pesquisar-cupons', {
            headers: {
                Authorization: "Bearer " + token
            }
        })

        let DATA = [];
        response.data.data.map(item => {

            DATA.push({
                Id: <p> {item.id} </p>,
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                Cupom: <a onClick={() => handleSelectPromo(item)}> {item.nome} </a>,
                Valor: <p> {item.tipo === "valor" ? '$' + item.valor : item.tipo === "porcentagem" ? item.porcentagem + '%' : `${item.tickets_afetados} ticket de descuento`} </p>,
                Quantidade: <p> {item.usados} </p>,
                Data: <p> {moment(item.data_inicio).format('DD/MM/YYYY')} </p>,
                DataExpira: <p> {moment(item.data_fim).format('DD/MM/YYYY')} </p>,
                Status: <p> {item.deleted_at ? false : true} </p>,
                Uso: <p> {item.usados}</p>,
                Json: <p> {JSON.stringify(item)}</p>,
                Ações: <p> Editar e deletar </p>,
                data: item
            });
        })
        setDataCupons(DATA);
    }

    async function pesquisarCuponsForSale() {

        let token = localStorage.token;

        const response = await api.get('/v1/ingresso/pesquisar-cupons-for-sale', { headers: { Authorization: "Bearer " + token } })

        let DATA = [];
        response.data.data.map(item => {

            let filmes_ids = [];
            item.filmes_included?.map(e => filmes_ids.push(e.filme_id))
            item.filmes_ids = filmes_ids;

            DATA.push({
                Id: <p> {item.id} </p>,
                Cupom: <a onClick={() => handleSelectPromo(item)}> {item.nome} </a>,
                Valor: <p> {item.tickets_afetados} ticket de descuento </p>,
                valor_venta: <p> ${parseInt(item.valor_venta).toLocaleString("pt-br", { currency: "CLP" })} </p>,
                Data: <p> {moment(item.data_inicio).format('DD/MM/YYYY')} </p>,
                DataExpira: <p> {moment(item.data_fim).format('DD/MM/YYYY')} </p>,
                Status: <p> {item.deleted_at ? false : true} </p>,
                vendidos: <p> {item.vendidos} </p>,
                cantidad_limite: <p> {item.cant_limite_ventas} </p>,
                data: item
            });


        })
        setDataCuponsForSale(DATA);
    }

    async function getFlmes() {
        let token = localStorage.token;
        const response = await api.get("/v1/filme/pesquisar_all", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        setFilmes(response.data.data.filme);
    }

    async function getSalas() {
        let token = localStorage.token;
        const response = await api.get("/v1/sala/pesquisar", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        setSalas(response.data.data);
    }

    useEffect(() => {
        pesquisarCupons();
        pesquisarCuponsForSale();
        getFlmes();
        getSalas();
    }, []);

    const handleSwitchButton = async (id, forSale = false) => {
        let token = localStorage.token;

        const response = await api.post('/v1/ingresso/toggle-cupom/' + id, {}, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
        if (forSale) {
            pesquisarCuponsForSale();
        } else {
            pesquisarCupons();
        }
    };

    const handleDeleteButton = async (d) => {

        let confirmA = window.confirm('¿Está seguro de que desea eliminar este promoción?');

        if (confirmA) {

            let id = d.id;
            let token = localStorage.token;

            api.get(`v2/promocao/deletar/${id}`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).finally(() => window.location.reload())


        }
    };

    const columns = [
        {
            align: "center",
            property: 'Cupom',
            header: <p>Cupón</p>,
            primary: true,
        },
        {
            align: "center",
            property: 'Valor',
            header: <p>Valor</p>,
        },
        {
            align: "center",
            property: 'Data',
            header: <p>Comienza en:</p>,
        },
        {
            align: "center",
            property: 'DataExpira',
            header: <p>Válido hasta:</p>,
        },
        {
            align: "center",
            property: 'Status',
            header: <p>Status</p>,
            render: datum => (
                <Switch checkedChildren="Activo" unCheckedChildren="Pausado" checked={datum.data.ativo} onChange={() => handleSwitchButton(datum.data.id)} />
            )
        },
        {
            align: "center",
            property: 'Uso',
            header: <p>Usados</p>,

        },
        {
            align: "center",
            property: 'Ações',
            header: <p>Acciones</p>,
            render: datum => (<Box
                direction="row"
                gap={'small'}
            >
                <FormButton type="primary"
                    icon={<EditIcon />}
                    onClick={async () => { await setPromo(datum.data); setEdit(true) }}
                    size={'medium'}
                />
                <FormButton type="primary" danger
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteButton(datum.data)}
                    size={'medium'}
                />
                <FormButton type="primary" success
                    icon={<VisibilityIcon />}
                    onClick={() => changeScreen(datum.data)}
                    size={'medium'}
                />
            </Box>

            )
        },
    ];

    const columnsParaVenta = [
        {
            align: "center",
            property: 'Cupom',
            header: <p>Cupón</p>,
        },
        {
            align: "center",
            property: 'valor_venta',
            header: <p>Valor venta</p>
        },
        {
            align: "center",
            property: 'Data',
            header: <p>Comienza en:</p>,
        },
        {
            align: "center",
            property: 'DataExpira',
            header: <p>Válido hasta:</p>,
        },
        {
            align: "center",
            property: 'Status',
            header: <p>Status</p>,
            render: datum => (
                <Switch checkedChildren="Activo" unCheckedChildren="Pausado" checked={datum.data.ativo} onChange={() => handleSwitchButton(datum.data.id, true)} />
            )
        },
        {
            align: "center",
            property: 'vendidos',
            header: <p>Vendidos</p>,
        },
        {
            align: "center",
            property: 'cantidad_limite',
            header: <p>Cant límite ventas</p>,
        },
        {
            align: "center",
            property: 'Ações',
            header: <p>Acciones</p>,
            render: datum => (<Box
                direction="row"
                gap={'small'}
            >
                <FormButton type="primary"
                    icon={<EditIcon />}
                    onClick={() => changeScreen(datum.data, true)}
                    size={'medium'}
                />
                <FormButton type="primary" danger
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteButton(datum.data)}
                    size={'medium'}
                />
            </Box>

            )
        },
    ];

    const columnsReport = [
        {
            property: 'cupon',
            header: <p>Cupón o nombre promoción</p>
        },
        {
            property: 'codigo',
            header: <p>Código cupón</p>
        },
        {
            property: 'fecha_canje',
            header: <p>Fecha Canje</p>
        },
        {
            property: 'hora_canje',
            header: <p>Hora Canje</p>
        },
        {
            property: 'ticket',
            header: <p>N° Ticket</p>
        },
        {
            property: 'pelicula',
            header: <p>Película</p>
        },
        {
            property: 'hora_funcion',
            header: <p>Hora Función</p>
        },
        {
            property: 'fecha_funcion',
            header: <p>Fecha Función</p>
        },
        {
            property: 'sala',
            header: <p>Sala</p>
        },
        {
            property: 'rut',
            header: <p>RUT</p>
        },
        {
            property: 'email',
            header: <p>Mail</p>
        },
    ];

    const [modal, setModal] = React.useState(false);

    const handleSelectPromo = (id) => {
        setId(id);
        setModal(true);
    }

    const handleAdd = () => {
        let token = localStorage.token;

        api.get(`v2/create-cupom/${promo.id}/${qtd}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => window.location.reload());


    }

    const handleFilterData = (e) => {
        e.preventDefault();
        let filtros = { ...filterData, [e.target.name]: e.target.value };
        setFilterData(filtros);
    };

    const getParamsReportCupon = () => {
        let paramsUrl = "";
        if (filterData.fecha_inicio) {
            paramsUrl += "&fecha_inicio=" + filterData.fecha_inicio;
        }
        if (filterData.fecha_fin) {
            paramsUrl += "&fecha_fin=" + filterData.fecha_fin;
        }
        if (filterData.filme_id) {
            paramsUrl += "&filme_id=" + filterData.filme_id;
        }
        if (filterData.sala_id) {
            paramsUrl += "&sala_id=" + filterData.sala_id;
        }
        return paramsUrl;
    }

    async function getReporteCupones(searchTxt = null) {
        setCargando(true);
        let token = localStorage.token;

        searchTxt = searchTxt !== null ? searchTxt : search;

        let paramsUrl = getParamsReportCupon();
        paramsUrl += "&search=" + searchTxt;

        const response = await api.get('/v1/relatorios/cupon?d=1' + paramsUrl, { headers: { Authorization: "Bearer " + token } })
        setDataReport(response.data.data)
        setGenerado(true);
        setCargando(false);
    }

    const handleSearch = (searchTxt) => {
        setSearch(searchTxt)
        getReporteCupones(searchTxt)
    }

    async function exportReportCupones() {
        setCargando(true);

        let paramsUrl = getParamsReportCupon();
        paramsUrl += "&search=" + search;

        let url = "https://api-cinesol.busticket.cl/relatorios/cupones_report/export?d=1" + paramsUrl;
        window.open(url, "_blank");

        setCargando(false);
    }

    return (
        <div className="container-home">
            <div className="container-main-pai">
                <div className="abas">
                    <button
                        className={"aba " + (aba == 0 ? "active" : "")}
                        onClick={() => setAba(0)}
                    >
                        Promociones
                    </button>
                    <button
                        className={"aba " + (aba == 1 ? "active" : "")}
                        onClick={() => setAba(1)}
                    >
                        Para vender
                    </button>
                    <button
                        className={"aba " + (aba == 2 ? "active" : "")}
                        onClick={() => setAba(2)}
                    >
                        Reporte cupón
                    </button>
                </div>
            </div>
            {aba === 0 && (
                <Box
                    direction="column"
                    border={[
                        {
                            color: "white",
                            size: "xlarge",
                            style: "solid",
                            side: "right"
                        },
                        {
                            color: "white",
                            size: "xlarge",
                            style: "solid",
                            side: "left"
                        },
                        {
                            color: "white",
                            size: "xlarge",
                            style: "solid",
                            side: "bottom"
                        },
                    ]}
                    pad="medium"
                    width="100%"
                    background={'white'}
                >
                    <Box direction="row" >
                        <Box pad="small" > <h2> Cupones </h2> </Box>
                        <Box pad="small" style={{ float: 'right', marginLeft: '70%' }} direction="row">
                            <Button sx={{
                                backgroundColor: '#2b00ff', '&:hover': {
                                    backgroundColor: '#2b00ff',
                                    color: '#white',
                                }, color: 'white',
                                width: '100%',
                                height: '60%',
                                justifyContent: 'center',
                                alignItem: 'center',
                                display: 'flex',
                                fontFamily: 'Poppins',

                            }}
                                onClick={() => changeScreen()}
                            >
                                <AddIcon />  Crear Nuevo
                            </Button>
                        </Box>
                    </Box>

                    <Box align='center' pad='small'>
                        <DataTable border={{
                            side: "horizontal",
                        }}
                            background={{
                                header: "#F1F1F1",
                            }}
                            pad={{
                                header: "small",
                                body: "xsmall",
                            }}
                            style={{ borderCollapse: 'collapse' }}
                            size='large'
                            columns={columns} data={dataCupons} />
                    </Box>

                    {editing && (
                        <Layer
                            onEsc={() => setEdit(false)}
                            onClickOutside={() => setEdit(false)}
                        >
                            <Box margin="small" pad="small" gap="medium" align="center">
                                <Text> Ingresa la cantidad a aumentar</Text>
                                <TextInput placeholder="Escribe aquí la cantidad" onChange={event => setQtd(event.target.value)} type="number" />
                                <Box direction="row" gap="small">
                                    <Btn label={<Text color="white">Confirmar</Text>} onClick={() => handleAdd()} style={{ backgroundColor: "green" }} color="green" />
                                </Box>
                            </Box>
                        </Layer>
                    )}

                    {modal && <Layer
                        onEsc={() => setModal(false)}
                        onClickOutside={() => setModal(false)}
                    >
                        <TableCupom modal={modal} id={currentId.id} name={currentId.nome} cupom={currentId} forSale={false} />
                    </Layer>}
                </Box>
            )}
            {aba === 1 && (
                <Box
                    direction="column"
                    border={[
                        {
                            color: "white",
                            size: "xlarge",
                            style: "solid",
                            side: "right"
                        },
                        {
                            color: "white",
                            size: "xlarge",
                            style: "solid",
                            side: "left"
                        },
                        {
                            color: "white",
                            size: "xlarge",
                            style: "solid",
                            side: "bottom"
                        },
                    ]}
                    pad="medium"
                    width="100%"
                    background={'white'}
                >
                    <Box direction="row" >
                        <Box pad="small" > <h2> Cupones de ventas </h2> </Box>
                        <Box pad="small" style={{ float: 'right', marginLeft: 'auto' }} direction="row">
                            <Button sx={{
                                backgroundColor: '#2b00ff', '&:hover': {
                                    backgroundColor: '#2b00ff',
                                    color: '#white',
                                }, color: 'white',
                                width: '100%',
                                height: '60%',
                                justifyContent: 'center',
                                alignItem: 'center',
                                display: 'flex',
                                fontFamily: 'Poppins',

                            }}
                                onClick={() => changeScreen(null, true)}
                            >
                                <AddIcon />  Nuevo
                            </Button>
                        </Box>
                    </Box>

                    <Box align='center' pad='small'>
                        <DataTable border={{ side: "horizontal", }}
                            background={{ header: "#F1F1F1", }}
                            pad={{ header: "small", body: "xsmall", }}
                            style={{ borderCollapse: 'collapse' }}
                            size='large'
                            columns={columnsParaVenta} data={dataCuponsForSale} />
                    </Box>
                    {editing && (
                        <Layer
                            onEsc={() => setEdit(false)}
                            onClickOutside={() => setEdit(false)}
                        >
                            <Box margin="small" pad="small" gap="medium" align="center">
                                <Text> Ingresa la cantidad a aumentar</Text>
                                <TextInput placeholder="Escribe aquí la cantidad" onChange={event => setQtd(event.target.value)} type="number" />
                                <Box direction="row" gap="small">
                                    <Btn label={<Text color="white">Confirmar</Text>} onClick={() => handleAdd(true)} style={{ backgroundColor: "green" }} color="green" />
                                </Box>
                            </Box>
                        </Layer>
                    )}

                    {modal && <Layer
                        onEsc={() => setModal(false)}
                        onClickOutside={() => setModal(false)}
                    >
                        <TableCupom modal={modal} id={currentId.id} name={currentId.nome} cupom={currentId} forSale={true} />
                    </Layer>}
                </Box>
            )}
            {aba === 2 && (
                <div style={{ width: '90%', margin: 'auto', textAlign: 'center' }}>
                    <div className="container-main-pai">
                        <h4>Muestra los detalles de un cupón canjeado por un cliente</h4>
                        <br />
                        <div className="filtros">
                            <div className="filtro-field">
                                <p>Desde</p>
                                <input type="date" name="fecha_inicio" onChange={handleFilterData} />
                            </div>
                            <div className="filtro-field">
                                <p>Hasta</p>
                                <input type="date" name="fecha_fin" onChange={handleFilterData} />
                            </div>
                        </div>
                        <div className="filtros">
                            <div className="filtro-field">
                                <p>Función</p>
                                <select name="filme_id" onChange={handleFilterData}>
                                    <option value="">Seleccione</option>
                                    {filmes && filmes.map((filme) => (
                                        <option value={filme.id}>{filme.titulo}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="filtro-field">
                                <p>Sala</p>
                                <select name="sala_id" onChange={handleFilterData}>
                                    <option value="">Seleccione</option>
                                    {salas.map((item) => (
                                        <option value={item.id}>{item.nome}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="filtros">
                            <button className="btn-generar" onClick={() => getReporteCupones()}>
                                {cargando ? "Aguarde" : "Generar"}
                            </button>
                            <button className="btn-generar-excel" onClick={() => exportReportCupones()}>
                                {cargando ? "Aguarde" : "Exportar Excel"}
                            </button>
                        </div>
                        <br />

                    </div>
                    {generado && (
                        <>
                            <Box direction="row">
                                <Text style={{ marginRight: 10 }}>Buscar: </Text>
                                <Input
                                    style={{ width: '20%' }}
                                    placeholder="Buscar..."
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </Box>
                            <Box align='center' pad='small'>
                                <div className="tabla-cupons-report">
                                    <DataTable border={{ side: "horizontal" }}
                                        background={{ header: "#F1F1F1", }}
                                        pad={{ header: "small", body: "xsmall" }}
                                        size='large'
                                        primaryKey={false}
                                        style={{ borderCollapse: 'collapse' }}
                                        columns={columnsReport} data={dataReport} />
                                </div>
                            </Box>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
import React, { useState, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import { useHistory } from "react-router";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";
import "./Payment.styles.css";
import { api } from "../../services/api";
import { User } from "../../components/User";
export const Payment = () => {
  const history = useHistory();
  const [filme, setFilme] = useState({});
  const [ingresso, setIngresso] = useState({});
  const [valorRestante, setValorRestante] = useState(0);
  const [valorPagamento, setValorPagamento] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("");
  const [formasPagamento, setFormasPagamento] = useState([]);
  const paramsG = new URLSearchParams(window.location.search);

  async function getFilme() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/v1/filme/pesquisar-info/" + params.get("id"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setFilme(response.data.data);

    getFormaPagamentoLocal();
  }

  const getFormaPagamentoLocal = () => {
    if (localStorage.formasPagamento) {
      let formasPagamentoLocal = JSON.parse(localStorage.formasPagamento);
      let ingressoLocal = JSON.parse(localStorage.ingresso);
      let fp = formasPagamento;
      let vr = parseInt(ingressoLocal.valor_total);

      formasPagamentoLocal.map((fpItem) => {
        fp.push({
          forma_pagamento: fpItem.forma_pagamento,
          valor: fpItem.valor,
          troco: fpItem.troco,
        });

        vr -=
          parseInt(fpItem.valor) - parseInt(fpItem.troco ? fpItem.troco : 0);
      });

      if (vr < 0) {
        setValorRestante(0);
        setFormasPagamento([]);
      } else {
        setValorRestante(vr);
        setFormasPagamento(fp);
      }
    }
  };

  const handleFormaPagamento = (e) => {
    e.preventDefault();
    setFormaPagamento(e.target.value);
  };

  const handleValorPagamento = (e) => {
    e.preventDefault();
    setValorPagamento(e.target.value);
  };

  const adicionarFormaPagamento = (e) => {
    e.preventDefault();

    let fp = formasPagamento;

    let vr = valorRestante - valorPagamento;

    let troco = 0;

    if (formaPagamento != "Efectivo" && vr < 0) {
      alert("Error: Debe ingresar un valor menor o igual al valor restante");
    } else {
      if (valorRestante - valorPagamento <= 0) {
        if (formaPagamento == "Efectivo") {
          troco = valorPagamento - valorRestante;
        }

        vr = 0;
      }

      fp.push({
        forma_pagamento: formaPagamento,
        valor: valorPagamento,
        troco: troco,
      });

      setValorPagamento();
      setFormaPagamento("");
      setValorRestante(vr);
      setFormasPagamento(fp);
    }
  };

  const handleNavigation = (e) => {
    e.preventDefault();
    localStorage.formasPagamento = JSON.stringify(formasPagamento);
    let params = new URLSearchParams(window.location.search);
    history.push("/ticket?id=" + params.get("id"));
  };

  const formatN = (n) => {
    let pesos = Intl.NumberFormat("es-CL");

    return pesos.format(n);
  };


  const excluirFormaPagamento = (e, key, forma) => {
    e.preventDefault();
    let fp = formasPagamento;

    fp.splice(key, 1);

    let vr =
      parseInt(valorRestante) + parseInt(forma.valor) - parseInt(forma.troco);

    setValorRestante(vr);
    setFormasPagamento(fp);
  };


  useEffect(() => {
    getFilme();
  }, []);

  useEffect(() => {
    let ingresso = JSON.parse(localStorage.ingresso);
    setIngresso(ingresso);
    setValorRestante(ingresso.valor_total);
  }, [formasPagamento]);
  
  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      <div className="container-category-filme">
        <div className="container-pai">
          <div className="container-pai-filme">
            <div className="detalhes-filme">
              <img src={filme.link_img_capa} alt="" className="img-capa" />
              <div className="infos-filme-carteira">
                <h2 className="title-sinopse">{filme.titulo}</h2>
                <p>2019 - Ação | Fantasia</p>
                <p>Diretor: {filme.diretor}</p>
                <p>
                  {" "}
                  <AiFillStar className="iconStar" />
                  7.5 (85,741)
                </p>
                <h6>
                  We all have a superhero inside us, it just takes a bit of
                  magic to bring it out. In Billy Batson's case, by shouting out
                  one word - SHAZAM. - this streetwise fourteen-year-old foster
                  kid can turn into the grown-up superhero Shazam
                </h6>
              </div>
            </div>
            <div className="informacoes-detalhes">
              <div className="detalhes-filme-direita">
                <div className="container-main-sesion">
                  <div className="container-sesion">
                    <a
                      href={"/pelicula?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      <div className="bolinha1">
                        <span>01</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <a
                      href={"/butacas?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      <div className="bolinha1">
                        <span>02</span>
                      </div>
                    </a>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="main-bolinha1">
                      <span>03</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>04</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>05</span>
                    </div>
                  </div>
                </div>
                <div className="description-bolinha">
                  <p>
                    <a
                      href={"/pelicula?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      Selección
                    </a>
                  </p>
                  <p>
                    <a
                      href={"/butacas?id=" + paramsG.get("id")}
                      style={{ color: "#777777" }}
                    >
                      Butacas
                    </a>
                  </p>
                  <p>Pago</p>
                  <p>Ticket</p>
                  <p>Finalizado</p>
                </div>
              </div>

              <div className="content-header" style={{ position: "relative" }}>
                <h1 className="title-payment">DETALLE DE LA COMPRA</h1>
                <div className="box-total-payment">
                  <h4>TOTAL</h4>
                  <p>${formatN(parseInt(ingresso.valor_total))}</p>
                </div>
                {ingresso ? (
                  <>
                    <div className="description-first">
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        }}
                      >
                        FECHA
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          width: "60%",
                        }}
                      >
                        {ingresso.horario ? ingresso.horario.dia_da_semana : ""}{" "}
                        {ingresso.horario ? ingresso.horario.data : ""}{" "}
                        {ingresso.horario ? ingresso.horario.nome_mes : ""}{" "}
                        <br />
                        Hora: {ingresso.horario ? ingresso.horario.horario : ""}
                      </p>
                    </div>

                    <div className="description-first">
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        }}
                      >
                        ENTRADAS
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                        }}
                      >
                        {ingresso.qtd_total}{" "}
                        {ingresso.qtd_total === 1 ? "entrada" : "entradas"}{" "}
                        <br />
                        {ingresso.horario
                          ? ingresso.horario.sala.nome
                          : ""}{" "}
                        <br />
                        {ingresso.horario ? ingresso.horario.qualidade : ""}
                      </p>
                    </div>

                    <div className="description-first">
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        }}
                      >
                        BUTACAS
                      </h3>
                      {ingresso.assentos ? (
                        ingresso.assentos.map((item) => {
                          return (
                            <>
                              <p
                                style={{
                                  fontFamily: "Poppins",
                                  color: "white",
                                  padding: "8px",
                                  backgroundColor: "#00C455",
                                  borderRadius: "4px",
                                  width: 40,
                                  float: "left",
                                  textAlign: "center",
                                  marginRight: "10px",
                                }}
                              >
                                {item.assento_nome}
                              </p>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      <div style={{ clear: "both" }}></div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <form style={{ marginBottom: 50 }}>
                  <div className="forma-pagamento">
                    <div className="form-control">
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        }}
                      >
                        {parseInt(ingresso.valor_total) !== 0
                          ? "FORMA DE PAGO"
                          : "CUPÓN"}
                      </h3>

                      {valorRestante > 0 ? (
                        <>
                          <select
                            name="forma_pagamento"
                            value={formaPagamento}
                            className="input-forma-pagamento"
                            onChange={handleFormaPagamento}
                          >
                            <option value="">Seleccione</option>
                            <option value="Efectivo">Efectivo</option>
                            <option value="Tarjeta de crédito">
                              Tarjeta de crédito
                            </option>
                            <option value="Tarjeta de débito">
                              Tarjeta de débito
                            </option>
                          </select>

                          {formaPagamento ? (
                            <input
                              name="valor-pagamento"
                              type="number"
                              onChange={handleValorPagamento}
                              min={1}
                              className="input-valor-pagamento"
                            />
                          ) : (
                            <></>
                          )}

                          {formaPagamento && valorPagamento ? (
                            <button
                              className="btn-incluir-forma-pagamento"
                              onClick={adicionarFormaPagamento}
                            >
                              Agregar
                            </button>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {formasPagamento && formasPagamento.length ? (
                        <>
                          <p>
                            Valor restante: ${formatN(parseInt(valorRestante))}
                          </p>
                          <table className="tbl-formas-pagamento">
                            <thead>
                              <tr>
                                <th>Forma de pago</th>
                                <th>Valor</th>
                                <th>Cambio</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {formasPagamento.map((fp, key) => (
                                <tr key={key}>
                                  <td>{fp.forma_pagamento}</td>
                                  <td>${formatN(fp.valor)}</td>
                                  <td>${formatN(fp.troco)}</td>
                                  <td>
                                    <button
                                      className="btn-excluir-forma-pagamento"
                                      onClick={(e) =>
                                        excluirFormaPagamento(e, key, fp)
                                      }
                                    >
                                      Excluir
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {valorRestante == 0 ? (
                    <div className="footer-button-payment">
                      <a
                        href={"/butacas?id=" + paramsG.get("id")}
                        className="button-cancel"
                      >
                        <span>Volver</span>
                      </a>
                      <button
                        onClick={handleNavigation}
                        className="button-continue"
                      >
                        <span>Continuar</span>
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import './ModalHistoricoSuspensiones.styles.css';
import { api } from '../../services/api';

export const ModalHistoricoSuspensiones = () => {
    const history = useHistory()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);

    const token = localStorage.token

    async function loadData() {
        const resp = await api.post('/v1/administrar_compras/data', {}, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        setLoading(false);
        setData(resp.data.data)
    }

    function handleModalVisible() {
        window.location.reload();
        history.push('/salas');
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="container-area-modal-historico">
            <button className="button-closed-modal-historico" onClick={handleModalVisible}>X</button>
            <div className="modal-container-modal-historico">
                <div className="title--modal-historico">
                    <h3>Historial de suspensiones y activaciones de ventas</h3>
                </div>
                {!loading && (
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Canal</th>
                                <th>Sala</th>
                                <th>Horario</th>
                                <th>Acción</th>
                                <th>Fecha y hora</th>
                                <th>Usuario</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((registro) => (
                                <tr>
                                    <td>{registro.canal}</td>
                                    <td>{registro.sala}</td>
                                    <td>{registro.horario}</td>
                                    <td>{registro.accion}</td>
                                    <td>{registro.fecha}</td>
                                    <td>{registro.usuario}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};